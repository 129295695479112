export const COPY_EMBED_PUBLIC_LINK = 'copy-embed-public-link'
export const COPY_EMBED_POLICY_SNIPPET = 'copy-embed-policy-snippet'
export const COPY_HTML = 'copy-html'
export const CLICK_APPLY_THEME = 'click-apply-theme'
export const CLICK_EMBED_POLICY = 'click-embed-policy'
export const CLICK_NEW_WEBSITE_MAIN = 'click-new-website-main'
export const CLICK_NEW_WEBSITE_HEADER = 'click-new-website-header'
export const CLICK_NEW_WEBSITE_DROPDOWN = 'click-new-website-dropdown'
export const CLICK_TRIGGER_EDIT = 'click-trigger-edit'
export const EXPORT_CONSENT = 'export_consent'
export const AUTOMATIC_SCANS = 'automatic_scans'
export const SET_SUBDOMAIN = 'set_subdomain'
export const GET_API_KEY = 'get_api_key'
export const SECOND_POLICY = 'second_policy'
export const EMBEDDED_URL = 'embedded_url'
export const EMBEDDED_POLICY = 'embedded_policy'
export const MORE_THEME = 'more_theme'
