import React from 'react'
import PropTypes from 'prop-types'

import Styles from './styles.scss'

import NewTabIcon from './img/new-tab-icon.svg'


export default function ExternalLink(props) {
  const {
    children,
    className,
    rel,
    showIcon = true,
    url,
  } = props

  /* eslint-disable react/jsx-no-target-blank */
  return (
    <a
      className={ className }
      href={ url }
      rel={ rel }
      target="_blank"
    >
      { children }

      {
        renderIcon({
          showIcon,
        })
      }
    </a>
  )
  /* eslint-enable */
}

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,

  children: PropTypes.node,
  className: PropTypes.string,
  rel: PropTypes.string,
  showIcon: PropTypes.bool,
}

function renderIcon({ showIcon }) {
  if ( !showIcon ) {
    return null
  }

  return (
    <img
      alt=""
      className={ Styles.icon }
      src={ NewTabIcon }
    />
  )
}
