import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'

import Paths from 'constants/paths'

import SidebarAccordion from './SidebarAccordion'
import WebsiteDashboardLink from './WebsiteDashboardLink'

import Styles from './styles.scss'

import useSidebarAccordions from './hooks/useSidebarAccordions'


export default function DashboardSidebar() {
  const navigate = useNavigate()

  const {
    accordions,
    currentWebsiteID,
    openedAccordionID,
  } = useSidebarAccordions()

  const [idOfOpenAccordion, setIdOfOpenAccordion] = useState(openedAccordionID)

  const dashboardURL = Paths.WEBSITE_DASHBOARD(currentWebsiteID)

  // Initially, `openedAccordionID` is likely going to be undefined,
  // but when a user navigates to a page from the dashboard, e.g.
  // "Privacy Policy", and nothing in the sidebar was actually clicked,
  // we still want the accordion with that page to open.
  useEffect(() => {
    setIdOfOpenAccordion(openedAccordionID)
  }, [openedAccordionID, setIdOfOpenAccordion])

  const checkIfAccordionIsOpen = useCallback((id) => {
    return ( idOfOpenAccordion === id )
  }, [idOfOpenAccordion])

  const handleClick = useCallback(({ id }) => {
    const newID = ( checkIfAccordionIsOpen(id) ) ? void 0 : id

    // WebsiteDashboardLink below is a 'dummy' accordion. It's really just a regular old link
    // and doesn't have the same 'sections' or child 'links' like the other accordions do.
    // Trying to configure it in the same manner led to a cascade of special-casing logic in
    // the child components. If a user clicks 'Dashboard', we navigate there and close all the
    // 'real' accordions.
    if ( newID === 'dashboard' ) {
      navigate(dashboardURL)
    }

    setIdOfOpenAccordion(newID)
  }, [checkIfAccordionIsOpen, dashboardURL, navigate, setIdOfOpenAccordion])

  const sidebarAccordions = useMemo(() => {
    return accordions?.map(({ icon, id, name, sections }) => {

      // Remember: `key` doesn't get passed through to SidebarAccordion--that's
      // consumed by React, which is why we also have to pass it in via `id`.
      return (
        <SidebarAccordion
          handleClick={ handleClick }
          icon={ icon }
          id={ id }
          isOpen={ checkIfAccordionIsOpen(id) }
          key={ id }
          name={ name }
          sections={ sections }
        />
      )
    })
  }, [accordions, handleClick, checkIfAccordionIsOpen])

  const rootClassName = classnames(Styles.root, {
    [Styles.disabled]: !currentWebsiteID,
  })

  return (
    <div className={ rootClassName }>
      <WebsiteDashboardLink
        handleClick={ handleClick }
        url={ dashboardURL }
      />

      { sidebarAccordions }
    </div>
  )
}
