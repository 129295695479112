import { useCallback } from 'react'

import useAddWebsiteModal from 'hooks/useAddWebsiteModal'

export const ADD_WEBSITE_MODAL = 'addWebsite'


export default function useReturnToAction() {
  const addWebsiteModal = useAddWebsiteModal()

  return useCallback((action) => {
    // eslint-disable-next-line eqeqeq
    if ( action == null ) {
      return
    }

    // eslint-disable-next-line eqeqeq
    if ( action == ADD_WEBSITE_MODAL ) {
      addWebsiteModal.open()
      return
    }

    throw new Error(`Unrecognized action: ${ action }`)
  }, [addWebsiteModal])
}
