import createActionCreator from 'utils/redux'

export const SET_SHOULD_OVERRIDE_DOCUMENT = `${ __filename }/SET_SHOULD_OVERRIDE_DOCUMENT`
export const INITIALIZE = `${ __filename }/INITIALIZE`

export const setShouldOverrideDocument = createActionCreator(SET_SHOULD_OVERRIDE_DOCUMENT)
export const initialize = createActionCreator(INITIALIZE)

export const initState = {
  shouldOverrideDocument: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_SHOULD_OVERRIDE_DOCUMENT: return {
      ...state,
      shouldOverrideDocument: payload,
    }
    case INITIALIZE: return {
      ...initState,
    }
    default: return state
  }
}
