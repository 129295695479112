import createActionCreator from 'utils/redux'

export const UPDATE_LOADING = `${ __filename }/UPDATE_LOADING`
export const UPDATE_SUCCESS = `${ __filename }/UPDATE_SUCCESS`

export const updateLoading = createActionCreator(UPDATE_LOADING)
export const updateSuccess = createActionCreator(UPDATE_SUCCESS)

export const initState = {
  loading: false,
  success: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        success: payload,
      }
    default:
      return state
  }
}
