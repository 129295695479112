import ReactIntlFormatters, {
  makeExternalLinkFormatter,
  makeSmartLinkFormatter,
} from './react-intl-formatters'

export default ReactIntlFormatters

export {
  makeExternalLinkFormatter,
  makeSmartLinkFormatter,
}
