import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { SmartLink } from '@termly_web/common'

import Styles from './styles.scss'


export default function LinkGroup({ className, linkGroup, title }) {
  const rootClassName = classnames(Styles.root, className)

  return (
    <div className={ rootClassName }>
      <h4 className={ Styles.title }>
        <FormattedMessage
          { ...title }
        />
      </h4>

      <ul className={ Styles.linkGroup }>
        {
          linkGroup.map(({ id, name, url }) => {
            const linkClassName = classnames(Styles.link, `t-${ id }Link`)

            return (
              <li key={ id }>
                <SmartLink
                  className={ linkClassName }
                  url={ url }
                >
                  <FormattedMessage
                    { ...name }
                  />
                </SmartLink>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

LinkGroup.propTypes = {
  title: PropTypes.shape({
    defaultMessage: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,

  className: PropTypes.string,
  linkGroup: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
      }).isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
}
