import {
  createContext,
  useContext,
} from 'react'

const UserflowAPIContext = createContext()


export default UserflowAPIContext

export function useUserflowAPI() {
  return useContext(UserflowAPIContext)
}

