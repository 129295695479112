import createActionCreator from 'utils/redux'

export const LOADING = `${ __filename }/LOADING`

export const setLoading = createActionCreator(LOADING)

export const initState = {
  loading: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case LOADING: return {
      ...state,
      loading: payload,
    }
    default: return state
  }
}
