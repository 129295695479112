import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { SketchPicker } from 'react-color'

import withFormContext from 'hoc/withFormContext'

import styles from './input.scss'

class ColorPicker extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
    _reduxForm: PropTypes.shape({
      dispatch: PropTypes.func,
      change: PropTypes.func,
    }),
  }

  static defaultProps = {
    input: { value: '#fff' },
  }

  handleChange = (color) => {
    const { dispatch, change } = this.props._reduxForm
    dispatch(change(this.props.input.name, color.hex))
  }

  render() {
    const { input } = this.props

    return (
      <label className={ styles['sketch-container'] }>
        <input
          { ...input }
          disabled
          className={ styles.hidden }
          type="text"
        />
        <SketchPicker
          disableAlpha
          color={ input.value }
          onChange={ this.handleChange }
        />
      </label>
    )
  }
}

export default withFormContext(ColorPicker)

export const _private = {
  ColorPicker,
}
