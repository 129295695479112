import React from 'react'

import toast, {
  Toaster as ReactHotToaster
} from 'react-hot-toast'

import Toast from './Toast'

const CONTAINER_STYLE = {
  top: '3.125rem',
  bottom: '3.125rem',
  left: '3.125rem',
  right: '3.125rem',
}

const POSITION = 'bottom-left'

// Unfortunately, we can't use CSS modules to style the Toast components
// that this component renders...at least not without making everything
// `!important`. Even trying to use ./Toast/styles.scss here as a
// `className` option is ineffective.
//
const TOAST_OPTIONS = {
  style: {
    background: '#363840',
    border: '1px solid #ced4da',
    borderRadius: '3px',
    boxShadow: '0px 4px 10px rgba(0, 50, 123, 0.1)',
    color: '#fff',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: 1.25,
    maxWidth: 'none',
    padding: '1rem',
  }
}


export default function Toaster() {
  return (
    <ReactHotToaster
      containerStyle={ CONTAINER_STYLE }
      position={ POSITION }
      toastOptions={ TOAST_OPTIONS }
    >
      {
        (toastInstance) => (
          <Toast
            toast={ toastInstance }
          />
        )
      }
    </ReactHotToaster>
  )
}

export { toast }
