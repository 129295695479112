import validateCard from './validateCard'

const formatCVC = (value) => {
  if (!value) {
    return value
  }
  const cvcLenLimit = validateCard.cardCVCFormat
  const onlyNums = value.replace(/[^\d]/g,'')
  if (value.length > cvcLenLimit) {
    return onlyNums.slice(0, cvcLenLimit)
  }
  return onlyNums
}

const formatCardNum = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length < 4) {
    return onlyNums
  } else {
    const cardInfo = validateCard.getCardInfo(onlyNums)
    const blockLen = !!cardInfo && validateCard.getFormatBlocksLen(cardInfo.blocks)
    const formattedValue = !!cardInfo && validateCard.getFormattedValue(onlyNums, cardInfo.blocks, blockLen, ' ')
    return formattedValue
  }
}

const formatPhone = (value) => {
  if (!value) {
    return ''
  }
  const phone = value.replace(/[^\d( )+-]+/g, '')
  if (value.length > 20) {
    return phone.slice(0, 20)
  }
  return phone
}

const formatZip = (value) => {
  if (!value) {
    return ''
  }
  const zipcode = value.toString().replace(/[^\w, -]+/g, '')
  if (value.length > 16) {
    return zipcode.slice(0, 16)
  }
  return zipcode
}

const formatUrl = (value) => {
  if (!value) {
    return ''
  }
  return value.replace(/^https?:\/\//, '')
}

export default (normalizeType, value) => {
  switch (normalizeType) {
    case 'CVC':
      return formatCVC(value)
    case 'CARD':
      return formatCardNum(value)
    case 'PHONE':
      return formatPhone(value)
    case 'ZIP':
      return formatZip(value)
    case 'URL':
      return formatUrl(value)
    default:
      return value
  }
}
