import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import closeIcon from 'assets/images/close-icon.svg'

import Styles from './styles.scss'


// TODO: [TER-10841] Get rid of this `onClickCloseButton`  prop.
// We should not be passing an `onClick`, particularly when it gets passed
// to a standard built-in component like <button>.
export default function ModalContent({ children, onClickCloseButton }) {
  const closeButtonClassName = classnames(Styles.closeButton, 't-closeModal', {
    [Styles.noClose]: !onClickCloseButton,
  })

  return (
    <div className={ Styles.root }>
      <button
        className={ closeButtonClassName }
        onClick={ onClickCloseButton }
        type="button"
      >
        <img
          alt="close"
          src={ closeIcon }
        />
      </button>

      { children }
    </div>
  )
}

ModalContent.propTypes = {
  children: PropTypes.node,
  onClickCloseButton: PropTypes.func,
}

ModalContent.defaultProps = {
  children: null,
}
