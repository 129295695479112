import QueryString from 'query-string'


/**
 * Returns a function that turns the pattern into an actual URL with
 * any placeholders filled in with the supplied parameters.
 *
 * @param {String} template - a URL or URL pattern (e.g. '/foo/:id')
 *
 * @returns {Function}
 */
export default function buildUrl(template) {
  /**
   * @private
   *
   * @param {Object} params
   *
   * @returns {String}
   */
  return (params, query) => {
    const url = interpolate(template, params)

    return QueryString.stringifyUrl({
      url,
      query,
    })
  }
}

/**
 * @private
 *
 * @param {String} template
 * @param {Object} keyValuePairs
 *
 * @returns {String} the `template` interpolated with the `keyValuePairs`
 *
 * @example
 *   interpolate('/foo/:bar', { bar: 'black/white' })
 *   => '/foo/black%2Fwhite'
 */
function interpolate(template, keyValuePairs) {
  if ( !keyValuePairs ) {
    return template
  }

  return Object.entries(keyValuePairs).reduce((url, [key, value]) => {
    return url.replace(`:${ key }`, encodeURIComponent(value))
  }, template)
}
