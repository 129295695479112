import Paths from 'constants/paths'

import Locale from './locale'

import SETTINGS_ICON from './img/settings-icon.svg'


export default function makeSettingsAccordion({ currentWebsiteID }) {
  return {
    icon: SETTINGS_ICON,
    id: 'settings',
    name: Locale.settings,
    sections: [
      {
        name: Locale.website,
        links: [
          {
            id: 'businessInfo',
            name: Locale.businessInfo,
            url: Paths.BUSINESS_INFO(currentWebsiteID),
          },
          {
            id: 'websiteInfo',
            name: Locale.websiteInfo,
            url: Paths.WEBSITE_INFO(currentWebsiteID),
          },
        ],
      },
      {
        name: Locale.developerSubsectionTitle,
        links: [
          {
            id: 'apiKey',
            name: Locale.apiKey,
            url: Paths.API_KEY(currentWebsiteID),
          },
          {
            id: 'documenation',
            name: Locale.documentation,
            url: 'https://help.termly.io/support/home',
            newTab: true,
          },
        ],
      },
    ],
  }
}
