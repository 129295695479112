import {
  getFormValues,
  getFormSyncErrors,
  isSubmitting,
  isValid,
} from 'redux-form'

import businessInfoFormIds from 'constants/formIds/businessInfo'

export const getValues       = getFormValues(businessInfoFormIds.name)
export const getSyncErrors   = getFormSyncErrors(businessInfoFormIds.name)
export const getIsSubmitting = isSubmitting(businessInfoFormIds.name)
export const getIsValid      = isValid(businessInfoFormIds.name)
