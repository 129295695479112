import createActionCreator from 'utils/redux'

export const GET_PAGEVIEW = 'GET_PAGEVIEW'
export const GET_PAGEVIEW_SUCCESS = 'GET_PAGEVIEW_SUCCESS'
export const SET_VIEW_SELECT = 'SET_VIEW_SELECT'

const initialState = {
  selectedMonth: '',
  months: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGEVIEW_SUCCESS: {
      return {
        ...state,
        months: [...state.months, action.payload],
      }
    }
    case SET_VIEW_SELECT: {
      return {
        ...state,
        selectedMonth: action.payload,
      }
    }
    default: return state
  }
}

export const getPageview = createActionCreator(GET_PAGEVIEW)
export const getPageviewSuccess = createActionCreator(GET_PAGEVIEW_SUCCESS)
export const setViewSelect = createActionCreator(SET_VIEW_SELECT)
