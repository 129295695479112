import { useCallback } from 'react'

import { useUserPolicy } from 'components/UserPolicyProvider'

import policyAllows from '../lib/policyAllows'


export default function useIsGroupAllowed() {
  const { userPolicy } = useUserPolicy()

  return useCallback(({ policy }) => {
    return policyAllows(userPolicy, policy)
  }, [userPolicy])
}
