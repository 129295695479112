import { defineMessages } from 'react-intl'


export default defineMessages({
  apiKey: {
    defaultMessage: 'API Key',
    id: 'dashboard-sidebar.api-key',
  },

  businessInfo: {
    defaultMessage: 'Business Information',
    id: 'dashboard-sidebar.business-info',
  },

  ccpaSettings: {
    defaultMessage: 'California Requirements',
    id: 'dashboard-sidebar.ccpa-settings',
  },

  consentBanner: {
    defaultMessage: 'Consent Banner',
    id: 'dashboard-sidebar.consent-banner',
  },

  consentLogs: {
    defaultMessage: 'Consent Logs',
    id: 'dashboard-sidebar.consent-logs',
  },

  consentManagement: {
    defaultMessage: 'Consent Management',
    id: 'dashboard-sidebar.consent-management',
  },

  consentManagementPlatform: {
    defaultMessage: 'Consent Management Platform',
    id: 'dashboard-sidebar.consent-management-platform',
  },

  cookies: {
    defaultMessage: 'Cookies',
    id: 'dashboard-sidebar.cookies',
  },

  dataSources: {
    defaultMessage: 'Data Sources',
    id: 'dashboard-sidebar.data-sources',
  },

  developerSubsectionTitle: {
    defaultMessage: 'Developer',
    id: 'dashboaord-sidebar.developer-subsection-title',
  },

  documentation: {
    defaultMessage: 'Documentation',
    id: 'dashboard-sidebar.documentation',
  },

  dsarForm: {
    defaultMessage: 'DSAR Form',
    id: 'dashboard-sidebar.dsar-form',
  },

  dsarManagerSubsectionTitle: {
    defaultMessage: 'DSAR Manager',
    id: 'dashboaord-sidebar.dsar-manager-subsection-title',
  },

  googleConsentMode: {
    defaultMessage: 'Google Consent Mode',
    id: 'dashboard-sidebar.google-consent-mode',
  },

  policies: {
    defaultMessage: 'Policies',
    id: 'dashboard-sidebar.policies',
  },

  scanner: {
    defaultMessage: 'Scanner',
    id: 'dashboard-sidebar.scanner',
  },

  settings: {
    defaultMessage: 'Settings',
    id: 'dashboard-sidebar.settings',
  },

  vendors: {
    defaultMessage: 'Vendors',
    id: 'dashboard-sidebar.vendors',
  },

  website: {
    defaultMessage: 'Website',
    id: 'dashboard-sidebar.website',
  },

  websiteInfo: {
    defaultMessage: 'Website Information',
    id: 'dashboard-sidebar.website-info',
  },
})
