import React, {
  useCallback,
} from 'react'

import { useModal } from 'components/ModalProvider'

import LazyAddWebsiteModal from './components/LazyAddWebsiteModal'


export default function useAddWebsiteModal({ handleSuccess: handleSuccessProp } = {}) {
  const modal = useModal()

  const handleSuccess = useCallback(({ websiteID }) => {
    if ( handleSuccessProp ) {
      handleSuccessProp({ websiteID })
    }

    modal.close()
  }, [handleSuccessProp, modal])

  modal.setModalContent(
    <LazyAddWebsiteModal
      handleCancel={ modal.close }
      handleSuccess={ handleSuccess }
    />
  )

  return modal
}
