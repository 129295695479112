import { datadogLogs } from '@datadog/browser-logs'

import { GtmAPI } from '@termly_web/common'

const GOOGLE_ADWORDS_CONVERSION_TRACKER_ID = process.env.GOOGLE_ADWORDS_CONVERSION_TRACKER_ID
const GTM_CONTAINER_ID = process.env.GTM_CONTAINER_ID


// N.B.: this callback will be invoked once per container, and our
// container happens to contain multiple other containers. The one
// we're interested in is the super container, the ID of which is
// stored in GTM_CONTAINER_ID.
export default function eventCallback(id, { tags }) {
  if ( id !== GTM_CONTAINER_ID ) {
    return
  }

  datadogLogs.logger.info('eventCallback arguments', {
    arguments: Array.from(arguments),
  })

  const hasTimeout = tags?.some(({ status }) => status === 'timeout')

  if ( hasTimeout ) {
    datadogLogs.logger.warn(`trackSignUp: TIMEOUT on container ${ id }`)

    datadogLogs.logger.info(`Sending manual AdWords conversion to ${ GOOGLE_ADWORDS_CONVERSION_TRACKER_ID }`)

    GtmAPI.gtag('event', 'conversion', {
      send_to: GOOGLE_ADWORDS_CONVERSION_TRACKER_ID,
    })
  } else {
    datadogLogs.logger.log('trackSignUp: success')
  }
}
