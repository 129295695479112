import {
  getFormValues as getReduxFormValues,
  isInvalid,
} from 'redux-form'

import {
  createSelector,
  createStructuredSelector,
} from 'reselect'

import formId from 'constants/formIds/customerRequest'

import {
  CCPA,
  DEFAULT_LAW_ACTIONS,
  REQUEST_AS_PERSONAL,
} from './constants'

export const getFormValues = getReduxFormValues(formId.name)

export const getPathname = (state) =>
  state.routing.locationBeforeTransitions.pathname

export const getSnippetWebsite = (state) => state.responses.snippetWebsite

export const getDSARSettings = createSelector(
  getSnippetWebsite,
  ({ dsar_settings }) => dsar_settings || {},
)

export const getWebsiteName = createSelector(
  getSnippetWebsite,
  (website) => website.name
)

export const getWebsiteUUID = createSelector(
  getSnippetWebsite,
  (website) => website.uuid
)

const getIsHeaderShown = createSelector(
  getSnippetWebsite,
  ({ show_logo }) => show_logo
)

export const getInitializeFormValues = createSelector(
  getWebsiteName,
  (websiteName) => ({
    [formId.fields.website]: websiteName,
    [formId.fields.requestAs]: REQUEST_AS_PERSONAL,
    [formId.fields.complianceType]: CCPA,
    [formId.fields.action]: DEFAULT_LAW_ACTIONS[CCPA],
  })
)

export const getSelectedRequestLaw = createSelector(
  getFormValues,
  ({ [formId.fields.complianceType]: value } = {}) => value,
)

export const getCustomerName = createSelector(
  getFormValues,
  ({ [formId.fields.name]: value } = {}) => value,
)

export const getEmail = createSelector(
  getFormValues,
  ({ [formId.fields.email]: value } = {}) => value,
)

export const getRequestAs = createSelector(
  getFormValues,
  ({ [formId.fields.requestAs]: value } = {}) => value,
)

export const getAction = createSelector(
  getFormValues,
  ({ [formId.fields.action]: value } = {}) => value,
)

export const getComment = createSelector(
  getFormValues,
  ({ [formId.fields.content]: value } = {}) => value,
)

export const getDeclareInformation = createSelector(
  getFormValues,
  ({ [formId.fields.declareInformation]: value } = {}) => value,
)

export const getUnderstandDeletion = createSelector(
  getFormValues,
  ({ [formId.fields.understandDeletion]: value } = {}) => value,
)

export const getValidateEmail = createSelector(
  getFormValues,
  ({ [formId.fields.validateEmail]: value } = {}) => value,
)

export const getIsConfirmedRequired = createSelector(
  getDeclareInformation,
  getUnderstandDeletion,
  getValidateEmail,
  (
    declareInformation,
    understandDeletion,
    validateEmail
  ) => declareInformation && understandDeletion && validateEmail
)

export const getRequestParams = createSelector(
  getCustomerName,
  getEmail,
  getRequestAs,
  getSelectedRequestLaw,
  getAction,
  getComment,
  (
    name,
    email,
    request_as,
    compliance_type,
    action,
    content,
  ) => ({
    name,
    email,
    identity_type: request_as,
    compliance_type,
    action,
    detail: { content },
  })
)

export const getFormInvalidStatus = (state) => isInvalid(formId.name)(state)

export const getShowLogo = createSelector(
  getSnippetWebsite,
  (website) => website.show_logo
)

export const getPageParams = createStructuredSelector({
  isConfirmedRequired: getIsConfirmedRequired,
  isHeaderShown: getIsHeaderShown,
  selectedRequestLaw: getSelectedRequestLaw,
  isLogoShown: getShowLogo,
  websiteName: getWebsiteName,
})

export const _private = {
  getIsHeaderShown,
}
