import { defineMessages } from 'react-intl'


export default defineMessages({
  errorMessage: {
    defaultMessage: 'There was an error. Please refresh the page',
    id: 'notification-banner.error-message',
  },

  learnMore: {
    defaultMessage: 'Learn More',
    id: 'notification-banner.learn-more-button',
  },

  notificationConsentCount: {
    defaultMessage: 'You\'ve reached your account\'s consent limit and your consent banner has been disabled. To continue collecting user consent, please upgrade your account.',
    id: 'notification-banner.alert-message-consent-count',
  },

  notificationPolicyLimit: {
    defaultMessage: 'You have surpassed your 1 policy limit. Please upgrade to publish additional policies.',
    id: 'notification-banner.alert-message-policy-limit',
  },

  subscriptionPastDue: {
    defaultMessage: 'Your subscription is past due. Please update your card on file.',
    id: 'notification-banner.subscription-past-due',
  },

  updateNow: {
    defaultMessage: 'Update Now',
    id: 'notification-banner.update-now-button',
  },
})
