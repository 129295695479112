import { combineReducers } from 'redux'

import deleteWebsite from './deleteWebsite'
import deleteCookie from './deleteCookie'
import changeWebsiteUrl from './changeWebsiteUrl'
import themeSaver from './themeSaver'
import deleteTheme from './deleteTheme'
import deleteAccount from './deleteAccount'
import policyMigrator from './policyMigrator'


export default combineReducers({
  deleteWebsite,
  deleteCookie,
  changeWebsiteUrl,
  themeSaver,
  deleteTheme,
  deleteAccount,
  policyMigrator,
})
