import { createSelector } from 'reselect'


export const selector = (state) => state.responses.websites

export const getIsLoading = createSelector(
  selector,
  ({ isLoading }) => isLoading
)

export const getWebsites = createSelector(
  selector,
  ({ websites }) => websites || []
)

export const getWebsiteIds = createSelector(
  getWebsites,
  (websites) => websites.map((website = {}) => website.id),
)

export const getWebsiteNames = createSelector(
  getWebsites,
  (websites) => websites.map((website = {}) => website.name),
)

export const getWebsiteByIndex = createSelector(
  (state, { index }) => index,
  getWebsites,
  (index, websites = []) => websites[index]
)

export const getFirstWebsite = createSelector(
  (state) => getWebsiteByIndex(state, { index: 0 }),
  (v = {}) => v
)

export const getFirstWebsiteUUID = createSelector(
  getFirstWebsite,
  ({ uuid } = {}) => uuid
)

// INTERNAL ONLY
export const getWebsiteSelector = createSelector(
  getWebsites,
  (websites) => (websiteId) => {
    return websites && websites.find((website) => matchesIdOrUUID(website, websiteId))
  }
)

export const getMigrated = createSelector(
  selector,
  ({ migrated }) => migrated || []
)

function matchesIdOrUUID(website, id) {
  return ( website.uuid === id || website.id === parseInt(id, 10) )
}

