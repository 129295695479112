import React from 'react'

import {
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'

import {
  CustomRouter,
  URLBuilder,
  UserTrackerProvider,
} from '@termly_web/common'

import Paths from 'constants/paths'

import lazyLoad from 'utils/lazyLoad'
import withSuspense from 'utils/withSuspense'

import history from 'store/history'

// This cannot be lazy-loaded because iframe-resizer (used in policy-viewer)
// cannot deal with it.
import PolicyNotFound from 'pages/PageNotFoundPage/PolicyNotFound'

import BannerSettingsRedirect from './BannerSettingsRedirect'

const CheckoutPage = lazyLoad(() => import('pages/CheckoutPage'))
const CustomerRequestSuccessPage = lazyLoad(() => import('pages/CustomerRequestSuccessPage'))
const LoginFailedPage = lazyLoad(() => import('pages/LoginFailedPage'))
const NonWebsitePages = lazyLoad(() => import('components/NonWebsitePages'))
const PageNotFoundPage = lazyLoad(() => import('pages/PageNotFoundPage'))
const PartnerOnboardPage = lazyLoad(() => import('pages/PartnerOnboardPage'))
const PublicDSARFormPage = lazyLoad(() => import('pages/PublicDSARFormPage'))
const SessionExpiredPage = lazyLoad(() => import('pages/SessionExpiredPage'))
const WebsitePages = lazyLoad(() => import('components/WebsitePages'))

// /user pages
const CreateAccountPage = lazyLoad(() => import('pages/CreateAccountPage'))
const ForgotPasswordPage = lazyLoad(() => import('pages/ForgotPasswordPage'))
const LogOutPage = lazyLoad(() => import('pages/LogOutPage'))
const MigrateAccountPage = lazyLoad(() => import('pages/MigrateAccountPage'))
const ResetPasswordPage = lazyLoad(() => import('pages/ResetPasswordPage'))
const SignInPage = lazyLoad(() => import('pages/SignInPage'))
const SignUpPage = lazyLoad(() => import('pages/SignUpPage'))

// Non-website pages
const AccountPage = lazyLoad(() => import('pages/AccountPage'))
const CMPOnboardingPage = lazyLoad(() => import('pages/CMPOnboardingPage'))
const WebsiteLimitPage = lazyLoad(() => import('pages/WebsiteLimitPage'))
const BillingHistoryPage = lazyLoad(() => import('pages/BillingHistoryPage'))
const BillingInfoPage = lazyLoad(() => import('pages/BillingInfoPage'))
const CheckoutPreviewPage = lazyLoad(() => import('pages/CheckoutPreviewPage'))
const DashboardIndexPage = lazyLoad(() => import('pages/DashboardIndexPage'))
const DeleteAccountPage = lazyLoad(() => import('pages/DeleteAccountPage'))
const PlanInfoPage = lazyLoad(() => import('pages/PlanInfoPage'))
const ReceiptPage = lazyLoad(() => import('pages/ReceiptPage'))
const SiteAuditPage = lazyLoad(() => import('pages/SiteAuditPage'))
const SubscriptionManagerPage = lazyLoad(() => import('pages/SubscriptionManagerPage'))
const SupportPage = lazyLoad(() => import('pages/SupportPage'))
const ThreeDSAuthCompletePage = lazyLoad(() => import('pages/ThreeDSAuthCompletePage'))
const ThreeDSAuthFailedPage = lazyLoad(() => import('pages/ThreeDSAuthFailedPage'))
const UserManagementPage = lazyLoad(() => import('pages/UserManagementPage'))
const WebsiteManagementPage = lazyLoad(() => import('pages/WebsiteManagementPage'))

// Website pages
const APIKeyPage = lazyLoad(() => import('pages/APIKeyPage'))
const AddCookiePage = lazyLoad(() => import('pages/AddCookiePage'))
const AddNewWebsitePage = lazyLoad(() => import('pages/AddNewWebsitePage'))
const BannerSettingsPage = lazyLoad(() => import('pages/BannerSettingsPage'))
const BusinessInfoPage = lazyLoad(() => import('pages/BusinessInfoPage'))
const CCPASettingsPage = lazyLoad(() => import('pages/CCPASettingsPage'))
const ConsentLogsPage = lazyLoad(() => import('pages/ConsentLogsPage'))
const DSARFormPage = lazyLoad(() => import('pages/DSARFormPage'))
const DudaIntegrationPage = lazyLoad(() => import('pages/DudaIntegrationPage'))
const EditCookiePage = lazyLoad(() => import('pages/EditCookiePage'))
const EditWebsiteInfoPage = lazyLoad(() => import('pages/EditWebsiteInfoPage'))
const PolicyPage = lazyLoad(() => import('pages/PolicyPage'))
const ScanReportPage = lazyLoad(() => import('pages/ScanReportPage'))
const ScanSettingsPage = lazyLoad(() => import('pages/ScanSettingsPage'))
const VendorsPage = lazyLoad(() => import('pages/VendorsPage'))
const WebsiteDashboardPage = lazyLoad(() => import('pages/WebsiteDashboardPage'))

const PagesRequiringUserRecord = lazyLoad(() => import('./PagesRequiringUserRecord'))


export default function Router() {
  return (
    <CustomRouter
      history={ history }
    >
      <Routes>
        <Route
          element={ <Navigate to={ Paths.DASHBOARD } /> }
          path="/"
        />

        <Route
          element={ withSuspense(PublicDSARFormPage) }
          path={ Paths.PUBLIC_DSAR() }
        />

        <Route
          element={ withSuspense(CustomerRequestSuccessPage) }
          path={ Paths.NOTIFY_ACTIONS_SUCCESS() }
        />

        <Route
          element={ <Navigate to={ Paths.RECEIPT() } /> }
          path={ Paths.LEGACY_RECEIPT() }
        />

        <Route
          element={ <Navigate to={ Paths.DASHBOARD } /> }
          path="/dashboard/website"
        />

        <Route
          index
          element={ <Navigate to={ Paths.LOGIN } /> }
        />

        <Route
          element={ withSuspense(DudaIntegrationPage) }
          path={ URLBuilder.dudaIntegrationURL() }
        />

        <Route
          element={ withSuspense(ThreeDSAuthCompletePage) }
          path={ URLBuilder.threeDSAuthCompleteURL() }
        />

        <Route
          element={ <UserTrackerProvider><Outlet /></UserTrackerProvider> }
        >
          <Route
            element={ withSuspense(LogOutPage) }
            path={ URLBuilder.logoutURL() }
          />

          <Route
            element={ withSuspense(LoginFailedPage) }
            path={ Paths.LOGIN_FAILED }
          />

          <Route
            element={ withSuspense(SessionExpiredPage) }
            path={ Paths.SESSION_EXPIRED }
          />

          <Route
            element={ withSuspense(PartnerOnboardPage) }
            path={ Paths.PARTNER_ONBOARD() }
          />

          <Route element={ withSuspense(PagesRequiringUserRecord) }>
            <Route
              element={ withSuspense(SignInPage) }
              path={ Paths.LOGIN }
            />

            <Route
              element={ withSuspense(SignUpPage) }
              path={ URLBuilder.signUpURL() }
            />

            <Route
              element={ withSuspense(ForgotPasswordPage) }
              path={ Paths.FORGOT_PWD }
            />

            <Route
              element={ withSuspense(ResetPasswordPage) }
              path={ Paths.RESET_PWD }
            />

            <Route
              element={ withSuspense(DashboardIndexPage) }
              path={ Paths.DASHBOARD }
            />

            <Route
              element={ withSuspense(CheckoutPage) }
              path={ Paths.CHECKOUT() }
            />

            <Route
              element={ withSuspense(SiteAuditPage) }
              path={ Paths.AUDIT }
            />

            <Route
              element={ withSuspense(CreateAccountPage) }
              path={ Paths.CREATE_ACCOUNT }
            />

            <Route
              element={ withSuspense(MigrateAccountPage) }
              path={ Paths.MIGRATE_ACCOUNT }
            />

            <Route
              element={ withSuspense(WebsitePages) }
              path={ URLBuilder.websiteDashboardURL() }
            >
              <Route
                index
                element={ withSuspense(WebsiteDashboardPage) }
              />
              <Route
                element={ withSuspense(VendorsPage) }
                path={ URLBuilder.websiteVendorsPageURL() }
              />
              <Route
                element={ withSuspense(CMPOnboardingPage) }
                path={ URLBuilder.websiteCMPOnboardingURL() }
              />
              <Route
                element={ withSuspense(AddCookiePage) }
                path={ Paths.REPORT_ADD_COOKIE() }
              />
              <Route
                element={ withSuspense(EditCookiePage) }
                path={ Paths.REPORT_EDIT_COOKIE() }
              />
              <Route
                element={ withSuspense(ScanReportPage) }
                path={ Paths.COOKIE_REPORT() }
              />
              <Route
                element={ withSuspense(ScanSettingsPage) }
                path={ Paths.SCAN_SETTINGS() }
              />
              <Route
                element={ withSuspense(EditWebsiteInfoPage) }
                path={ Paths.WEBSITE_INFO() }
              />
              <Route
                element={ withSuspense(BusinessInfoPage) }
                path={ Paths.BUSINESS_INFO() }
              />
              <Route
                element={ withSuspense(ConsentLogsPage) }
                path={ Paths.CONSENT_LOGS() }
              />
              <Route
                element={ withSuspense(APIKeyPage) }
                path={ Paths.API_KEY() }
              />

              { /* BEGIN DEPRECATED URLS */ }
              <Route
                element={ <BannerSettingsRedirect /> }
                path={ Paths.EMBED_CONSENT_BANNER() }
              />
              <Route
                element={ <BannerSettingsRedirect /> }
                path={ Paths.EMBED_PREFERENCE_CENTER_BUTTON() }
              />
              { /* END DEPRECATED URLS */ }

              <Route
                element={ withSuspense(CCPASettingsPage) }
                path={ Paths.CCPA_SETTINGS() }
              />
              <Route
                element={ withSuspense(DSARFormPage) }
                path={ Paths.DSAR_FORM() }
              />
              <Route
                element={ withSuspense(BannerSettingsPage) }
                path={ Paths.BANNER_SETTINGS() }
              />
              <Route
                element={ withSuspense(PolicyPage) }
                path={ URLBuilder.policyDetailsURL() }
              />
            </Route>

            <Route
              element={ withSuspense(NonWebsitePages) }
              path={ Paths.DASHBOARD }
            >
              <Route
                element={ withSuspense(CMPOnboardingPage) }
                path={ Paths.CMP_ONBOARDING }
              />
              <Route
                element={ withSuspense(AddNewWebsitePage) }
                path={ Paths.NEW_WEBSITE }
              />
              <Route
                element={ withSuspense(WebsiteLimitPage) }
                path={ Paths.WEBSITE_LIMIT }
              />
              <Route
                element={ withSuspense(DeleteAccountPage) }
                path={ Paths.DELETE_ACCOUNT }
              />
              <Route
                element={ withSuspense(UserManagementPage) }
                path={ Paths.USER_MANAGEMENT }
              />
              <Route
                element={ withSuspense(WebsiteManagementPage) }
                path={ URLBuilder.websitesURL() }
              />
              <Route
                element={ withSuspense(AccountPage) }
                path={ Paths.ACCOUNT }
              />

              <Route
                element={ withSuspense(BillingHistoryPage) }
                path={ Paths.BILLING_HISTORY }
              />
              <Route
                element={ withSuspense(BillingInfoPage) }
                path={ Paths.BILLING }
              />
              <Route
                element={ withSuspense(CheckoutPreviewPage) }
                path={ Paths.CHECKOUT_PREVIEW }
              />
              <Route
                element={ withSuspense(SupportPage) }
                path={ URLBuilder.supportURL() }
              />
              <Route
                element={ withSuspense(ReceiptPage) }
                path={ Paths.RECEIPT() }
              />
              <Route
                element={ withSuspense(PlanInfoPage) }
                path={ Paths.PLAN_INFO }
              />
              <Route
                element={ withSuspense(ThreeDSAuthFailedPage) }
                path={ URLBuilder.threeDSAuthFailedURL() }
              />
              <Route
                element={ withSuspense(SubscriptionManagerPage) }
                path={ URLBuilder.subscriptionManagerURL() }
              />
            </Route>

            <Route
              element={ withSuspense(PageNotFoundPage) }
              path="*"
            />
          </Route>
        </Route>

        <Route
          exact
          element={ <PolicyNotFound /> }
          path={ Paths.POLICY_NOT_FOUND }
        />

        <Route
          element={ withSuspense(PageNotFoundPage) }
          path="*"
        />
      </Routes>
    </CustomRouter>
  )
}
