import React, {
  useCallback,
} from 'react'

import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from './styles.scss'


export default function TopLevelLink(props) {
  const {
    handleClick,
    hasCarat,
    icon,
    id,
    isOpen,
    name,
  } = props

  const onClick = useCallback((e) => {
    e.stopPropagation()

    handleClick({ id })
  }, [handleClick, id])

  const rootClassName = classnames(Styles.root, `t-accordionName--${ id }`)

  return (
    <div
      className={ rootClassName }
      onClick={ onClick }
    >
      <div className={ Styles.icon }>
        <img
          src={ icon }
        />
      </div>

      <div className={ Styles.text }>
        <FormattedMessage
          { ...name }
        />
      </div>

      {
        renderCarat({
          hasCarat,
          isOpen,
        })
      }
    </div>
  )
}

TopLevelLink.propTypes = {
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.shape({}).isRequired,

  className: PropTypes.string,
  hasCarat: PropTypes.bool,
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
}

function renderCarat({ hasCarat, isOpen }) {
  if ( !hasCarat ) {
    return null
  }

  const accordionNameCaratClassName = classnames(Styles.carat, {
    [Styles['carat--open']]: isOpen,
  })

  return (
    <div className={ accordionNameCaratClassName } />
  )
}
