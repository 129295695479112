import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import Description from 'components/Description'
import Title from 'components/Title'

import style from './missing-content.scss'
import missingContent from './locale'


export default class MissingContent extends PureComponent {
  static propTypes = {
    logo:    PropTypes.string,
    title:   PropTypes.string,
    caption: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
  }
  static defaultProps = {
    logo: 'no-invoice',
    title: '',
    caption: '',
    className: 'missing-content-block',
  }

  render() {
    const {
      logo,
      title,
      caption,
      children,
      className,
    } = this.props

    return (
      <div className={ style[className] }>
        <div className={ style[logo] } />
        <Title
          className={ style['title'] }
          show={ !!title }
          tag="h4"
          text={ missingContent[title] }
        />
        <Description
          className={ style['caption'] }
          show={ !!caption }
          text={ missingContent[caption] }
        />
        { children }
      </div>
    )
  }
}
