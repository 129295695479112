import { useMutation } from '@tanstack/react-query'

import makeMutator from './makeMutator'


export default function useWebsiteOptionsMutation({ orderBy, pageSize }, options) {
  const mutator = makeMutator({
    orderBy,
    pageSize,
  })

  return useMutation(mutator, options)
}
