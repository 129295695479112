import React from 'react'

import {
  Navigate,
  useParams,
} from 'react-router-dom'

import Paths from 'constants/paths'


export default function BannerSettingsRedirect() {
  const { id } = useParams()

  return (
    <Navigate
      to={ Paths.BANNER_SETTINGS(id) }
    />
  )
}
