import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import cx from 'classnames'

import { FormattedMessage, injectIntl } from 'react-intl'

import Popover from 'components/Popover'
import mapClassNameToArray from 'utils/mapClassName'

import styles from '../Input/input.scss'

class Select extends PureComponent {
  static propTypes = {
    label: PropTypes.shape(FormattedMessage.propTypes),
    className: PropTypes.string,
    containerClass: PropTypes.string,
    placeholder: PropTypes.shape(FormattedMessage.propTypes),
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
      ]),
    ),
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape(FormattedMessage.propTypes),
      valid: PropTypes.bool,
    }),
    ids: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
    arrow: PropTypes.string,
    labelClass: PropTypes.string,
    supplement: PropTypes.node,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }),
  }

  static defaultProps = {
    label: null,
    className: '',
    containerClass: 'input-container',
    placeholder: null,
    options: [],
    meta: {},
    ids: [],
    input: {},
    arrow: 'label-arrow',
    labelClass: 'label-input',
    supplement: null,
    disabled: false,
  }

  render() {
    const {
      input,
      input: { value },
      placeholder,
      label,
      options,
      meta: { touched, error, valid },
      className,
      containerClass,
      ids,
      arrow,
      intl: { formatMessage },
      labelClass,
      supplement,
      disabled,
    } = this.props

    const selectStyle = cx(
      styles['form-control'],
      ...mapClassNameToArray(className, styles),
      {
        [styles.error]: touched && error,
        [styles['select-placeholder']]: !value || !valid,
      },
    )

    const containerStyle = cx(
      ...mapClassNameToArray(containerClass, styles),
    )

    return (
      <div className={ containerStyle }>
        <label className={ styles[labelClass] }>
          { label && <span>{ label && <FormattedMessage { ...label } /> }{ supplement }</span> }
          <div className={ styles.position }>
            <label className={ styles[arrow] }>
              <select
                { ...input }
                className={ selectStyle }
                disabled={ disabled }
              >
                {
                  placeholder &&
                  <option value="">
                    { formatMessage(placeholder) }
                  </option>
                }
                {
                  options.map((option, i) => (
                    <option
                      key={ i }
                      value={ ids[i] || option }
                    >
                      { option }
                    </option>
                  ))
                }
              </select>
            </label>
            <Popover
              className="popover-danger"
              show={ touched && !!error }
            >
              { error && <FormattedMessage { ...error } /> }
            </Popover>
          </div>
        </label>
      </div>
    )
  }
}

export default injectIntl(Select)

export const _private = {
  Select,
}
