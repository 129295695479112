import createActionCreator from 'utils/redux'

export const SET_DATA = `${ __filename }/SET_DATA`
export const SET_LOADING = `${ __filename }/SET_LOADING`

export const setData = createActionCreator(SET_DATA)
export const setLoading = createActionCreator(SET_LOADING)

export const INITIAL_STATE = {
}

export default function websitesReducer(state = INITIAL_STATE, { type, payload }) {
  switch ( type ) {
    case SET_DATA:
      return {
        ...payload,
        isLoading: false,
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    default:
      return state
  }
}
