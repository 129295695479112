import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import cx from 'classnames'

import { FormattedMessage, injectIntl } from 'react-intl'

import normalizer from 'utils/normalizer'
import Popover from 'components/Popover'
import Description from 'components/AutoFormattedMessage'


import mapClassNameToArray from 'utils/mapClassName'

import styles from './input.scss'

class Input extends PureComponent {
  static propTypes = {
    checkMark: PropTypes.bool,
    input: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
    placeholder: PropTypes.shape(FormattedMessage.propTypes),
    label: PropTypes.shape(FormattedMessage.propTypes),
    type: PropTypes.string,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape(FormattedMessage.propTypes),
      valid: PropTypes.bool,
    }),
    className: PropTypes.string,
    containerClass: PropTypes.string,
    normalizeType: PropTypes.string,
    description: Description.propTypes.text,
    id: PropTypes.string,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }),
    maxLength: PropTypes.number,
    labelClass: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    label: null,
    type: 'text',
    className: '',
    containerClass: 'input-container',
    normalizeType: null,
    meta: {},
    placeholder: null,
    input: {},
    description: null,
    labelClass: 'label-input',
    readOnly: false,
    disabled: false,
  }

  render() {
    const {
      input,
      input: { value },
      placeholder,
      label,
      type,
      meta: { touched, error, valid },
      className,
      containerClass,
      normalizeType,
      description,
      checkMark,
      maxLength,
      intl: { formatMessage },
      labelClass,
      readOnly,
      id,
      disabled,
    } = this.props

    const inputStyle = cx(
      styles['form-control'],
      ...mapClassNameToArray(className, styles),
      {
        [styles.error]: touched && error,
      }
    )
    const labelStyle = cx({
      [styles[labelClass]]: true,
      [styles['input-validate']]: value && checkMark && valid,
    })
    const containerStyle = cx(
      ...mapClassNameToArray(containerClass, styles),
    )

    const isEmpty = !value || value.length === 0
    return (
      <div
        className={ containerStyle }
        style={ type === 'hidden' ? { display: 'none' } : null }
      >
        <div className={ styles.position }>
          <label className={ labelStyle }>
            <span className={ styles['input-title'] }>{ label && <FormattedMessage { ...label } /> }</span>
            <Description
              className={ styles['input-description'] }
              show={ !!description }
              text={ description }
            />
            <input
              { ...input }
              className={ inputStyle }
              disabled={ disabled }
              id={ id }
              maxLength={ maxLength }
              placeholder={ isEmpty && placeholder ? formatMessage(placeholder) : null }
              readOnly={ readOnly }
              type={ type }
              value={ normalizeType ? normalizer(normalizeType, value) : value }
            />
          </label>
          <Popover
            className="popover-danger"
            show={ touched && !!error }
          >
            { error ? <FormattedMessage { ...error } /> : null }
          </Popover>
        </div>
      </div>
    )
  }
}

export default injectIntl(Input)

export const _private = {
  Input,
}
