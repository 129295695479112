import QueryString from 'query-string'

const GET_URI = 'v1/websites'


export default function makeURL({ currentPage, orderBy, pageSize }) {
  const query = {
    current_page: currentPage,
    order_by: orderBy,
    page_size: pageSize,
  }

  return QueryString.stringifyUrl({
    query,
    url: GET_URI,
  })
}
