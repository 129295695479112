import React, {
  useCallback,
} from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Button } from '@termly/react-components'

import { useUpgrade } from 'components/UpgradeProvider'

import Styles from './styles.scss'


// TODO: Now that the click handling is delegated to
// `UpgradeProvider`, we really should change the name of this
// component, since it no longer just redirects to another page.
// But, I cannot tackle that now...the current PR is already a
// bit on the large side.
//
export default function NavigateToPricingPageButton(props) {
  const {
    children,
    className,
    color,
    dataTracking,
    handleClick,
    queryParam,
    type,
  } = props

  const { upgrade } = useUpgrade()

  const handleButtonClick = useCallback(() => {
    if ( handleClick ) {
      handleClick()
      return
    }

    upgrade({
      queryParam,
    })
  }, [handleClick, queryParam, upgrade])

  const rootClassName = classnames(Styles.root, className)

  return (
    <Button
      preventDefault
      className={ rootClassName }
      color={ color }
      data-tracking={ dataTracking }
      handleClick={ handleButtonClick }
      type={ type }
    >
      { children }
    </Button>
  )
}

NavigateToPricingPageButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  dataTracking: PropTypes.string,
  handleClick: PropTypes.func,
  queryParam: PropTypes.string,
  type: PropTypes.string,
}
