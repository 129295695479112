import React from 'react'
import PropTypes from 'prop-types'
import { components as ReactSelectComponents } from 'react-select'

import SeeAllWebsites from './SeeAllWebsites'


export default function HeaderWebsiteMenuList(props) {
  const { selectProps } = props

  const {
    inputValue,
    menuProps,
  } = selectProps

  return (
    <ReactSelectComponents.MenuList
      { ...props }
    >
      { props.children }

      <SeeAllWebsites
        filter={ inputValue }
        show={ menuProps.showAllWebsites }
        totalWebsites={ menuProps.totalWebsites }
      />
    </ReactSelectComponents.MenuList>
  )
}

HeaderWebsiteMenuList.propTypes = {
  selectProps: PropTypes.shape({
    inputValue: SeeAllWebsites.propTypes.filter,
    menuProps: PropTypes.shape(SeeAllWebsites.propTypes).isRequired,
  }).isRequired,

  children: PropTypes.node,
}
