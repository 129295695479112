import { lazy } from 'react'

import HeapAPI from 'utils/heap-api'

const HEAP_EVENT = 'Lazy load failure message shown'

// Everybody to the limit.
// https://www.youtube.com/watch?v=KFNcStdF_Ok
const KEY = 'fhqwhgads'

const LazyLoadFailed = require('./LazyLoadFailed')


// H/T: https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export default function lazyLoad(importComponent) {
  return lazy(async () => {
    const isReloadPermitted = getReloadPermitted()

    try {
      return await importComponent()
    } catch (error) {
      if ( !isReloadPermitted ) {
        HeapAPI.track(HEAP_EVENT)

        return LazyLoadFailed
      }

      setFlag({ isReloaded: true })

      window.location.reload()
    }
  })
}


function setFlag({ isReloaded }) {
  sessionStorage.setItem(KEY, isReloaded)
}

function getReloadPermitted() {
  try {
    return sessionStorage.getItem(KEY) !== 'true'
  } catch (error) {
    console.error(error)
    return false
  }
}
