import React, {
  useEffect,
} from 'react'

import PropTypes from 'prop-types'

import {
  useDispatch,
  useSelector,
} from 'react-redux'

import DashboardLayout from './DashboardLayout'

import {
  WebsitePagesLayoutActions,
  WebsitePagesLayoutSelectors,
} from './store'


// TODO: This component needs to die. As far as I can tell, the only
// thing it is doing that's still needed is triggering the display of
// the "migrate policy" modal. I would do that now, but I'm just
// supposed to be fixing a bug, so making a big change like that is
// currently out of scope.
//
export default function WebsitePagesLayout({ children, websiteID }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(WebsitePagesLayoutActions.initialize({
      websiteID,
    }))
  }, [dispatch, websiteID])

  const isLoadingWebsites = useSelector(WebsitePagesLayoutSelectors.selectIsLoadingWebsites)

  if ( isLoadingWebsites ) {
    return null
  }

  return (
    <DashboardLayout>
      { children }
    </DashboardLayout>
  )
}

WebsitePagesLayout.propTypes = {
  websiteID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,

  children: PropTypes.node,
}
