import publicKeys from './publicKeys'
import domain from './domain'

const ENV = process.env.NODE_ENV
const DOMAIN = domain[ENV] || domain.production


export default {
  DOMAIN,
  stripe: {
    API: 'https://js.stripe.com/v2',
    PUBLIC_KEY: publicKeys.stripe[ENV] || publicKeys.stripe.production,
  },
  sso: {
    google: publicKeys.google[ENV] || publicKeys.google.staging,
  },
  supportMail: 'support@termly.io',
  defaultSite: 2,
  sitePerPrice: 4.95,
  langPerPrice: 1.95,
  freePlanId: 24,
  recaptchaKey: publicKeys.recaptcha[ENV] || publicKeys.recaptcha.staging,
  plans: {
    basic: 'basic',
    pro: 'pro',
    pro_plus: 'pro+',
  },
  maxPageview: 10000000,
  minPurchaseLicense: 0,
  maxPurchaseLicense: 99999,
  contactPhone: 'tel:+18552345020',
}
