import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Loading } from '@termly/react-components'

import MsgBox from 'components/MsgBox'

import Locale from './locale'
import Styles from './styles.scss'

import makeMessage from './lib/makeMessage'

const DEFAULT_ERROR_MESSAGE = Locale.errorMessage


export default function ReactQueryStateHandler(props) {
  const {
    errorMessage,
    isError,
    isLoading,
    isFetching,
    isRefetching,
  } = props

  if ( isError ) {
    const message = makeMessage(errorMessage) || DEFAULT_ERROR_MESSAGE

    const content = (
      <FormattedMessage
        { ...message }
      />
    )

    return (
      <MsgBox
        content={ content }
      />
    )
  }

  const showLoadingSpinner = ( isLoading || isFetching || isRefetching )

  if ( showLoadingSpinner ) {
    return (
      <Loading
        className={ Styles.loading }
      />
    )
  }

  return null
}

ReactQueryStateHandler.propTypes = {
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
    PropTypes.shape({
      defaultMessage: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ]),
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRefetching: PropTypes.bool,
}

