import createActionCreator from 'utils/redux'

export const SET_DATA = `${ __filename }/SET_DATA`

export const setData = createActionCreator(SET_DATA)

export const initState = { code: null, data: null }

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_DATA: {
      return {...state, ...payload}
    }
    default: {
      return state
    }
  }
}
