import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Config from 'config'

import Styles from './styles.scss'

import GenericUserIcon from './img/generic-user-icon.svg'


export default function AvatarIcon({ iconURL }) {
  const hasCustomIcon = Boolean(iconURL)

  const className = classnames(Styles.root, 't-avatarIcon', {
    [Styles.custom]: hasCustomIcon,
    [Styles.generic]: !hasCustomIcon,
  })

  const style = {
    '--icon-url': makeIconCssUrl(iconURL),
  }

  return (
    <div
      className={ className }
      style={ style }
    />
  )
}

AvatarIcon.propTypes = {
  iconURL: PropTypes.string,
}

function makeIconCssUrl(iconURL) {
  const url = ( iconURL ) ? `${ Config.DOMAIN + iconURL }` : GenericUserIcon

  return `url(${ url })`
}
