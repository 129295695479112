import {
  createContext,
  useContext,
} from 'react'


const UserPolicyContext = createContext()

export function useUserPolicy() {
  return useContext(UserPolicyContext)
}

export default UserPolicyContext
