import {
  createContext,
  useContext,
} from 'react'

const ZendeskAPIContext = createContext()


export default ZendeskAPIContext

export function useZendeskAPI() {
  return useContext(ZendeskAPIContext)
}
