import * as Actions from './actions'


export const initState = {
  error: null,
}

export default function authenticationReducer(state = initState, action) {
  switch (action.type) {
    case `${ Actions.signInError }`:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}
