import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'

import classnames from 'classnames'

import { useAuth } from 'pkg/authentication'

import Avatar from 'components/Avatar'

import AvatarFlyout from './AvatarFlyout'

import Styles from './styles.scss'


export default function AvatarDropdown() {
  const [isOpen, setIsOpen] = useState(false)

  const { user } = useAuth()

  const toggle = useCallback((newValue) => {
    setIsOpen((oldValue) => {
      return ( newValue === void 0 ) ? !oldValue : newValue
    })
  }, [setIsOpen])

  const onClick = useCallback((e) => {
    // We're not stopping propagation here because we need the
    // click event to bubble up to make sure we don't have
    // more than one dropdown open.
    toggle()
  }, [toggle])

  const onClickAway = useCallback((e) => {
    e.stopPropagation()

    toggle(false)
  }, [toggle])

  useEffect(() => {
    if ( isOpen ) {
      window.addEventListener('click', onClickAway)
    } else {
      window.removeEventListener('click', onClickAway)
    }

    return () => {
      window.removeEventListener('click', onClickAway)
    }
  }, [onClickAway, isOpen])

  const buttonClassName = classnames(Styles.button, 't-headerAvatar')

  return (
    <div
      className={ Styles.root }
    >
      <button
        className={ buttonClassName }
        onClick={ onClick }
        type="button"
      >
        <Avatar
          firstName={ user.first_name }
          lastName={ user.last_name }
        />
      </button>
      {
        renderFlyout({
          showMenu: isOpen,
          toggle,
        })
      }
    </div>
  )
}

function renderFlyout({ showMenu, toggle }) {
  if ( !showMenu ) {
    return null
  }

  return (
    <AvatarFlyout
      toggle={ toggle }
    />
  )
}
