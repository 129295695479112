import { combineReducers } from 'redux'

import policyMigrator from './policyMigrator'
import embedPreferenceCenterButton from './embedPreferenceCenterButton'


export default combineReducers({
  policyMigrator,
  embedPreferenceCenterButton,
})
