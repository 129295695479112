import React, {
  useCallback,
} from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Button } from '@termly/react-components'

import { useAuth } from 'pkg/authentication'

import Paths from 'constants/paths'

import Styles from './styles.scss'


export default function AccountInfo({ handleClick }) {
  const { user } = useAuth()
  const navigate = useNavigate()

  const handleButtonClick = useCallback(() => {
    handleClick()

    navigate(Paths.ACCOUNT)
  }, [navigate, handleClick])

  const rootClassName = classnames(Styles.root, 't-accountInfoButton')

  return (
    <Button
      preventDefault
      className={ rootClassName }
      color="primary"
      handleClick={ handleButtonClick }
      type="text"
    >
      {
        renderName({
          user,
        })
      }

      <span className={ Styles.email }>
        { user.email }
      </span>
    </Button>
  )
}

AccountInfo.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

function renderName({ user }) {
  const {
    first_name: firstName,
    last_name: lastName,
  } = user

  if ( !firstName || !lastName ) {
    return null
  }

  const fullName = `${ firstName } ${ lastName }`

  return (
    <span className={ Styles.name }>
      { fullName }
    </span>
  )
}
