export const FONT_FAMILIES = [
  'Arial Black',
  'Arial',
  'Bookman',
  'Comic Sans MS',
  'Courier New',
  'Courier',
  'Garamond',
  'Georgia',
  'Helvetica',
  'Impact',
  'Lato',
  'Montserrat',
  'Open Sans',
  'Palatino',
  'Roboto',
  'Times New Roman',
  'Times',
  'Trebuchet MS',
  'Verdana',
]

export const FONT_SIZES = [
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '19px',
  '20px',
  '24px',
  '26px',
  '28px',
  '30px',
  '36px',
  '48px',
  '60px',
]
