import {
  createContext,
  useContext,
} from 'react'


export const CurrentWebsiteIDContext = createContext()

export function useCurrentWebsiteID() {
  return useContext(CurrentWebsiteIDContext)
}
