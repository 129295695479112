import React from 'react'

import PropTypes from 'prop-types'

import TermlyLogo from '../TermlyLogo'

import Styles from './styles.scss'

const DEFAULT_LOGO_CLICK_LOCATION = '/dashboard'


export default function Hat({ leftContent, logoHref, middleContent, rightContent }) {
  return (
    <div className={ Styles.root }>
      <div className={ Styles.leftContent }>
        <TermlyLogo
          className={ Styles.logo }
          href={ logoHref }
        />
        { leftContent }
      </div>

      <div className={ Styles.middleContent }>
        { middleContent }
      </div>

      <div className={ Styles.rightContent }>
        { rightContent }
      </div>
    </div>
  )
}

Hat.propTypes = {
  leftContent: PropTypes.node,
  logoHref: PropTypes.string,
  middleContent: PropTypes.node,
  rightContent: PropTypes.node,
}

Hat.defaultTypes = {
  logoHref: DEFAULT_LOGO_CLICK_LOCATION,
}
