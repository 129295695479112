// TODO: I'm not a fan of what I've done here. We should really DRY
// this up with ./PageNotFoundPage.jsx and stop reaching into the package
// innards to get at this component (see routes.js).  I'm not going to
// do that refactoring now, though, because it's out of scope for what
// I'm doing now (killing circular dependencies).

import React from 'react'
import 'iframe-resizer/js/iframeResizer.contentWindow'

import MissingContent from 'components/MissingContent'
import LogoHeader from 'components/Header/LogoHeader'

import Styles from './styles.scss'


export default function PolicyNotFound() {
  return (
    <div className={ Styles.root }>
      <LogoHeader />

      <div className={ Styles.content }>
        <MissingContent
          caption="noPolicyCaption"
          logo="doc-no-exist"
          title="noPolicyTitle"
        />
      </div>
      { /* This is used by iframe-resizer. See Iframe.jsx in policy-viewer. */ }
      <div data-iframe-height />
    </div>
  )
}
