// TODO: Some paths are strings, others are functions. Make them all
// functions for consistency's sake. (Hint: use reverse() from the
// `named-urls` NPM package to facilitate this.)  While doing that,
// stop with the ALL_CAPS.

import QueryString from 'query-string'
import {
  PolicyTypes,
  URLBuilder,
} from '@termly_web/common'

const ROOT = '/'
const AUDIT = '/audit'
const DASHBOARD = '/dashboard'

const CMP_ONBOARDING = `${ DASHBOARD }/cmp-onboarding`
const DELETE_ACCOUNT = `${ DASHBOARD }/delete-account`
const LEGACY_RECEIPT = (id = ':receiptID') => `${ DASHBOARD }/receipts/${ id }`
const RECEIPT = (id = ':receiptID') => `${ DASHBOARD }/settings/receipts/${ id }`
const USER_MANAGEMENT = `${ DASHBOARD }/user-management`

// `website` is lowercased because it is not exported
const website = `${ DASHBOARD }/website`
const WEBSITE_DASHBOARD = (id = ':id') => `${ website }/${ id }`
const API_KEY = (id = ':id') => `${ website }/${ id }/api-key`
const BANNER_SETTINGS = (id = ':id') => `${ website }/${ id }/banner-settings`
const BUSINESS_INFO = (id = ':id') => `${ website }/${ id }/business-info`
const CCPA_SETTINGS = (id = ':id') => `${ website }/${ id }/ccpa-settings`
const CONSENT_LOGS = (id = ':id') => `${ website }/${ id }/consent-logs`
const COOKIE_POLICY = (id = ':id') => POLICY_URL({ id, policyType: PolicyTypes.COOKIE})
const WIZARD_SANDBOX = (id = ':id') => `${ website }/${ id }/wizard-sandbox`

function POLICY_PRIORITY_URL({ websiteID, policyType }) {
  if ( PolicyTypes.hasCustomizedPolicyEntryPoint(policyType) ) {
    return `${ COOKIE_REPORT(websiteID) }?category=UNCLASSIFIED`
  }

  return POLICY_URL({ id: websiteID, policyType })
}

const COOKIE_REPORT = (id = ':id') => `${ website }/${ id }/cookie-report`
const DOMAIN = (id = ':id') => `${ website }/${ id }/domain`
const DSAR_FORM = (id = ':id') => `${ website }/${ id }/dsar-form`
const EMBED_CONSENT_BANNER = (id = ':id') => `${ website }/${ id }/embed-consent-banner`
const EMBED_PREFERENCE_CENTER_BUTTON = (id = ':id') => `${ website }/${ id }/embed-preference-center-button`
const NEW_WEBSITE = `${ website }/new`

const PRIVACY_POLICY = (id = ':id') => POLICY_URL({ id, policyType: PolicyTypes.PRIVACY})
const SCAN_SETTINGS = (id = ':id') => `${ website }/${ id }/scan-settings`
const WEBSITE_INFO = (id = ':id') => `${ website }/${ id }/website-info`

function POLICY_URL({ id, policyType } = {}) {
  const policySlug = PolicyTypes.getSlugForPolicyType(policyType)

  return URLBuilder.policyDetailsURL({
    id,
    policySlug,
  })
}

const NOTIFICATION = (id = ':id', step = 0) => {
  const queryString = ( step ) ? `?step = ${ step }` : ''

  return `${ website }/${ id }/notification${ queryString }`
}

const REPORT_ADD_COOKIE = (id = ':id', category = ':category', reportId = ':reportId') => {
  const cookieReport = COOKIE_REPORT(id)

  return `${ cookieReport }/${ reportId }/add-cookie/${ category }`
}
const REPORT_EDIT_COOKIE = (id = ':id', cookieId = ':cookieId', category = null, reportId = ':reportId') => {
  const cookieReport = COOKIE_REPORT(id)
  const queryString = ( category ) ? `?category=${ category }` : ''

  return `${ cookieReport }/${ reportId }/edit-cookie/${ cookieId }${ queryString }`
}

const USER = '/user'
const CHECKOUT = (params = '') => `${ USER }/checkout${ params ? params : '' }`
const CREATE_ACCOUNT = `${ USER }/create-account`
const FORGOT_PWD = `${ USER }/forgot-password`
const LOGIN = `${ USER }/login`
const LOGIN_FAILED = '/login-failed'
const MIGRATE_ACCOUNT = `${ USER }/migrate-account`
const PARTNER_ONBOARD = (websiteID = ':websiteID') => `/partner-onboard/${ websiteID }`
const POLICY_NOT_FOUND = `${ USER }/policy-not-found`
const PRODUCT = `${ USER }/products`
const PRODUCT_WITH_COOKIE = `${ USER }/products?cookiePricing=true`
const RESET_PWD = `${ USER }/reset-password`
const SESSION_EXPIRED = '/session-expired'

const SETTINGS = `${ DASHBOARD }/settings`
const ACCOUNT = `${ SETTINGS }/account`
const BILLING = `${ SETTINGS }/billing`
const BILLING_HISTORY = `${ SETTINGS }/billing-history`
const CHECKOUT_PREVIEW = `${ SETTINGS }/checkout-preview`
const SUPPORT = `${ DASHBOARD }/support`
const PLAN_INFO = URLBuilder.planInfoURL()
const WEBSITE_LIMIT =  `${ SETTINGS }/website-limit`

const NOTIFY = '/notify'
const NOTIFY_ACTIONS_SUCCESS = (id = ':id', queryArgs) => {
  const baseURL = `${ NOTIFY }/${ id }/success`

  if ( !queryArgs ) {
    return baseURL
  }

  const queryString = QueryString.stringify(queryArgs)

  return `${ baseURL }?${ queryString }`
}
const PUBLIC_DSAR = (documentUUID = ':documentUUID') => `${ NOTIFY }/${ documentUUID }`

const WP_PATH = 'https://termly.io/'

export default {
  ACCOUNT,
  API_KEY,
  AUDIT,
  BANNER_SETTINGS,
  BILLING,
  BILLING_HISTORY,
  BUSINESS_INFO,
  CCPA_SETTINGS,
  CHECKOUT,
  CHECKOUT_PREVIEW,
  CMP_ONBOARDING,
  CONSENT_LOGS,
  COOKIE_POLICY,
  COOKIE_REPORT,
  CREATE_ACCOUNT,
  DASHBOARD,
  DELETE_ACCOUNT,
  DOMAIN,
  DSAR_FORM,
  EMBED_CONSENT_BANNER,
  EMBED_PREFERENCE_CENTER_BUTTON,
  FORGOT_PWD,
  LEGACY_RECEIPT,
  LOGIN,
  LOGIN_FAILED,
  MIGRATE_ACCOUNT,
  NEW_WEBSITE,
  NOTIFICATION,
  NOTIFY,
  NOTIFY_ACTIONS_SUCCESS,
  PARTNER_ONBOARD,
  PLAN_INFO,
  POLICY_NOT_FOUND,
  POLICY_PRIORITY_URL,
  POLICY_URL,
  PRIVACY_POLICY,
  PRODUCT,
  PRODUCT_WITH_COOKIE,
  PUBLIC_DSAR,
  RECEIPT,
  REPORT_ADD_COOKIE,
  REPORT_EDIT_COOKIE,
  RESET_PWD,
  ROOT,
  SCAN_SETTINGS,
  SESSION_EXPIRED,
  SETTINGS,
  SUPPORT,
  USER,
  USER_MANAGEMENT,
  WEBSITE_DASHBOARD,
  WEBSITE_INFO,
  WEBSITE_LIMIT,
  WIZARD_SANDBOX,
  WP_PATH,
}
