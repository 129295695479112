import { put, call, select, takeLatest } from 'redux-saga/effects'

import createActionCreator from 'utils/redux'

import {
  applyDocumentTheme,
  upgradeDraftPolicyToLatest,
} from 'constants/api'

import api from 'utils/api'

import { getDocument } from 'selectors/responses/website'

import {
  updateLoading,
  updateApplyLoading,
} from 'reducers/panels/policy'


export const APPLY_DEFAULT_THEME = `${ __filename }/APPLY_DEFAULT_THEME`
const MIGRATE_POLICY = `${ __filename }/MIGRIATE_POLICY`

export const applyDefaultTheme = createActionCreator(APPLY_DEFAULT_THEME)
export const upgradePolicyToLatest = createActionCreator(MIGRATE_POLICY)

export function* sagaApplyDefaultTheme() {
  try {
    yield put(updateApplyLoading(true))
    const document = yield select(getDocument)
    yield call(api.putData, applyDocumentTheme(document.id), {
      theme_id: null,
    })
  } catch (e) {
    console.error(e)
  } finally {
    yield put(updateApplyLoading(false))
  }
}

export const watchers = {
  *applyDefaultTheme() {
    yield takeLatest(APPLY_DEFAULT_THEME, sagaApplyDefaultTheme)
  },
  *upgradePolicyToLatest() {
    yield takeLatest(MIGRATE_POLICY, sagaMigratePolicyToLatest)
  },
}

function* sagaMigratePolicyToLatest({ payload }) {
  yield put(updateLoading(true))

  const {
    documentID,
    editDocumentURL,
  } = payload

  yield call(api.putData, upgradeDraftPolicyToLatest(documentID))

  yield call([location, 'assign'], editDocumentURL)
}

export const _private = {
  sagaMigratePolicyToLatest,
  MIGRATE_POLICY,
}
