import React from 'react'
import PropTypes from 'prop-types'

import IntercomAPIProvider from './IntercomAPIProvider'
import UserTrackerInnerProvider from './UserTrackerInnerProvider'
import UserflowAPIProvider from './UserflowAPIProvider'
import ZendeskAPIProvider from './ZendeskAPIProvider'


export default function UserTrackerProvider({ children }) {
  return (
    <IntercomAPIProvider>
      <UserflowAPIProvider>
        <ZendeskAPIProvider>
          <UserTrackerInnerProvider>
            { children }
          </UserTrackerInnerProvider>
        </ZendeskAPIProvider>
      </UserflowAPIProvider>
    </IntercomAPIProvider>
  )
}

UserTrackerProvider.propTypes = {
  children: PropTypes.node,
}
