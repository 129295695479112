export const REQUEST_AS_PERSONAL = 'personal'
export const REQUEST_AS_AGENT = 'agent'

export const CCPA = 'ccpa'
export const CPA = 'cpa'
export const CTDPA = 'ctdpa'
export const GDPR = 'gdpr'
export const OTHER = 'other'
export const UCPA = 'ucpa'
export const VCDPA = 'vcdpa'

// Order determines how we display these things.
// DO NOT PUT `OTHER` IN HERE
export const SUPPORTED_LAWS = [
  GDPR,
  CCPA,
  CPA,
  CTDPA,
  UCPA,
  VCDPA,
]

export const DEFAULT_LAW_ACTIONS = {
  [CCPA]: 'request_to_know',
  [CPA]: 'request_to_know',
  [CTDPA]: 'request_to_know',
  [GDPR]: 'confirm_personal_info',
  [UCPA]: 'request_to_know',
  [VCDPA]: 'request_to_know',
}
