import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import PropTypes from 'prop-types'

import { Navigate } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'

import Paths from 'constants/paths'

import { makeContext } from 'utils/contextFactory'

import noop from 'utils/noop'

import pathMatches from './lib/pathMatches'
import useUserInfoQuery from './hooks/useUserInfoQuery'

const [AuthContext, useAuth] = makeContext('AuthContext')

const IDLE_TIMEOUT = 5 * 60 * 1_000


/**
 * @function useAuth()
 *
 * @returns {Object} with the shape { user, excluded }
 */
export { useAuth }

/**
 * Provides the context for the `useAuth()` hook.
 */
export default function AuthProvider({ children, handleAuthComplete = noop }) {
  const [isTimedOut, setTimedOut] = useState()

  const {
    excluded,
    isInitialLoading,
    refetch,
    user,
  } = useUserInfoQuery({
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    // useUserInfoQuery() will always return an object for `user`...but it'll only
    // have an `id` if it's a legitimate record.
    if ( !user.id ) {
      return
    }

    handleAuthComplete({
      user,
    })
  }, [handleAuthComplete, user])

  useIdleTimer({
    disabled: !user.integration_partner,
    onIdle() {
      setTimedOut(true)
    },
    timeout: IDLE_TIMEOUT,
  })

  const isEditor = ( user.is_collaborator && user.account_role === 'collaborating:editor' )

  /**
   * @function
   * @param {String} featureName
   * @returns {Boolean}
   */
  const isExcludedFeature = useCallback((featureName) => {
    return new Set(excluded.features).has(featureName)
  }, [excluded])

  /**
   * @function
   * @param {String} locationPathname
   * @returns {Boolean}
  */
  const isExcludedPathname = useCallback((locationPathname) => {
    return pathMatches(locationPathname, excluded.pages)
  }, [excluded])

  // The useMemo() may be a bit overkill, but I really want to avoid
  // causing an unnecessary full-on re-render of the page
  const value = useMemo(() => ({
    isEditor,
    isExcludedFeature,
    isExcludedPathname,
    refetch,
    user,
  }), [isEditor, isExcludedFeature, isExcludedPathname, refetch, user])

  if ( isInitialLoading ) {
    return null
  }

  if ( isTimedOut ) {
    return (
      <Navigate
        to={ Paths.SESSION_EXPIRED }
      />
    )
  }

  return (
    <AuthContext.Provider value={ value }>
      { children }
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  handleAuthComplete: PropTypes.func,
}

// Only for testing purposes
export const _private = { AuthContext }

