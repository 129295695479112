import React, {
  useCallback,
  useMemo,
} from 'react'

import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import Paths from 'constants/paths'

import ReactQueryStateHandler from 'components/ReactQueryStateHandler'
import WebsiteSelect from 'components/WebsiteSelect'
import { useCurrentWebsiteID } from 'components/CurrentWebsiteIDProvider'

import useWebsite from 'hooks/useWebsite'

import HeaderWebsiteSelectFooter from './HeaderWebsiteSelectFooter'
import HeaderWebsiteMenuList from './HeaderWebsiteMenuList'

import Locale from './locale'
import Styles from './styles.scss'

const COMPONENTS = {
  MenuFooter: HeaderWebsiteSelectFooter,
  MenuList: HeaderWebsiteMenuList,
}

const STYLES = {
  menuList: (provided, { selectProps }) => {
    const { menuProps } = selectProps

    if ( menuProps?.showAllWebsites ) {
      return {
        ...provided,
        // Yeah, I know...magic value. I'm not really sure how else to
        // do what needs to be done here, which is to show the "show
        // all" button section when necessary.
        maxHeight: '345px',
      }
    }

    return provided
  },
}


export default function HeaderWebsiteSelect() {
  const intl = useIntl()
  const navigate = useNavigate()

  const websiteID = useCurrentWebsiteID()

  // We're using isInitialLoading here because if there is no `websiteID`,
  // useWebsite() will report `isLoading: true` indefinitely because it's
  // disabled in that case. Thank you, @tanstack.
  const {
    isError,
    isInitialLoading,
    website,
  } = useWebsite({
    websiteID,
  })

  const handleChange = useCallback(({ value }) => {
    navigate(Paths.WEBSITE_DASHBOARD(value.value))
  }, [navigate])

  const placeholder = useMemo(() => {
    return intl.formatMessage(Locale.selectYourWebsite)
  }, [intl])

  const defaultValue = useMemo(() => {
    if ( !website ) {
      return
    }

    return {
      label: website.url,
      value: website.uuid,
      website,
    }
  }, [website])

  if ( isInitialLoading || isError ) {
    return (
      <ReactQueryStateHandler
        isError={ isError }
        isLoading={ isInitialLoading }
      />
    )
  }

  return (
    <WebsiteSelect
      closeMenuOnSelect
      defaultOptions
      className={ Styles.root }
      components={ COMPONENTS }
      defaultValue={ defaultValue }
      handleChange={ handleChange }
      name="website-select"
      orderBy="-updated_at"
      placeholder={ placeholder }
      styles={ STYLES }
    />
  )
}

HeaderWebsiteSelect.propTypes = {
  selectProps: PropTypes.shape({
    onMenuClose: PropTypes.func,
  }),
}
