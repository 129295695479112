import React from 'react'
import ReactSelectAsync from 'react-select/async'

import Select from './Select'

// This component is intended to be a _very_ thin wrapper around
// ReactSelect so that we can have a consistent look and feel
// across our UIs.
//
// Please do not add anything to this file unless it's a
// feature or style that needs to be present across all applications.
// If it's only used in one client, consider creating an application-
// specific wrapper instead.
//
export default function AsyncSelect(props) {
  return (
    <Select
      ReactSelectComponent={ ReactSelectAsync }
      { ...props }
    />
  )
}

AsyncSelect.propTypes = Select.propTypes
