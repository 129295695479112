import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import withFormContext from 'hoc/withFormContext'

import mapClassNameToArray from 'utils/mapClassName'

// TODO: Do not burglarize other components
import styles from '../Input/input.scss'

// TODO: this does not seem like a good idea at all
let timer


class Dropdown extends Component {
  static propTypes = {
    className:        PropTypes.string,
    options:          PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
    containerClass:   PropTypes.string,
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
    arrow: PropTypes.string,
    label: PropTypes.shape(FormattedMessage.propTypes),
    hidden: PropTypes.bool,
    _reduxForm: PropTypes.shape({
      dispatch: PropTypes.func,
      change: PropTypes.func,
    }),
  }

  static defaultProps = {
    className: '',
    options: [],
    containerClass: 'input-container',
    input: {},
    arrow: 'label-arrow',
    hidden: false,
  }

  state = { showList: false }

  handleToggle = () => {
    this.setState((state) => ({ showList: !state.showList }))
  }

  handleBlur = (e) => {
    if (this.state.showList && !(this.input.contains(e.target) || this.list.contains(e.target))) {
      timer = setTimeout(() => {
        this.setState(() => ({ showList: false }))
      }, 200)
    }
  }

  handleClick = (font) => (e) => {
    const { dispatch, change } = this.props._reduxForm
    this.setState(() => ({ showList: false }))
    dispatch(change(this.props.input.name, font))
  }

  handleStyle = (name, value) => name.toLowerCase().includes('family') ? { fontFamily: value } : undefined

  getInputRef = (ref) => {
    this.input = ref
  }

  getListRef = (ref) => {
    this.list = ref
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleBlur)
  }

  componentWillUnmount() {
    clearTimeout(timer)
    document.removeEventListener('mousedown', this.handleBlur)
  }

  render() {
    const {
      className,
      containerClass,
      input,
      input: { name, value },
      options,
      arrow,
      label,
      hidden,
    } = this.props

    const inputStyle = cx(
      styles['form-control'],
      ...mapClassNameToArray(className, styles),
    )

    return (
      <div
        className={ styles[containerClass] }
        hidden={ hidden }
      >
        { label && <label className={ styles['color-label'] }><FormattedMessage { ...label } /></label> }
        <label className={ styles[arrow] }>
          <input
            { ...input }
            className={ inputStyle }
            onClick={ this.handleToggle }
            ref={ this.getInputRef }
            style={ this.handleStyle(name, value) }
            type="button"
            value={ value }
          />
        </label>
        {
          this.state.showList && (
            <ul
              className={ styles.dropdown }
              ref={ this.getListRef }
            >
              {
                options.map((option, i) => (
                  <li
                    className={ cx({ [styles['selected']]: option === value }) }
                    key={ i }
                    onClick={ this.handleClick(option) }
                    style={ this.handleStyle(name, option) }
                  >{ option }
                  </li>
                ))
              }
            </ul>
          )
        }
      </div>
    )
  }
}

export default withFormContext(Dropdown)

export const _private = {
  Dropdown,
}
