import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'

import HighlightWords from 'react-highlight-words'

import Styles from './styles.scss'


export default function Option(props) {
  const {
    data,
    innerProps,
    innerRef,
    isFocused,
    selectProps,
  } = props

  const {
    label,
    website: {
      name,
    },
  } = data

  const { inputValue } = selectProps

  const className = classnames(Styles.root, 't-site', {
    [Styles.focused]: isFocused,
  })

  const searchWords = useMemo(() => {
    return [inputValue]
  }, [inputValue])

  return (
    <div
      { ...innerProps }
      className={ className }
      ref={ innerRef }
    >
      <div className={ Styles.name }>
        <HighlightWords
          highlightClassName={ Styles.highlight }
          searchWords={ searchWords }
          textToHighlight={ name }
        />
      </div>
      <div>
        <HighlightWords
          highlightClassName={ Styles.highlight }
          searchWords={ searchWords }
          textToHighlight={ label }
        />
      </div>
    </div>
  )
}

Option.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    website: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  innerProps: PropTypes.shape({}),
  innerRef: PropTypes.shape({}),
  isFocused: PropTypes.bool,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
  }),
}
