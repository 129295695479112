import { useLocation } from 'react-router-dom'

import { useAuth } from 'pkg/authentication'

const EXCLUDED_PAGES_CONFIG = {
  duda: [
    'website-info',
  ],
}


export default function useIsPageAllowed(url) {
  const { user } = useAuth()
  const { pathname } = useLocation()

  const page = url || pathname

  if ( !user?.integration_partner || !page ) {
    return true
  }

  // We guard against two scenarios here:
  // 1) Whether or not a link is rendered
  // i.e. in the Dashboard Sidebar, Avatar Dropdown, etc.
  // 2) Whether or not a user can access a page directly via URL
  return !EXCLUDED_PAGES_CONFIG[user.integration_partner]?.some((excludedPage) => page.includes(excludedPage))
}
