import React from 'react'
import { FormattedMessage } from 'react-intl'

import { makeExternalLinkFormatter } from 'utils/react-intl-formatters'

import Locale from './locale'
import Styles from './styles.scss'
import AlertIcon from './img/alert-icon.svg'

const VALUES = {
  link: makeExternalLinkFormatter('https://termly.io/contact-us'),
}


export default function LazyLoadFailed() {
  return (
    <div className={ Styles.root }>
      <div className={ Styles.content }>
        <img
          alt="Oh noes!"
          className={ Styles.icon }
          src={ AlertIcon }
        />

        <p className={ Styles.title }>
          <FormattedMessage
            { ...Locale.doh }
          />
        </p>
        <p className={ Styles.instructions }>
          <FormattedMessage
            { ...Locale.otraVez }
            values={ VALUES }
          />
        </p>
      </div>
    </div>
  )
}
