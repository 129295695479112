import POLICIES_ICON from 'assets/images/policies-icon.svg'

import { makePoliciesLinks } from './policiesLinksConfig'

import Locale from './locale'


export default function makePoliciesAccordion({ currentWebsiteID }) {
  return {
    icon: POLICIES_ICON,
    id: 'policies',
    name: Locale.policies,
    sections: [
      {
        links: makePoliciesLinks({
          currentWebsiteID,
        }),
      },
    ],
  }
}
