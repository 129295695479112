import QueryString from 'query-string'

import Client from 'utils/client'

const GET_URI = 'v1/websites/management-summary'


export default function makeMutator({ orderBy, pageSize }) {
  return async (filter) => {
    const url = QueryString.stringifyUrl({
      query: {
        order_by: orderBy,
        page_size: pageSize,
        q: filter,
      },
      url: GET_URI,
    })

    const {
      data: {
        pagination,
        websites,
      },
    } = await Client.get(url)

    return {
      pagination,
      websites,
    }
  }
}
