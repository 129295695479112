import { AuthenticationActions } from 'pkg/authentication'

import Paths from 'constants/paths'


/**
 * @param {Object} action - a Redux action
 * @param {Object} action.payload
 * @param {String} action.type
 */
export default function getURLForAction({ payload, type }) {
  switch (type) {
    case `${ AuthenticationActions.signUpSuccess }`:
    case `${ AuthenticationActions.signInSuccess }`: {
      const {
        redirect,
        user,
      } = payload

      if ( user.must_change_password ) {
        return Paths.ACCOUNT
      }

      return redirect || Paths.DASHBOARD
    }
  }
}
