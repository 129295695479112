import { defineMessages } from 'react-intl'


export default defineMessages({
  title: {
    id: 'gone-fishin-page.title',
    defaultMessage: "We'll be back",
  },

  content: {
    id: 'gone-fishin-page.content',
    defaultMessage: "We're making improvements for you and will be back online soon.",
  },

  errorTitle: {
    id: 'gone-fishin-page.error-title',
    defaultMessage: "Something's not quite right",
  },

  errorContent: {
    id: 'gone-fishin-page.error-content',
    defaultMessage: "We're experiencing an unknown error at the moment. Please check back again in a few minutes.",
  },
})
