import React, {
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

import PropTypes from 'prop-types'
import { Router as ReactRouter } from 'react-router-dom'

import GtmAPI from '../../utils/gtm-api'


// This was copied from some article I stumbled across, which I cannot
// find at the moment. It seems, unfortunately, to be necessary in order
// to share a history object with our sagas (react-router-dom's router
// components no longer support dependency injection of the `history`
// object.
//
export default function CustomRouter({ children, history, ...props }) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useEffect(() => {
    // Necessary to make Google Optimize happy
    // (see https://support.google.com/optimize/answer/7008840)
    GtmAPI.push({
      event: 'optimize.activate',
    })
  }, [history.location])

  useLayoutEffect(() => {
    history.listen(setState)
  }, [history])

  return (
    <ReactRouter
      { ...props }
      location={ state.location }
      navigationType={ state.action }
      navigator={ history }
    >
      { children }
    </ReactRouter>
  )
}

CustomRouter.propTypes = {
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({}).isRequired,
  }),

  children: PropTypes.node,
}
