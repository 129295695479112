export default {
  name: 'customerRequest',
  fields: {
    website: 'website',
    name: 'name',
    email: 'email',
    requestAs: 'request_as',
    complianceType: 'compliance_type',
    action: 'action',
    content: 'content',
    declareInformation: 'declare_information',
    understandDeletion: 'understand_deletion',
    validateEmail: 'validate_email',
  },
}
