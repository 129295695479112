import _ from 'lodash'
import { createSelector } from 'reselect'

import businessInfoFormIds from 'constants/formIds/businessInfo'
import IntlConnectedInput from 'components/Input'
import IntlConnectedSelect from 'components/Select'

import {
  countriesCodeArray,
  countryFlagToFullName,
  countryFullNametoFlag,
  statesMap,
} from 'utils/country'

import {
  decodePhoneNumber,
  encodePhoneNumber,
} from 'utils/phoneNumber'

import {
  getCompanyAddress,
  getCompanyCity,
  getCompanyCountry,
  getCompanyEmail,
  getCompanyFax,
  getCompanyLegalName,
  getCompanyPhone,
  getCompanyState,
  getCompanyZip,
} from 'selectors/responses/website'

import { getValues } from './businessInfo'


export const getStateFieldOptions = createSelector(
  getValues,
  (values) =>
    statesMap[
      countryFlagToFullName(_.get(values, businessInfoFormIds.fields.country))
    ]
)

export const getStateFieldComponent = createSelector(
  getStateFieldOptions,
  (options) => (options ? IntlConnectedSelect : IntlConnectedInput)
)

export const getStateFieldClassName = createSelector(
  getStateFieldOptions,
  (options) => (options ? 'white-background-with-down-arrow' : 'background-white')
)

// TODO: extract this...it doesn't need to be in here, but it's a bit involved
export const getStateField = createSelector(
  getStateFieldOptions,
  getStateFieldComponent,
  getStateFieldClassName,
  (options, component, className) => ({
    id: 'state',
    name: businessInfoFormIds.fields.state,
    containerClass: 'business-info-input-container',
    checkMark: true,
    className,
    component,
    options,
  })
)

export const getBusinessInfoAsFormData = createSelector(
  getCompanyAddress,
  getCompanyCity,
  getCompanyCountry,
  getCompanyEmail,
  getCompanyLegalName,
  getCompanyFax,
  getCompanyPhone,
  getCompanyState,
  getCompanyZip,
  (
    companyAddress,
    companyCity,
    companyCountry,
    companyEmail,
    companyLegalName,
    companyFax,
    companyPhone,
    companyState,
    companyZip
  ) => {
    const {
      countryAbbr: companyFaxCountry,
      number: companyFaxNumber,
    } = decodePhoneNumber(companyFax)
    const {
      countryAbbr: companyPhoneCountry,
      number: companyPhoneNumber,
    } = decodePhoneNumber(companyPhone)
    const initialData = {}
    _.set(initialData, businessInfoFormIds.fields.address, companyAddress)
    _.set(initialData, businessInfoFormIds.fields.city, companyCity)
    _.set(
      initialData,
      businessInfoFormIds.fields.country,
      countryFullNametoFlag(companyCountry) || ''
    )
    _.set(initialData, businessInfoFormIds.fields.email, companyEmail)
    _.set(
      initialData,
      businessInfoFormIds.fields.companyName,
      companyLegalName
    )
    _.set(
      initialData,
      businessInfoFormIds.fields.fax.country,
      companyFaxCountry || countriesCodeArray[0]
    )
    _.set(initialData, businessInfoFormIds.fields.fax.number, companyFaxNumber)
    _.set(
      initialData,
      businessInfoFormIds.fields.phone.country,
      companyPhoneCountry || countriesCodeArray[0]
    )
    _.set(
      initialData,
      businessInfoFormIds.fields.phone.number,
      companyPhoneNumber
    )
    _.set(initialData, businessInfoFormIds.fields.state, companyState)
    _.set(initialData, businessInfoFormIds.fields.zip, companyZip)
    return initialData
  }
)

// Used in sagas/forms/dashboardBusinessInfo.js
export const getBusinessInfoAsRequestBody = createSelector(
  getValues,
  (values) => {
    const companyAddress = _.get(values, businessInfoFormIds.fields.address)
    const companyCity = _.get(values, businessInfoFormIds.fields.city)
    const companyCountry = _.get(values, businessInfoFormIds.fields.country)
    const companyEmail = _.get(values, businessInfoFormIds.fields.email)
    const companyName = _.get(values, businessInfoFormIds.fields.companyName)
    const companyFaxCountry = _.get(
      values,
      businessInfoFormIds.fields.fax.country
    )
    const companyFaxNumber = _.get(
      values,
      businessInfoFormIds.fields.fax.number
    )
    const companyPhoneCountry = _.get(
      values,
      businessInfoFormIds.fields.phone.country
    )
    const companyPhoneNumber = _.get(
      values,
      businessInfoFormIds.fields.phone.number
    )
    const companyState = _.get(values, businessInfoFormIds.fields.state)
    const companyZip = _.get(values, businessInfoFormIds.fields.zip)
    return {
      company_city: companyCity,
      company_legal_name: companyName,
      company_email: companyEmail,
      company_phone: encodePhoneNumber(companyPhoneCountry, companyPhoneNumber),
      company_fax: encodePhoneNumber(companyFaxCountry, companyFaxNumber),
      company_country: countryFlagToFullName(companyCountry),
      company_state: companyState,
      company_zip: companyZip,
      company_address: companyAddress,
    }
  }
)
