import { createSelector } from 'reselect'

import { PolicyTypes } from '@termly_web/common'

import {
  getPathname,
  getWebsiteIdFromParam,
} from 'utils/selectors'


import Paths from 'constants/paths'

export const selector = (state) => state.responses.website

export const getIsLoading = createSelector(selector, ({ isLoading }) => isLoading)

export const getId = createSelector(
  selector,
  ({ id }) => id,
)

export const getCurrentReportId = createSelector(
  selector,
  ({ current_report_id }) => current_report_id
)

export const getName = createSelector(
  selector,
  ({ name }) => name,
)

export const getUrl = createSelector(
  selector,
  ({ url }) => url || '',
)

export const getUUID = createSelector(
  selector,
  ({ uuid }) => uuid,
)

export const getDocuments = createSelector(
  selector,
  ({ documents }) => documents,
)

export const getPublishedDocuments = createSelector(
  getDocuments,
  (documents = []) => {
    return documents.filter((document) => {
      return document && document.published_document_version_id
    })
  }
)

export const getPrivacyPolicyDocument = createSelector(
  getDocuments,
  (documents = []) => documents.find(({ name }) => name.includes('Privacy')),
)

export const getPrivacyPolicyDocumentUUID = createSelector(
  getPrivacyPolicyDocument,
  ({ uuid } = {}) => uuid
)

export const getTermsOfServiceDocument = createSelector(
  getDocuments,
  (documents = []) => documents.find(({ name }) => name.includes('Terms')),
)

export const getRefundPolicyDocument = createSelector(
  getDocuments,
  (documents = []) => documents.find(({ name }) => name.includes('Return')),
)

export const getDisclaimerDocument = createSelector(
  getDocuments,
  (documents = []) => documents.find(({ name }) => name.includes('Disclaimer')),
)

export const getCookiePolicyDocument = createSelector(
  getDocuments,
  (documents = []) => documents.find(({ name }) => name.includes('Cookie')),
)

export const getHasMultipleNonCookiePolicies = createSelector(
  selector,
  ({ has_multiple_non_cookie_policies }) => has_multiple_non_cookie_policies
)

export const getCookiePolicyId = createSelector(
  getCookiePolicyDocument,
  ({ id } = {}) => id,
)

export const getCookiePolicyUuid = createSelector(
  getCookiePolicyDocument,
  ({ uuid } = {}) => uuid,
)

export const getCompanyAddress = createSelector(
  selector,
  ({ company_address }) => company_address,
)

export const getCompanyCity = createSelector(
  selector,
  ({ company_city }) => company_city,
)

export const getCompanyCountry = createSelector(
  selector,
  ({ company_country }) => company_country,
)

export const getCompanyEmail = createSelector(
  selector,
  ({ company_email }) => company_email,
)

export const getCompanyFax = createSelector(
  selector,
  ({ company_fax }) => company_fax,
)

export const getCompanyLegalName = createSelector(
  selector,
  ({ company_legal_name }) => company_legal_name,
)

export const getCompanyPhone = createSelector(
  selector,
  ({ company_phone }) => company_phone,
)

export const getCompanyState = createSelector(
  selector,
  ({ company_state }) => company_state,
)

export const getCompanyZip = createSelector(
  selector,
  ({ company_zip }) => company_zip,
)

export const getOldCookiePolicy = createSelector(
  selector,
  ({ old_cookie_policy }) => old_cookie_policy,
)

export const getPolicyType = createSelector(
  getWebsiteIdFromParam,
  getPathname,
  (websiteId, pathname) => {
    const policyType = findPolicyTypeByPath({ websiteId, pathname })

    return policyType || PolicyTypes.defaultPolicy
  }
)

export const groupDocumentsByType = createSelector(
  getDocuments,
  (documents = []) => {
    return keyBy(documents, 'template_type')
  }
)

export const getPublishedPolicyTypes = createSelector(
  getPublishedDocuments,
  (documents = []) => {
    return documents.map((document) => {
      return document && document.template_type
    })
  }
)

export const getAutoScanSettings = createSelector(
  selector,
  (website) => ({
    nextScanDate: website.next_scan_date,
    scanPeriod: website.scan_period,
  })
)

export const getDocument = createSelector(
  getPolicyType,
  groupDocumentsByType,
  (type, groupedDocuments) => groupedDocuments[type]
)

export const getDocumentID = createSelector(
  getDocument,
  ({ id } = {}) => id
)

export const getDocumentName = createSelector(
  getDocument,
  ({ name } = {}) => name
)

export const getScanStatus = createSelector(
  selector,
  ({ scan_status }) => scan_status
)

function findPolicyTypeByPath({ websiteId, pathname }) {
  return PolicyTypes.allTypes.find((policyType) => {
    return Paths.POLICY_URL({ id: websiteId, policyType }) === pathname
  })
}

function keyBy(array, key) {
  return (array).reduce((accumulator, element) =>  ({
    ...accumulator,
    [element[key]]: element,
  }) , {})
}
