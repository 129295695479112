/*eslint-disable */

import publicKeys from 'config/publicKeys'

class GooglePlaceAutocomplete {
  googleAuth = null;

  constructor (onLoad) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${publicKeys.googleapis.map}&libraries=places&language=en`;
    script.onload = onLoad;
    document.body.appendChild(script);
  }

  attachToElement (id) {
    return new google.maps.places.Autocomplete(document.getElementById(id), { types: ['geocode'] });
  }
}

export default GooglePlaceAutocomplete;
