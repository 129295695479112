import createActionCreator from 'utils/redux'

export const SET_DATA = `${ __filename }/SET_DATA`

export const setData = createActionCreator(SET_DATA)

export const initState = {}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_DATA:
      return payload
    default:
      return state
  }
}
