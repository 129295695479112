// TODO: move these into more specific/localized files
export const SUBDOMAIN_PREFIX                 = 'subdomainPrefix'
export const ALL_PAGES                        = 'all_pages'
export const ONE_PAGE                         = 'one_page'
export const POLLING_PERIOD_1S                = 1000
export const POLLING_PERIOD_5S                = 5000
export const POLLING_THRESHOLD                = 30000
export const SCANNING                         = 'scanning'
export const SCAN_FINISH                      = 'scan_finish'
export const SCAN_FAULT                       = 'scan_fault'
export const SCAN_FAULT_MESSAGE_ROBOT_TXT     = 'robots txt disallow'
export const PRO_PLUS                         = 'proPlus'
export const STARTER                          = 'starter'
export const PRO                              = 'pro'
export const BASIC                            = 'basic'
export const ON_DEMAND                        = 'on-demand'
export const TIER                             = 'tier'
export const READY                            = 'ready'
export const LOADING                          = 'loading'
export const SUCCESS                          = 'success'
export const RETRY                            = 'retry'
export const FAIL                             = 'fail'
export const YES                              = 'yes'
export const NO                               = 'no'
export const FUNNEL_BUSINESS_INFO             = 'FUNNEL_BUSINESS_INFO'
export const GTM_DATA_TRACKING = {
  IMPLEMENTATION_GUIDE_WIZARD: 'implementation-guide-wizard',
  IMPLEMENTATION_GUIDE_DASHBOARD: 'implementation-guide-dashboard',
}
export const CHECKED = 'checked'
export const WARNING = 'warning'
export const NONE = 'none'
export const CODE_SNIPPET = 'code-snippet'
export const BASIC_USER_PUBLISH_POLICY_QUOTA = 4

export const BASIC_USER_MAX_USER_CONSENT_BANNERS   = 10000
