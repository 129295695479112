import {
  put,
  takeLatest,
} from 'redux-saga/effects'

import createActionCreator from 'utils/redux'

import { setVisible as setDeleteWebsiteModalVisible } from 'reducers/elements/modals/deleteWebsite'


export const CLICK_SUBMIT         = `${ __filename }/CLICK_SUBMIT`
export const CLICK_DELETE_WEBSITE = `${ __filename }/CLICK_DELETE_WEBSITE`

export const clickDeleteWebsite = createActionCreator(CLICK_DELETE_WEBSITE)

export function * sagaClickDeleteWebsite() {
  yield put(setDeleteWebsiteModalVisible(true))
}

export const watchers = {
  * clickDeleteWebsite() {
    yield takeLatest(CLICK_DELETE_WEBSITE, sagaClickDeleteWebsite)
  },
}
