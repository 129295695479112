import { defineMessages } from 'react-intl'


export default defineMessages({
  errorMessage: {
    defaultMessage: 'There was a technical error. Please try again.',
    id: 'react-query-state-handler.error-message',
  },
})

