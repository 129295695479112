import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'
import { useZendesk } from 'react-use-zendesk'

import ZendeskAdaptorContext from './ZendeskAdaptorContext'

const ZENDESK_WIDGET_Z_INDEX = 100


export default function ZendeskAdaptor({ children }) {
  // We can't simply assign this to a `zendesk` const and
  // reference that in the `useMemo()` below because the
  // hook does not memoize the object itself, only the individual
  // functions therein. If you try to to use a `zendesk` object
  // here, `value` will be changed on every page update and you'll
  // fall into an infinite loop.
  const {
    loginUser,
    logoutUser,
    setZIndex,
  } = useZendesk()

  const value = useMemo(() => ({
    signIn({ hasWebsite, user }) {
      loginUser(user.zendesk_jwt_token)
    },

    signOut() {
      logoutUser()
    },

    trackEvent: noop,
    updateUser: noop,
  }), [loginUser, logoutUser])

  // I tried to do this in both useEffect() and useLayoutEffect(), but
  // to no effect (pun intended). This seems to do the trick, though.
  // I hate it, but there is nothing resembling an "after render" callback
  // in react-use-zendesk.
  //
  // We're doing this so that the widget doesn't climb atop the
  // resource-blocker previewer, which the default value causes it to do.
  setZIndex(ZENDESK_WIDGET_Z_INDEX)

  return (
    <ZendeskAdaptorContext.Provider
      value={ value }
    >
      { children }
    </ZendeskAdaptorContext.Provider>
  )
}

ZendeskAdaptor.propTypes = {
  children: PropTypes.node,
}

// eslint-disable-next-line no-empty-function
function noop() {
}
