import FeatureFlagsProvider from './FeatureFlagsProvider'
import handleFlagsmithUser from './lib/handleFlagsmithUser'
import useFeatureFlags from './hooks/useFeatureFlags'

export default FeatureFlagsProvider

export {
  handleFlagsmithUser,
  useFeatureFlags,
}
