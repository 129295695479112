import PropTypes from 'prop-types'
import React from 'react'
import { IntercomProvider } from 'react-use-intercom'

import IntercomAdaptor from './IntercomAdaptor'

// eslint-disable-next-line no-process-env
const APP_ID = ( process.env.NODE_ENV === 'production' ) ? 'e4dbwtll' : 'exbw3qyc'


export default function IntercomAPIProvider({ children }) {
  return (
    <IntercomProvider
      autoBoot
      appId={ APP_ID }
    >
      <IntercomAdaptor>
        { children }
      </IntercomAdaptor>
    </IntercomProvider>
  )
}

IntercomAPIProvider.propTypes = {
  children: PropTypes.node,
}
