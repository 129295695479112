import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'
import Measure from 'react-measure'

import SidebarLink from '../SidebarLink'
import TopLevelLink from '../TopLevelLink'

import AccordionSections from './AccordionSections'

import Styles from './styles.scss'


export default function SidebarAccordion(props) {
  const {
    handleClick,
    icon,
    id,
    isOpen,
    name,
    sections,
  } = props

  const [sectionHeight, setSectionHeight] = useState(0)

  const measureWrapperClassName = classnames(Styles.measure, 't-measureWrapper')

  const measureWrapperStyle = useMemo(() => {
    const height = ( isOpen ) ? sectionHeight : 0

    return {
      height: `${ height }px`,
    }
  }, [isOpen, sectionHeight])

  const onResize = useCallback(({ bounds, margin }) => {
    const { height } = bounds

    setSectionHeight(height + margin.top + margin.bottom)
  }, [setSectionHeight])

  return (
    <>
      {
        // The SidebarLink here is being used just to keep all the
        // TopLevelLinks in the sidebar properly aligned.
      }
      <SidebarLink>
        <TopLevelLink
          hasCarat
          handleClick={ handleClick }
          icon={ icon }
          id={ id }
          isOpen={ isOpen }
          name={ name }
        />
      </SidebarLink>
      <div
        className={ measureWrapperClassName }
        style={ measureWrapperStyle }
      >
        <Measure
          bounds
          margin
          onResize={ onResize }
        >
          { getElementsRenderer(sections) }
        </Measure>
      </div>
    </>
  )
}

SidebarAccordion.propTypes = {
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  sections: AccordionSections.propTypes.sections,

  isOpen: PropTypes.bool,
}

// Yeah, this is a little weird....<Measure> wants its child to
// be a *function*
function getElementsRenderer(sections) {
  // eslint-disable-next-line react/prop-types
  return function MeasuredSectionWrapper({ measureRef }) {
    return (
      <div
        className={ Styles.sections }
        ref={ measureRef }
      >
        <AccordionSections
          sections={ sections }
        />
      </div>
    )
  }
}
