export const classifiedProvider = (cookies) => {
  let classifiedProviderMap = {}
  cookies.forEach((cookie) => {
    if (cookie.domain && classifiedProviderMap[cookie.domain]) {
      classifiedProviderMap[cookie.domain].push(cookie)
    } else if (cookie.domain) {
      classifiedProviderMap = {
        ...classifiedProviderMap,
        [cookie.domain]: [cookie],
      }
    }
  })
  return classifiedProviderMap
}

const createCookieManager = (cookies = []) => {
  const cookieMap = {
    unclassified: [],
    essential: [],
    performance: [],
    analytics: [],
    advertising: [],
    social_networking: [],
  }
  let cookieTable = {}
  cookies.forEach((cookie) => {
    if (cookieMap[cookie.category]) {
      cookieMap[cookie.category].push(cookie)
    }
  })

  Object.keys(cookieMap).forEach((category) => {
    cookieTable = {
      ...cookieTable,
      [category]: classifiedProvider(cookieMap[category]),
    }
  })

  return {
    getCookie(id) {
      return cookies.find((cookie) => cookie.id.toString() === id)
    },
    getCookies() {
      return cookies
    },
    getNewCookies() {
      return cookies.filter((cookie) => cookie.new)
    },
    getClassifiedCookies() {
      return cookieMap
    },
    findClassifiedGroupLength(category) {
      return cookieMap[category].length
    },
    getProviderCookies(category) {
      return cookieTable[category]
    },
  }
}

export default createCookieManager
