import React from 'react'
import PropTypes from 'prop-types'

import AvatarIcon from './AvatarIcon'
import Styles from './styles.scss'


export default function Avatar({ firstName, lastName }) {
  return (
    <div className={ Styles.root }>
      {
        renderContent(firstName, lastName)
      }
    </div>
  )
}

Avatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
}

function renderContent(firstName, lastName) {
  const initials = makeInitials(firstName, lastName)

  if ( initials ) {
    return initials
  }

  return (
    <AvatarIcon />
  )
}

function makeInitials(...names) {
  return names
    .map((name) => name?.charAt(0))
    .join('')
}

