import React, {
  Suspense,
} from 'react'

// TODO: not sure what I want to do with this. Spinner?
const FALLBACK = (<></>)


export default function withSuspense(Comp) {
  return (
    <Suspense
      fallback={ FALLBACK }
    >
      <Comp />
    </Suspense>
  )
}
