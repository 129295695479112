import * as Sentry from '@sentry/browser'

import SentryKeys from './sentry-keys'
import configureSentryScope from './configureSentryScope'


export default function initializeSentry({ store }) {
  // eslint-disable-next-line no-process-env
  const { NODE_ENV } = process.env

  // Traffic to Sentry costs the company money, so we'll not generate
  // unnecessary expenditures here.
  if ( NODE_ENV === 'development' ) {
    return
  }

  const dsn = SentryKeys[NODE_ENV] || SentryKeys.staging

  Sentry.init({
    dsn,
    release: '2.0.0',
  })

  Sentry.getCurrentScope(configureSentryScope(store))
}
