// This simple wrapper is provided solely as a convenience so that callers can cleanly
// invoke the Heap API (https://developers.heap.io/reference/client-side-apis-overview)
// without regard to whether window.heap exists.


export default {
  track(event, properties) {
    return window.heap?.track(event, properties)
  },
}
