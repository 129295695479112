import { useThrottleCallback } from '@react-hook/throttle'

import useWebsiteOptionsMutation from '../useWebsiteOptionsMutation'

import makeLoadWebsiteSelectOptions from './makeLoadWebsiteSelectOptions'


export default function useLoadOptions(args) {
  const {
    defaultValue,
    handlePagination,
    orderBy,
    pageSize,
    throttleRate,
  } = args

  const {
    error,
    isLoading,
    mutateAsync,
  } = useWebsiteOptionsMutation({
    orderBy,
    pageSize,
  })

  const unthrottledLoadOptions = makeLoadWebsiteSelectOptions({
    defaultValue,
    handlePagination,
    mutateAsync,
  })

  const loadOptions = useThrottleCallback(unthrottledLoadOptions, throttleRate)

  return {
    error,
    isLoading,
    loadOptions,
  }
}
