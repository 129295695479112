import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { SmartLink } from '@termly_web/common'

import useIsPageAllowed from 'hooks/useIsPageAllowed'

import SidebarLink from '../../../SidebarLink'

import Styles from './styles.scss'


export default function SectionLink({ id, name, url }) {
  const className = classnames(Styles.root, `t-paragraph--${ id }`)

  const isPageAllowed = useIsPageAllowed(url)

  if ( !isPageAllowed ) {
    return null
  }

  return (
    <SidebarLink
      className={ className }
      url={ url }
    >
      <SmartLink
        className={ Styles.link }
        url={ url }
      >
        <FormattedMessage
          { ...name }
        />
      </SmartLink>
    </SidebarLink>
  )
}

SectionLink.propTypes = {
  name: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,

  id: PropTypes.string,
}
