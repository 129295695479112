import URI from 'urijs'

// Unfortunately, we have to be "oddly specific" about what that thing
// after "/dashboard/website/" is. It shouldn't be an integer, as
// that's a hold-over from days gone by, but every once in a while we
// see one of those integer-based URLs slip through. Rather, it should
// be a UUID.
//
// We're trying to strike a balance here between being specific enough
// about our expectations about what a UUID looks like, and not being
// *overly* specific about it so that we wind up creating a time bomb
// for ourselves should the format of the UUID change slightly over
// time.
//
const WEBSITE_ID_REGEX = /^\/dashboard\/website\/((?:[abcdef0-9]{4,}-?){3,}|\d+)/


export function getDomain(url) {
  return new URI(url).domain()
}

// N.B.: this is not named 'getWebsiteId()' because there's a selector bearing
// that same name and I want the two to be easily distinguishable when grepping
// the source code.
//
// TODO: rename `getDomain()`, above, to `extractDomain()`
/**
 * Parses the supplied URL and returns the website ID if it has a path that
 * starts with /dashboard/website/ (e.g. '/dashboard/website/5' will return '5').
 *
 * @param {String} url
 *
 * @returns {String|undefined}
 */
export function extractWebsiteId(url) {
  // We want to catch null and undefined here, so it's okay to simply use '=='
  // eslint-disable-next-line eqeqeq
  if ( url == null ) {
    return
  }

  const path = new URI(url).path()

  const matches = WEBSITE_ID_REGEX.exec(path)

  if ( !matches ) {
    return
  }

  return matches[1]
}
