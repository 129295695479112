import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import ExternalLink from '../ExternalLink'


export default function SmartLink({ children, className, showIcon = true, url }) {
  const formattedURL = new URL(url, window.location)

  const isLocalLink = ( formattedURL.host === window.location.host )

  if ( isLocalLink ) {
    return (
      <Link
        className={ className }
        to={ url }
      >
        { children }
      </Link>
    )
  }

  return (
    <ExternalLink
      className={ className }
      showIcon={ showIcon }
      url={ url }
    >
      { children }
    </ExternalLink>
  )
}

SmartLink.propTypes = {
  url: PropTypes.string.isRequired,

  children: PropTypes.node,
  className: PropTypes.string,
  showIcon: ExternalLink.propTypes.showIcon,
}

