import React, {
  useCallback,
} from 'react'

import { useModal } from 'components/ModalProvider'

import LazyDiscardChangesModal from './components/LazyDiscardChangesModal'


export default function useDiscardChangesModal(props) {
  const {
    additionalWebsiteDetails,
    handleSuccess: handleSuccessProp,
    planName,
    show,
  } = props

  const modal = useModal()

  const handleSuccess = useCallback(() => {
    handleSuccessProp()
    modal.close()
  }, [handleSuccessProp, modal])

  // TODO: TER-14760
  // This ( !show ) check is a temporary fix to prevent the `setModalContent()` from being
  // called unnecessarily.
  //
  // We've run into an issue where 2 modals are clobbering each other.
  // If a user clicks "add website" but has used all of his licenses,
  // he is prompted to purchase another license and then is redirected to the `/plan-info` page
  // with a `returnTo=addWebsite` URL param that triggers the "Add Website" modal so he
  // can continue on with what he was trying to do, add a website.
  // The problem is that on said `/plan-info` page, we have the <PlanRenewOrCancelButton />
  // and <ManageSubscriptionButton /> components that both call the
  // `useDiscardChangesModal` hook (the hook you're reading this comment inside of right now).
  // As you can see below, we call the `setModalContent()` function on the modal provider
  // before we ever know if the modal needs to be opened or not.
  // This is what causes the "Add Website" modal, that was opened by the
  // `returnTo=addWebsite` URL param, to be clobbered by the "Discard Changes" modal.
  //
  if ( !show ) {
    return
  }

  modal.setModalContent(
    <LazyDiscardChangesModal
      additionalWebsiteDetails={ additionalWebsiteDetails }
      closeModal={ modal.close }
      handleSuccess={ handleSuccess }
      planName={ planName }
    />
  )

  return modal
}
