import {
  applyMiddleware,
  createStore,
} from 'redux'

import {
  persistReducer,
  persistStore,
} from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import createSagaMiddleware from 'redux-saga'
import createImmutableStateInvariant from 'redux-immutable-state-invariant'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from 'reducers'
import rootSaga from 'sagas'

export const saga = createSagaMiddleware()
export const immutableStateInvariant = createImmutableStateInvariant()


const middlewareEnhancer = (() => {
  const middlewares = [
    saga,
  ]
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
    return composeWithDevTools(applyMiddleware(
      ...middlewares,
      immutableStateInvariant,
    ))
  }
  return applyMiddleware(...middlewares)
})()

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, middlewareEnhancer)

const persistor = persistStore(store)

saga.run(rootSaga)

export {
  store,
  persistor,
}
