import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from './styles.scss'


export default function ContentConstraint({ children, className }) {
  const rootClassName = classnames(Styles.root, className)

  return (
    <div className={ rootClassName }>
      { children }
    </div>
  )
}

ContentConstraint.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
