import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  change,
  startSubmit,
  submit,
  stopSubmit,
  initialize,
} from 'redux-form'

import history from 'store/history'

import api from 'utils/api'
import {
  getWebsiteUUID as getWebsiteUUIDPath,
  getSnippetWebsite,
  postCustomerRequest,
} from 'constants/api'
import PATHS from 'constants/paths'
import formId from 'constants/formIds/customerRequest'
import createActionCreator from 'utils/redux'
import {
  getRequestParams,
  getFormInvalidStatus,
  getInitializeFormValues,
  getWebsiteUUID as selectWebsiteUUID,
  getWebsiteName,
} from 'selectors/forms/customerRequest'

import { DEFAULT_LAW_ACTIONS } from 'selectors/forms/constants'

import {
  setData,
} from 'reducers/responses/snippetWebsite'

export const HANDLE_DID_MOUNT = `${ __filename }/HANDLE_DID_MOUNT`
export const REQUEST_LAW_CHANGED = `${ __filename }/REQUEST_LAW_CHANGED`
export const SUBMIT = `${ __filename }/SUBMIT`

export const handleDidMount = createActionCreator(HANDLE_DID_MOUNT)
export const handleRequestLawChanged = createActionCreator(REQUEST_LAW_CHANGED)
export const handleSubmit = createActionCreator(SUBMIT)

export function * sagaHandleDidMount({ payload }) {
  const { documentUUID } = payload

  try {
    const websiteUUID = yield call(getWebsiteUUIDFromDocumentUUID, documentUUID)
    const websiteResponse = yield call(api.getData, getSnippetWebsite(websiteUUID))
    yield put(setData(websiteResponse))

    yield call(sagaHandleInitializeForm)
  } catch (e) {
    console.error(e)
  }
}

export function* sagaHandleInitializeForm() {
  const initializeFormValues = yield select(getInitializeFormValues)
  yield put(initialize(formId.name, initializeFormValues))
}

export function* sagaHandleRequestLawChanged(action) {
  try {
    const requestLaw = action.payload

    const defaultAction = DEFAULT_LAW_ACTIONS[requestLaw]

    yield put(change(formId.name, formId.fields.action, defaultAction))
  } catch (e) {
    console.error(e)
  }
}

export function* sagaHandleSubmit({ payload }) {
  const { documentUUID } = payload

  try {
    yield put(startSubmit(formId.name))
    yield put(submit(formId.name))
    const isInvalid = yield select(getFormInvalidStatus)
    if (isInvalid) return

    const requestParams = yield select(getRequestParams)

    const websiteUUID = yield select(selectWebsiteUUID)
    yield call(api.postData, postCustomerRequest(websiteUUID), requestParams)
    const websiteName = yield select(getWebsiteName)

    const url = PATHS.NOTIFY_ACTIONS_SUCCESS(documentUUID, {
      compliance_type: requestParams.compliance_type,
      website_name: websiteName,
    })

    yield call(history.push, url)
  } catch (e) {
    console.error(e)
  } finally {
    yield put(stopSubmit(formId.name))
  }
}

export const watchers = {
  * handleDidMount() {
    yield takeLatest(HANDLE_DID_MOUNT, sagaHandleDidMount)
  },
  * handleRequestLawChanged() {
    yield takeLatest(REQUEST_LAW_CHANGED, sagaHandleRequestLawChanged)
  },
  * handleSubmit() {
    yield takeLatest(SUBMIT, sagaHandleSubmit)
  },
}

// Provided solely to make testing easier. Don't even think of using this
// in non-test code.
export const _private = {
  getWebsiteUUIDFromDocumentUUID,
}

function* getWebsiteUUIDFromDocumentUUID(documentUUID) {
  const response = yield call(api.getData, getWebsiteUUIDPath(documentUUID))

  return response.uuid
}
