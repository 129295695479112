export default function findAccordionIDForPathname(accordions, pathname) {
  if ( !accordions ) {
    return
  }

  const openAccordion = accordions.find(({ sections }) => {
    return sections.find(({ links }) => {
      return links.find(({ url }) => {
        return pathname === url
      })
    })
  })

  return openAccordion?.id
}

