import React, {
  PureComponent,
} from 'react'

import PropTypes from 'prop-types'

import { MessageBox } from '@termly_web/common'


export default class MsgBox extends PureComponent {
  static propTypes = {
    content: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    theme: PropTypes.string,
  }

  static defaultProps = {
    content: null,
    theme: 'error',
  }

  render() {
    const {
      content,
      theme,
    } = this.props

    if ( !content ) {
      return null
    }

    return (
      <div>
        {
          renderMsgBox({
            content,
            theme,
          })
        }
      </div>
    )
  }
}

function renderMsgBox({ theme, content }) {
  if ( Array.isArray(content) ) {
    return content.map((msg, index) => (
      <MessageBox
        key={ index }
        theme={ theme }
      >
        { msg }
      </MessageBox>
    ))
  }

  return (
    <MessageBox
      theme={ theme }
    >
      { content }
    </MessageBox>
  )
}
