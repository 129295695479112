const errorMsgHandling = (error) => {
  if ((typeof error) === 'string') {
    return error
  }
  if (Array.isArray(error)) {
    if (error.length === 1) {
      return error[0]
    }
    return error
  }
  let errorArray = []
  Object.keys(error).forEach((key) => {
    if ((typeof error[key]) === 'string') {
      errorArray.push(error[key])
    } else {
      if (key !== 'error' && key !== 'errors') {
        const subject = key.replace('_', ' ')
        error[key] = error[key].map((msg) => `${ subject } ${ msg }`)
      }
      errorArray = errorArray.concat(error[key])
    }
  })
  if (errorArray.length === 1) {
    return errorArray[0]
  }
  return errorArray
}

const errorArrayGenerator = (errors) => {
  if (!errors) {
    return errors
  }
  if (errors.error) {
    return errorMsgHandling(errors.error)
  }
  return errorMsgHandling(errors)
}

export default errorArrayGenerator
