export default function makeMessage(error) {
  if ( !error ) {
    return
  }

  if ( error.defaultMessage && error.id ) {
    return error
  }

  const defaultMessage = error.message || error

  if ( !defaultMessage?.replace ) {
    return
  }

  const id = defaultMessage.replace(/[^A-Za-z0-9]/g, '-')

  return {
    defaultMessage,
    id: `react-query-state-handler.adhoc-message.${ id }`,
  }
}
