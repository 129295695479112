import React from 'react'
import PropTypes from 'prop-types'

import { CLICK_NEW_WEBSITE_DROPDOWN } from 'constants/dataTrackingHeap'

import useNavigateToBusinessInfo from 'hooks/useNavigateToBusinessInfo'

import AddWebsiteButton from 'components/AddWebsiteButton'
import { useUserPolicy } from 'components/UserPolicyProvider'

import Styles from './styles.scss'

const BUTTON_CLASS_NAME = `${ Styles.button } t-addWebsiteButton`
const CAN_CREATE_WEBSITE = 'can_create_website'


export default function HeaderWebsiteSelectFooter({ selectProps }) {
  const { onMenuClose } = selectProps

  const handleSuccess = useNavigateToBusinessInfo()
  const { userPolicy } = useUserPolicy()

  const canCreateWebsite = userPolicy.has(CAN_CREATE_WEBSITE)

  return (
    <div className={ Styles.root }>
      {
        renderAddWebsiteButton({
          canCreateWebsite,
          handleSuccess,
          onMenuClose,
        })
      }
    </div>
  )
}

HeaderWebsiteSelectFooter.propTypes = {
  selectProps: PropTypes.shape({
    onMenuClose: PropTypes.func,
  }),
}

function renderAddWebsiteButton({ canCreateWebsite, handleSuccess, onMenuClose }) {
  if ( !canCreateWebsite ) {
    return null
  }

  return (
    <AddWebsiteButton
      className={ BUTTON_CLASS_NAME }
      color="primary"
      data-tracking-heap={ CLICK_NEW_WEBSITE_DROPDOWN }
      handleClick={ onMenuClose }
      handleSuccess={ handleSuccess }
    />
  )
}
