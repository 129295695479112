/**
 * Takes a space-delimited list of classNames and maps those names to
 * values found in the supplied `style` object.
 *
 * WARNING: If you supply a className that is not found in `style`,
 * that name will be silently dropped.
 *
 * @param {String} [className='']
 * @param {Object} style - a css module
 */
// TODO: naming is unfortunate. It's used throughout the application as
// `mapClassNameToArray()` but the file name does not agree.
export default function mapClassName(className = '', style) {
  return className
    .trim()
    .split(/\s/)
    .map((cls) => style[cls] || cls)
    .filter(Boolean)
}
