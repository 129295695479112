import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

import PATHS from 'constants/paths'

import styles from './header.scss'


export default class LogoHeader extends PureComponent {
  render() {
    return (
      <header className={ styles['logo-header'] }>
        <Link to={ PATHS.ROOT }><div className={ styles['logo'] } /></Link>
      </header>
    )
  }
}
