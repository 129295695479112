import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'

import {
  NavigateToPricingPageButton,
  PRICING_PAGE_PARAMS,
} from '@termly_web/common'

import useBillingInfoQuery from 'hooks/useBillingInfoQuery'
import useCurrentUserPlanQuery from 'hooks/useCurrentUserPlanQuery'
import useWebsitesQuery from 'hooks/useWebsitesQuery'

import noop from 'utils/noop'

import AddWebsiteModalButton from './AddWebsiteModalButton'
import DiscardChangesModalButton from './DiscardChangesModalButton'
import NavigateToCheckoutPreviewButton from './NavigateToCheckoutPreviewButton'

import Locale from './locale'
import Styles from './styles.scss'

const DEFAULT_COLOR = 'secondary'

const CONFIG = {
  addWebsiteModalButton: AddWebsiteModalButton,
  checkoutPreviewButton: NavigateToCheckoutPreviewButton,
  discardChangesModalButton: DiscardChangesModalButton,
}


export default function AddWebsiteButton(props) {
  const {
    buttonText = Locale.buttonText,
    className,
    color = DEFAULT_COLOR,
    dataTracking,
    handleClick = noop,
    handleSuccess,
  } = props

  const { billingInfo } = useBillingInfoQuery()
  const isAccountPendingCancellation = Boolean(billingInfo?.scheduled_for_cancellation_on)

  const { websites } = useWebsitesQuery()
  const currentWebsiteCount = websites?.length

  const {
    isBasic,
    planName,
  } = useCurrentUserPlanQuery()

  const buttonClassName = classnames(Styles.root, className)
  const buttonMessage = (
    <FormattedMessage
      { ...buttonText }
    />
  )

  if ( isBasic && currentWebsiteCount > 0  ) {
    return (
      <NavigateToPricingPageButton
        className={ buttonClassName }
        color={ color }
        dataTracking={ dataTracking }
        queryParam={ PRICING_PAGE_PARAMS.ADD_WEBSITE }
        type="solid"
      >
        { buttonMessage }
      </NavigateToPricingPageButton>
    )
  }

  const Component = getComponent({
    billingInfo,
    currentWebsiteCount,
    isAccountPendingCancellation,
  })

  return (
    <Component
      className={ buttonClassName }
      color={ color }
      dataTracking={ dataTracking }
      handleClick={ handleClick }
      handleSuccess={ handleSuccess }
      message={ buttonMessage }
      planName={ planName }
    />
  )
}

AddWebsiteButton.propTypes = {
  handleSuccess: PropTypes.func.isRequired,

  buttonText: PropTypes.shape({
    defaultMessage: PropTypes.string,
    id: PropTypes.string,
  }),
  className: PropTypes.string,
  color: PropTypes.string,
  dataTracking: PropTypes.string,
  handleClick: PropTypes.func,
}

function getComponent({ billingInfo, currentWebsiteCount, isAccountPendingCancellation }) {
  if ( isAccountPendingCancellation && currentWebsiteCount > 0 ) {
    return CONFIG.discardChangesModalButton
  }

  if ( currentWebsiteCount >= billingInfo.website_allowed ) {
    return CONFIG.checkoutPreviewButton
  }

  return CONFIG.addWebsiteModalButton
}
