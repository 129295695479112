import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import styles from './popover.scss'

export default class Popover extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ]),
    className: PropTypes.string,
  }

  static defaultProps = {
    show: false,
    children: null,
    className: 'popover',
  }

  render() {
    const {
      show,
      children,
      className,
    } = this.props
    return (
      show && (
        <div className={ styles[className] }>
          { children }
        </div>
      )
    )
  }
}
