import createActionCreator from 'utils/redux'

// Using __filename wrecks havoc in CircleCI if we take snapshots of events
const NAMESPACE = 'reducers/panels/bannerSettings'

export const UPDATE_SHOW_FONT_FAMILY_OPTIONS = `${ NAMESPACE }/UPDATE_SHOW_FONT_FAMILY_OPTIONS`
export const UPDATE_SHOW_FONT_SIZE_OPTIONS = `${ NAMESPACE }/UPDATE_SHOW_FONT_SIZE_OPTIONS`
export const UPDATE_SHOW_FONT_COLOR_OPTIONS = `${ NAMESPACE }/UPDATE_SHOW_FONT_COLOR_OPTIONS`
export const UPDATE_SHOW_BUTTON_BACKGROUND_OPTIONS = `${ NAMESPACE }/UPDATE_SHOW_BUTTON_BACKGROUND_OPTIONS`
export const UPDATE_SHOW_BUTTON_TEXT_COLOR_OPTIONS = `${ NAMESPACE }/UPDATE_SHOW_BUTTON_TEXT_COLOR_OPTIONS`
export const UPDATE_SHOW_BANNER_BACKGROUND_OPTIONS = `${ NAMESPACE }/UPDATE_SHOW_BANNER_BACKGROUND_OPTIONS`

export const updateShowFontFamilyOptions = createActionCreator(
  UPDATE_SHOW_FONT_FAMILY_OPTIONS,
)
export const updateShowFontSizeOptions = createActionCreator(
  UPDATE_SHOW_FONT_SIZE_OPTIONS,
)
export const updateShowFontColorOptions = createActionCreator(
  UPDATE_SHOW_FONT_COLOR_OPTIONS,
)
export const updateShowButtonBackgroundOptions = createActionCreator(
  UPDATE_SHOW_BUTTON_BACKGROUND_OPTIONS,
)
export const updateShowButtonTextColorOptions = createActionCreator(
  UPDATE_SHOW_BUTTON_TEXT_COLOR_OPTIONS,
)
export const updateShowBannerBackgroundOptions = createActionCreator(
  UPDATE_SHOW_BANNER_BACKGROUND_OPTIONS,
)

export const initState = {
  showPreview: false,
  showFontFamilyOptions: false,
  showFontSizeOptions: false,
  showFontColorOptions: false,
  showButtonBackgroundOptions: false,
  showButtonTextColorOptions: false,
  showBannerBackgroundOptions: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_SHOW_FONT_FAMILY_OPTIONS:
      return {
        ...state,
        showFontFamilyOptions: payload,
      }
    case UPDATE_SHOW_FONT_SIZE_OPTIONS:
      return {
        ...state,
        showFontSizeOptions: payload,
      }
    case UPDATE_SHOW_FONT_COLOR_OPTIONS:
      return {
        ...state,
        showFontColorOptions: payload,
      }
    case UPDATE_SHOW_BUTTON_BACKGROUND_OPTIONS:
      return {
        ...state,
        showButtonBackgroundOptions: payload,
      }
    case UPDATE_SHOW_BUTTON_TEXT_COLOR_OPTIONS:
      return {
        ...state,
        showButtonTextColorOptions: payload,
      }
    case UPDATE_SHOW_BANNER_BACKGROUND_OPTIONS:
      return {
        ...state,
        showBannerBackgroundOptions: payload,
      }
    default:
      return state
  }
}
