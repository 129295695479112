import { URLBuilder } from '@termly_web/common'

import Paths from 'constants/paths'

import { Policies } from 'components/UserPolicyProvider'

import Locale from './locale'

const ACCOUNT_LINKS = {
  policy: Policies.ALLOW_COLLABORATORS,
  title: Locale.account,
  links: [
    {
      id: 'userManagement',
      name: Locale.users,
      url: Paths.USER_MANAGEMENT,
    },
    {
      id: 'websiteManagement',
      name: Locale.websites,
      url: URLBuilder.websitesURL(),
    },
  ],
}

const BILLING_LINKS = {
  policy: Policies.CAN_VIEW_BILLING,
  title: Locale.subscription,
  links: [
    {
      id: 'planInfo',
      name: Locale.planInfo,
      url: Paths.PLAN_INFO,
    },
    {
      id: 'creditCardInfo',
      name: Locale.creditCardInfo,
      policy: Policies.HAS_CARD_ON_FILE,
      url: Paths.BILLING,
    },
    {
      id: 'billingHistory',
      name: Locale.billingHistory,
      policy: Policies.HAS_CARD_ON_FILE,
      url: Paths.BILLING_HISTORY,
    },
  ],
}

export const LINK_GROUPS = [
  ACCOUNT_LINKS,
  BILLING_LINKS,
]
