import { useQuery } from '@tanstack/react-query'

import Client from 'utils/client'

import getCacheKey from './getCacheKey'
import makeURL from './makeURL'

/**
 * @typedef {Object} WebsitesQuery
 * @property {Object} websites
 * @property {Object[]} websites.migrated
 * @property {Object[]} websites.websites
 */

/**
 * @typedef {Object} PaginationArgs
 * @property {String} current_page - current page token
 * @property {String} order_by
 * @property {Number} page_size - how many websites should be in each page of results
 */

/**
 * @param {PaginationArgs} args
 * @param {Object} options - see "Options" at https://react-query.tanstack.com/reference/useQuery
 *
 * @returns {WebsitesQuery} also includes the response detailed at https://react-query.tanstack.com/reference/useQuery
 */
export default function useWebsitesQuery(args = {}, options) {
  const url = makeURL({
    currentPage: args.currentPage,
    orderBy: args.orderBy,
    pageSize: args.pageSize,
  })

  const {
    data = {},
    ...rest
  } = useQuery(getCacheKey(args),
               () => Client.get(url),
               options)

  const responseData = data.data || {}

  return {
    ...rest,
    // `data` is basically the XHR response (headers, request object, etc.).
    // The actual payload returned by the server is `data.data`.
    // Sad, but true.
    //
    // Even sadder yet...the API returns { websites, migrated }. It's
    // not clear if we ever really use `migrated` in this application,
    // but until I determine the answer, I'm not going to muck with
    // it.
    migrated: responseData.migrated,
    pagination: responseData.pagination,
    websites: responseData.websites,
  }
}
