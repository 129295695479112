import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import Paths from 'constants/paths'


export default function useNavigateToBusinessInfo() {
  const navigate = useNavigate()

  return useCallback(({ websiteID }) => {
    navigate(Paths.BUSINESS_INFO(websiteID))
  }, [navigate])
}
