import {
  call,
  put,
  takeLatest,
  all,
} from 'redux-saga/effects'

import {
  apiFailure,
  toggleLoading,
  toggleDialog,
} from 'reducers/ui'

import {
  MIGRATE_DOC,
  DELETE_DOC_BY_ID,
  setWebsiteInfo,
  deleteDocByIdSuccess,
  GET_WEBSITE,
} from 'reducers/dashboard'

import { setData as setWebsiteData } from 'reducers/responses/website'

import {
  websiteInfo,
  website,
  documentInfo,
  migrateDoc,
} from 'constants/api'

import API from 'utils/api'
import errorArrayGenerator from 'utils/errorArrayGenerator'

import { apiCallBasic } from './apiCall'


export function* getWebsites() {
  yield* apiCallBasic(null, API.getData, websiteInfo, setWebsiteInfo)
}

export function* getWebsite(websiteID) {
  try {
    const websiteInfoData = yield call(API.getData, website(websiteID))

    yield put(setWebsiteData(websiteInfoData))

    return websiteInfoData
  } catch (e) {
    const error = yield e.json.json()
    yield put(apiFailure(errorArrayGenerator(error)))
  }
}

export function* tableUpdate() {
  yield all([
    call(getWebsites),
    put(toggleDialog()),
  ])
}

export function* deleteDocById(action) {
  try {
    yield call(API.deleteData, documentInfo(action.payload))
    yield call(tableUpdate)
    yield put(deleteDocByIdSuccess())
  } catch (e) {
    console.error(e)
    const error = yield e.json.json()
    yield put(apiFailure(errorArrayGenerator(error)))
  }
}

export function* migrateDocument(action) {
  yield put(toggleLoading({ element: 'form', isLoading: true }))
  const { document_id, website_id, error } = action.payload
  try {
    yield call(API.putData, migrateDoc(document_id), { document: { website_id }, override: !!error })
    yield call(tableUpdate)
  } catch (e) {
    const errorJSON = yield e.json.json()
    yield put(apiFailure(errorArrayGenerator(errorJSON)))
  }
  yield put(toggleLoading('form'))
}

export function* watchDeleteDocById() {
  yield takeLatest(DELETE_DOC_BY_ID, deleteDocById)
}

export function* watchMigrateDoc() {
  yield takeLatest(MIGRATE_DOC, migrateDocument)
}

export function* watchGetWebsite() {
  yield takeLatest(GET_WEBSITE, ({ payload: websiteId }) => {
    return getWebsite(websiteId)
  })
}
