export default {
  cardFormatBlocks: {
    amex:    [4, 6, 5],
    jcb:     [4, 4, 4, 4],
    visa:    [4, 4, 4, 4],
    master:  [4, 4, 4, 4],
    general: [4, 4, 4, 4],
  },
  cardType: 'unknown',
  cardCVCFormat: 3,
  cardFormatRegex : {
    amex:   /^3[47]\d{0,13}/,
    jcb:    /^(?:2131|1800|35\d{0,2})\d{0,12}/,
    visa:   /^4\d{0,15}/,
    master: /^(5[1-5]|2[2-7])\d{0,14}/,
    general: /\d{0,15}/,
  },
  getFormatBlocksLen(blocks) {
    return blocks.reduce((prev,current) => {
      return prev + current
    },0)
  },
  getCardInfo(value) {
    const cardTypes = ['amex', 'jcb', 'visa', 'master', 'general']
    const AMEX_CVC_LENGTH = 4
    const GENERAL_LENGTH = 3
    for (const type of cardTypes) {
      if (this.cardFormatRegex[type].test(value)) {
        this.cardCVCFormat = (type === 'amex') ? AMEX_CVC_LENGTH : GENERAL_LENGTH
        this.cardType = type
        return {
          type,
          blocks: this.cardFormatBlocks[type],
        }
      }
    }
  },
  getFormattedValue(value, blocks, blocksLen, delimiter) {
    let result = ''
    if (blocksLen === 0) {
      return value
    }
    blocks.forEach((length, index) => {
      if (value.length > 0) {
        const subStr = value.slice(0,length)
        const rest = value.slice(length)
        result += subStr
        if (subStr.length === length && index < blocks.length - 1) {
          result += delimiter
        }
        value = rest.trim()
      }
    })
    return result.trim()
  },
}
