import {
  createContext,
  useContext,
} from 'react'

const UserTrackerContext = createContext()


export default UserTrackerContext

export function useUserTracker() {
  return useContext(UserTrackerContext)
}

