import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import TermlyLogo from './img/termly-logo.svg'
import AbstractArt from './img/abstract-art.svg'

import useGoneFishin from './hooks/useGoneFishin'

import Locale from './locale'
import Styles from './styles.scss'

// TODO: Eventually, I'd like to internalize the fetchHealth prop, but
// that's a bit out of scope for what I'm attempting right now.
export default function GoneFishin({ children, fetchHealth }) {
  const {
    isError,
    isGoneFishin,
    isLoading,
  } = useGoneFishin(fetchHealth)

  if ( isLoading ) {
    return null
  }

  if ( !isGoneFishin && !isError ) {
    return children
  }

  const title = ( isError ) ? Locale.errorTitle : Locale.title
  const message = ( isError ) ? Locale.errorContent : Locale.content

  return (
    <div className={ Styles.root }>
      <img
        className={ Styles.logo }
        src={ TermlyLogo }
      />
      <div className={ Styles.container }>
        <img
          className={ Styles.abstractArt }
          src={ AbstractArt }
        />
        <h1 className={ Styles.title }>
          <FormattedMessage { ...title } />
        </h1>
        <p className={ Styles.bodyText }>
          <FormattedMessage { ...message } />
        </p>
      </div>
    </div>
  )
}

GoneFishin.propTypes = {
  fetchHealth: PropTypes.func.isRequired,

  children: PropTypes.node,
}
