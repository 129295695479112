import React, {
  useEffect,
  useMemo,
} from 'react'

import PropTypes from 'prop-types'
import Userflow from 'userflow.js'

import UserflowAPIContext from './UserflowAPIContext'

import { USERFLOW_TOKEN } from './lib/constants'

const USERFLOW_WIDGET_Z_INDEX = 100


export default function UserflowAPIProvider({ children }) {
  useEffect(() => {
    Userflow.setBaseZIndex(USERFLOW_WIDGET_Z_INDEX)
  }, [])

  const value = useMemo(() => ({
    signIn({ hasWebsite, user }) {
      // I originally had this in a `useEffect()` hook, but that
      // wasn't getting called until after I'd attempted to invoke
      // `.signIn()`, so I figure we'll just handle it here.
      Userflow.init(USERFLOW_TOKEN)

      if ( !user?.uuid ) {
        return
      }

      const signedUpAt = new Date(user.created_at * 1000).toISOString()

      // TODO: This plan_name check should really be done using `usePlansQuery()`
      // or `useCurrentUserPlansQuery()` that is currently found only in dashboard
      const hasUpgraded = ( user.plan_name !== 'basic' && !user.is_trial_user )

      Userflow.identify(user.uuid, {
        /* eslint-disable camelcase */
        email: user.email,
        hasUpgraded,
        hasWebsite,
        signed_up_at: signedUpAt,
        /* eslint-enable */
      })
    },

    signOut() {
      if ( !Userflow.isIdentified() ) {
        return
      }

      Userflow.reset()
    },

    trackEvent(eventName) {
      if ( !Userflow.isIdentified() ) {
        return
      }

      // N.B.: the Userflow API supports a second argument for a
      // payload to accompany the event name
      // (https://userflow.com/docs/userflow-js#track) but we don't
      // have a need for that at the moment.
      Userflow.track(eventName)
    },

    updateUser(attrs) {
      if ( !Userflow.isIdentified() ) {
        return
      }

      Userflow.updateUser(attrs)
    },
  }), [])

  return (
    <UserflowAPIContext.Provider
      value={ value }
    >
      { children }
    </UserflowAPIContext.Provider>
  )
}

UserflowAPIProvider.propTypes = {
  children: PropTypes.node,
}
