import { defineMessages } from 'react-intl'


export default defineMessages({
  pageNotFoundTitle: {
    id: 'missing-content.page-not-found-title',
    defaultMessage: '404 Page not Found',
  },

  pageNotFoundCaption: {
    id: 'missing-content.page-not-found-caption',
    defaultMessage: 'Sorry, the page you are looking for doesn\'t exist',
  },

  createFirstPolicyTitle: {
    id: 'missing-content.create-first-policy-title',
    defaultMessage: 'CREATE YOUR FIRST POLICY',
  },

  createFirstPolicyCaption: {
    id: 'missing-content.create-first-policy-caption',
    defaultMessage: 'Set up legal compliance for your website / mobile app.',
  },

  noInvoiceTitle: {
    id: 'missing-content.no-invoice-title',
    defaultMessage: 'You have no invoices.',
  },

  noPolicyTitle: {
    id: 'missing-content.no-policy-title',
    defaultMessage: 'Oops! The policy is not here.',
  },

  noPolicyCaption: {
    id: 'missing-content.no-policy-caption',
    defaultMessage: 'You may have mistyped address or the page may have moved.',
  },
})
