import React from 'react'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { URLBuilder } from '@termly_web/common'

import Locale from './locale'
import Styles from './styles.scss'

const CLASS_NAME = classnames(Styles.logout, 't-logoutLink')


export default function AvatarFlyoutLogout() {
  return (
    <Link
      className={ CLASS_NAME }
      to={ URLBuilder.logoutURL() }
    >
      <FormattedMessage
        { ...Locale.logout }
      />
    </Link>
  )
}
