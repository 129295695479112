import useBillingInfoQuery from 'hooks/useBillingInfoQuery'
import useCurrentUserPlanQuery from 'hooks/useCurrentUserPlanQuery'
import useWebsite from 'hooks/useWebsite'

import { useCurrentWebsiteID } from 'components/CurrentWebsiteIDProvider'


export default function useShowNotificationBanner() {
  const {
    isError: isBillingInfoQueryError,
    isLoading: isBillingInfoQueryLoading,
    billingInfo,
  } = useBillingInfoQuery()

  const isSubscriptionPastDue = ( billingInfo?.subscription_status === 'past_due' )

  const {
    canPublish,
    isError: isCurrentUserPlanQueryError,
    isLoading: isCurrentUserPlanQueryLoading,
    isOverConsentQuota,
  } = useCurrentUserPlanQuery()

  const websiteID = useCurrentWebsiteID()
  const { website } = useWebsite({ websiteID })

  const hasMultipleNonCookiePolicies = website?.has_multiple_non_cookie_policies

  return {
    isError: ( isBillingInfoQueryError || isCurrentUserPlanQueryError ),
    isLoading: ( isBillingInfoQueryLoading || isCurrentUserPlanQueryLoading ),
    isOverConsentQuota,
    isSubscriptionPastDue,
    showBanner: ( isSubscriptionPastDue || isOverConsentQuota  || ( !canPublish && hasMultipleNonCookiePolicies ) ),
  }
}
