/**
 * Checks to see if `path` matches any of the paths in `testPaths`. If it doesn't,
 * then the last slash-delimited chunk of `path` is lopped off and the check is
 * performed again until either a match is found or there are no more chunks.
 *
 * Given: testPaths = ['foo/bar/baz']
 * When:
 *   path = 'foo/bar/baz/woot' => true
 *   path = 'foo/bar/baz' => true
 *   path = 'foo/bar' => false
 *   path = 'snapple/face' => false
 *
 * @param {String} pathname
 * @param {String[]} testPaths
 *
 * @returns {Boolean}
 */
export default function pathMatches(pathname, testPaths) {
  if ( !pathname || !testPaths || !testPaths.length ) {
    return false
  }

  const set = new Set(testPaths)

  const lookup = (path) => {
    if ( !path ) {
      return false
    }

    if ( set.has(path) ) {
      return true
    }

    const chunks = path.split('/')
    chunks.pop()

    return lookup(chunks.join('/'))
  }

  return lookup(pathname)
}
