import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider as ReduxProvider } from 'react-redux'

import {
  BuildVersionChecker,
  FeatureFlagsProvider,
  GoneFishin,
  ReactQueryClientProvider,
  Toaster,
  initializeSentry,
} from '@termly_web/common'

import {
  persistor,
  store,
} from 'store'

import fetchHealth from 'utils/fetchHealth'

import DashboardIntlProvider from 'components/DashboardIntlProvider'

import Router from './Router'

import './styles.scss'

// eslint-disable-next-line no-process-env
const FLAGSMITH_ENVIRONMENT_ID = process.env.FLAGSMITH_ENVIRONMENT_ID

initializeSentry({ store })


export default function App() {
  const preferredLocales = window.navigator.languages

  return (
    <FeatureFlagsProvider
      environmentID={ FLAGSMITH_ENVIRONMENT_ID }
    >
      <ReactQueryClientProvider>
        <ReduxProvider
          store={ store }
        >
          <PersistGate
            loading={ null }
            persistor={ persistor }
          >
            <DashboardIntlProvider
              preferredLocales={ preferredLocales }
            >
              <BuildVersionChecker />
              <GoneFishin
                fetchHealth={ fetchHealth }
              >
                <Router />
              </GoneFishin>
              <Toaster />
            </DashboardIntlProvider>
          </PersistGate>
        </ReduxProvider>
      </ReactQueryClientProvider>
    </FeatureFlagsProvider>
  )
}
