import React, {
  useCallback,
  useMemo,
  useRef,
} from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { AsyncSelect } from '@termly_web/common'

import Option from './Option'
import WebsiteMenu from './WebsiteMenu'

import Locale from './locale'
import Styles from './styles.scss'

import useLoadOptions from './hooks/useLoadOptions'

const COMPONENTS = {
  Option,
  Menu: WebsiteMenu,
  MenuFooter: null,
}

// For little style tweaks that don't warrant the creation of an
// entirely new component
const CUSTOM_STYLES = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'all .2s ease',
    transform: ( state.selectProps.menuIsOpen ) ? 'rotate(180deg)' : null,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#f5f6fa',
    padding: '0.125rem',
  }),
}

const MAX_LOAD_CALLS_PER_SECOND = 2
const PAGE_SIZE = 5

const NO_OPTIONS_MESSAGE = () => (
  <FormattedMessage
    { ...Locale.noMatches }
  />
)

const DEFAULT_PLACEHOLDER = (
  <FormattedMessage
    { ...Locale.search }
  />
)


// This is the very first component to use `react-select`, so I'm not
// going to try to put it into @termly_web/common, much less
// @termly/react-components. I'm very intentionally not using
// `components/Select` because that component won't do multi-selects
// or any of the cool whiz-bang things we're doing in this
// component. Eventually, I want to move the entire application off of
// `components/Select`, but that's way out of scope for the current task.
// Once we have another opportunity to work on a Select, we should see
// about generalizing some of this stuff and at least making the styling
// standardized.
//
export default function WebsiteSelect(props) {
  const {
    className,
    components,
    defaultValue,
    handleChange,
    isDisabled,
    isMulti,
    name,
    orderBy,
    placeholder = DEFAULT_PLACEHOLDER,
    styles,
    ...overrideProps
  } = props

  // We're using a ref here because we don't want or need the
  // `handlePagination` callback below to cause a re-render. That'll
  // already happen when `useLoadOptions` returns its payload.
  const menuProps = useRef({})

  const handlePagination = (pagination) => {
    menuProps.current = {
      showAllWebsites: Boolean(pagination.page_token_next),
      totalWebsites: pagination.total_size,
    }
  }

  const {
    error,
    isLoading,
    loadOptions,
  } = useLoadOptions({
    defaultValue,
    handlePagination,
    orderBy,
    pageSize: PAGE_SIZE,
    throttleRate: MAX_LOAD_CALLS_PER_SECOND,
  })

  const customStyles = useMemo(() => ({
    ...CUSTOM_STYLES,
    ...styles,
  }), [styles])

  const selectComponents = useMemo(() => ({
    ...COMPONENTS,
    ...components,
  }), [components])

  const onChange = useCallback((newValue) => {
    handleChange({
      name,
      value: newValue,
    })
  }, [handleChange, name])

  if ( error ) {
    console.debug(error)

    return (
      <div className={ Styles.errorMessage }>
        <FormattedMessage
          { ...Locale.errorMessage }
        />
      </div>
    )
  }

  const selectorClassName = classnames(className, 't-websiteSelect')

  return (
    <AsyncSelect
      defaultOptions
      className={ selectorClassName }
      components={ selectComponents }
      defaultValue={ defaultValue }
      isClearable={ isMulti }
      isDisabled={ isDisabled }
      isLoading={ isLoading }
      isMulti={ isMulti }
      loadOptions={ loadOptions }
      menuProps={ menuProps.current }
      noOptionsMessage={ NO_OPTIONS_MESSAGE }
      onChange={ onChange }
      placeholder={ placeholder }
      styles={ customStyles }
      { ...overrideProps }
    />
  )
}

const valueType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  website: PropTypes.shape({}),
})

WebsiteSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,

  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  defaultValue: PropTypes.oneOfType([
    valueType,
    PropTypes.arrayOf(valueType),
  ]),
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  orderBy: PropTypes.string,
  placeholder: PropTypes.string,
  styles: PropTypes.shape({}),
  value: PropTypes.oneOfType([
    valueType,
    PropTypes.arrayOf(valueType),
  ]),
}
