import { combineReducers } from 'redux'

import { reducer as form } from 'redux-form'

import { authenticationReducer } from 'pkg/authentication'

import ui from './ui'
import dashboard from './dashboard'
import pageview from './pageview'
import cookieReport from './cookieReport'

import elements from './elements'
import panels from './panels'
import responses from './responses'
import forms from './forms'

export default combineReducers({
  ui,
  dashboard,
  form,
  authentication: authenticationReducer,
  pageview,
  cookieReport,
  elements,
  panels,
  responses,
  forms,
})
