import Cookies from 'js-cookie'
import { datadogLogs } from '@datadog/browser-logs'

import { GtmAPI } from '@termly_web/common'

import { AuthenticationActions } from 'pkg/authentication'

import eventCallback from './eventCallback'

const GTM_EVENT_TIMEOUT = 3_000


export default function trackSignUp({ from, user }) {
  datadogLogs.logger.log(`SignUpSuccess (${ from })`, {
    cookies: Cookies.get(),
    dataLayer: window.dataLayer,
    user,
  })

  GtmAPI.push({
    event: `${ AuthenticationActions.signUpSuccess }`,
    eventCallback,

    // If the eventCallback isn't called on the uber container under this
    // time limit, the `eventCallback()` will be invoked.
    eventTimeout: GTM_EVENT_TIMEOUT,

    // It's not clear how much of the user record is *actually*
    // needed.  As of 4 September 2024, Jona indicates that the only
    // param he knows is needed is the user's e-mail, but I'm
    // reluctant to prune the payload right now because I don't want
    // to introduce too many new changes at once.
    payload: user,
  })
}
