import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import { fieldInputPropTypes } from 'redux-form'

import styles from './custom-color-input.scss'

export default class CustomColorInput extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      ...fieldInputPropTypes,
      onChange: PropTypes.func,
      value: PropTypes.string,
    }),
  }

  static defaultProps = {
    input: {},
  }

  state = { displayColorPicker: false }

  handleClick = () => this.setState((state) => ({ displayColorPicker: !state.displayColorPicker }))

  handleClose = () => this.setState(() => ({ displayColorPicker: false }))

  handleChange = ({ hex }) => {
    const { input: { onChange } } = this.props
    onChange(hex)
  }

  render() {
    const {
      input,
      input: { value },
    } = this.props
    const { displayColorPicker } = this.state

    return (
      <div className={ styles['container'] }>
        <input
          { ...input }
          hidden
          type="text"
        />
        <button
          className={ styles['button'] }
          onClick={ this.handleClick }
          type="button"
        >
          <span
            className={ styles['color-circle'] }
            style={ { background: value } }
          />
          <div className={ styles['text'] }>{ value }</div>
        </button>
        {
          displayColorPicker && (
            <div className={ styles['color-picker-wrapper'] }>
              <div
                className={ styles['dismiss-mask'] }
                onClick={ this.handleClose }
              />
              <SketchPicker
                disableAlpha
                color={ value }
                onChange={ this.handleChange }
              />
            </div>
          )
        }
      </div>
    )
  }
}
