import React, {
  useCallback,
} from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from './styles.scss'


export default function Modal(props) {
  const {
    children,
    handleClickOutside,
    show,
  } = props

  const onClick = useCallback((e) => {
    if ( !handleClickOutside ) {
      return
    }

    e.stopPropagation()

    handleClickOutside()
  }, [handleClickOutside])

  const onKeyUp = useCallback((e) => {
    if ( e.key !== 'Escape' ) {
      return
    }

    onClick(e)
  }, [onClick])

  if ( !show ) {
    return null
  }

  const containerClassName = classnames(Styles.container, 't-container')

  return (
    <div
      className={ Styles.root }
      onClick={ onClick }
      onKeyUp={ onKeyUp }
    >
      <div className={ Styles.overlay } />
      <div className={ Styles.viewport }>
        <div
          className={ containerClassName }
          onClick={ onContainerClick }
        >
          { children }
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  handleClickOutside: PropTypes.func,
  show: PropTypes.bool,
}

function onContainerClick(e) {
  e.stopPropagation()
}
