import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects'

import {
  startSubmit,
  submit,
  stopSubmit,
} from 'redux-form'

import { queryClient } from '@termly_web/common'

import history from 'store/history'

import { getCacheKey as getWebsiteCacheKey } from 'hooks/useWebsite'

import createActionCreator from 'utils/redux'
import formId from 'constants/formIds/policyMigrator'

import {
  getFormInvalidStatus,
  getRedirectPathByPolicyType,
} from 'selectors/forms/policyMigrator'

import {
  migrateDoc,
} from 'constants/api'
import api from 'utils/api'
import {
  setData as setPutMigrateData,
} from 'reducers/responses/putMigratedDocument'
import {
  setShouldOverrideDocument,
  initialize,
} from 'reducers/forms/policyMigrator'
import {
  setVisible,
} from 'reducers/elements/modals/policyMigrator'


export const HANDLE_SUBMIT = `${ __filename }/HANDLE_SUBMIT`
export const HANDLE_CANCEL_MIGRATE_POLICY = `${ __filename }/HANDLE_CANCEL_MIGRATE_POLICY`

export const handleSubmit = createActionCreator(HANDLE_SUBMIT)
export const handleCancelMigratePolicy = createActionCreator(HANDLE_CANCEL_MIGRATE_POLICY)

export function * sagaHandleSubmit({ payload }) {
  const {
    documentID,
    override,
    websiteID,
  } = payload

  try {
    yield put(startSubmit(formId.name))
    yield put(submit(formId.name))

    const isInvalid = yield select(getFormInvalidStatus)
    if (isInvalid) return

    const response = yield call(api.putData, migrateDoc(documentID), {
      document: {
        website_id: websiteID,
      },
      override,
    })

    yield put(setPutMigrateData(response))
    yield put(setVisible(false))

    const redirectPolicyPath = yield select(getRedirectPathByPolicyType)
    yield call(history.push, redirectPolicyPath)
    yield put(initialize())
  } catch (e) {
    console.error(e)

    // TODO: Wow. Seriously? No matter what kind of error that occurs
    // in the try() block, we're just going to assume that it's
    // because there's a document in the way?  Que horror!
    yield put(setShouldOverrideDocument(true))
  } finally {
    yield put(stopSubmit(formId.name))
  }
}

export function* sagaHandleCancelMigratePolicy({ payload }) {
  const {
    documentID,
    websiteID,
  } = payload

  yield call(api.deleteData, migrateDoc(documentID))

  // This will trigger react-query to refresh the website record
  // behind the scenes, so that places like the website dashboard will
  // be updated
  yield call([queryClient, queryClient.invalidateQueries], getWebsiteCacheKey(websiteID))

  yield put(setVisible(false))
}

export const watchers = {
  * handleSubmit() {
    yield takeLatest(HANDLE_SUBMIT, sagaHandleSubmit)
  },
  * handleCancelMigratePolicy() {
    yield takeLatest(HANDLE_CANCEL_MIGRATE_POLICY, sagaHandleCancelMigratePolicy)
  },
}
