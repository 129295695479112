import _ from 'lodash'

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+(\.[A-Z]{2,})$/i

const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/

export const optional = (validator) => (value) => _.isEmpty(value) ? undefined : validator(value)

export const required = (errorMessage) => (value) => !value ? errorMessage : undefined

export const email = (errorMessage) => (value) => emailPattern.test(value) ? undefined : errorMessage

export const lengthLargerThen = (errorMessage, length) => (value) => value.length > length ? undefined : errorMessage

export const lengthSmallerThen = (errorMessage, length) => (value) => value.length < length ? undefined : errorMessage

export const password = (errorMessage) => (value) => passwordPattern.test(value) ? undefined : errorMessage

export const fieldEqual = (errorMessage, comparedField) => (value, allValues) =>
  value === allValues[comparedField] ? undefined : errorMessage
