import {
  useCallback,
  useMemo,
} from 'react'

import { useAuth } from 'pkg/authentication'

import useBillingInfoQuery from 'hooks/useBillingInfoQuery'

import Policies from '../constants'

import useUserPolicyQuery from './useUserPolicyQuery'


// N.B.: this hook exists as a stop-gap measure until such time as we have a
// backend service that can do this kind of data aggregation for us.
//
export default function usePrunedUserPolicyQuery() {
  const {
    isEditor,
    user,
    refetch: refetchUser,
  } = useAuth()

  const {
    billingInfo,
    isError: isBillingInfoError,
    isLoading: isBillingInfoLoading,
    refetch: refetchBillingInfo,
  } = useBillingInfoQuery()

  const {
    isError: isUserPolicyError,
    isLoading: isUserPolicyLoading,
    userPolicy: baseUserPolicy,
    refetch: refetchUserPolicy,
    ...rest
  } = useUserPolicyQuery()

  const refetch = useCallback(() => {
    refetchUser()
    refetchUserPolicy()
    refetchBillingInfo()
  }, [refetchBillingInfo, refetchUser, refetchUserPolicy])

  const hasCardOnFile = Boolean(billingInfo.last4)

  const userPolicy = useMemo(() => {
    return augmentUserPolicy({
      hasCardOnFile,
      isEditor,
      user,
      userPolicy: baseUserPolicy,
    })
  }, [baseUserPolicy, hasCardOnFile, isEditor, user])

  return {
    userPolicy,
    isError: ( isBillingInfoError || isUserPolicyError ),
    isLoading: ( isBillingInfoLoading || isUserPolicyLoading ),
    refetch,
    ...rest,
  }
}

function augmentUserPolicy({ hasCardOnFile, isEditor, user, userPolicy }) {
  const augmentedPolicy = new Set(userPolicy)

  // Currently, there is no provision for this. We only want to hide the avatar dropdown for Duda users,
  // so it's probably not worth the time for the BE guys to create it either. Also, all other provisions
  // are "allow" or "can", so I went with this rather than adding a "HIDE_AVATAR_DROPDOWN" policy.
  augmentedPolicy.add(Policies.ALLOW_AVATAR_DROPDOWN)

  if ( user?.integration_partner || isEditor ) {
    augmentedPolicy.delete(Policies.CAN_VIEW_BILLING)
    augmentedPolicy.delete(Policies.ALLOW_COLLABORATORS)
    augmentedPolicy.delete(Policies.CAN_CREATE_WEBSITE)
  }

  if ( user?.integration_partner ) {
    augmentedPolicy.delete(Policies.ALLOW_AVATAR_DROPDOWN)
    augmentedPolicy.delete(Policies.ALLOW_WEBSITE_SETTINGS)
    augmentedPolicy.delete(Policies.ALLOW_WEBSITE_SELECTOR)
  }

  if ( !isEditor && hasCardOnFile ) {
    augmentedPolicy.add(Policies.HAS_CARD_ON_FILE)
  }

  return augmentedPolicy
}
