import { defineMessage } from 'react-intl'


export const overview = defineMessage({
  id: 'dashboard-sidebar.overview',
  defaultMessage: 'Overview',
})

export const copyrightNotice = defineMessage({
  id: 'dashboard-sidebar.copyrightNoticePolicy',
  defaultMessage: 'Copyright Notice',
})

export const accountInfo = defineMessage({
  id: 'dashboard-sidebar.accountInfo',
  defaultMessage: 'Account Info',
})

