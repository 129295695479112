import React, {
  useCallback,
} from 'react'

import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { URLBuilder } from '@termly_web/common'

import { Button } from '@termly/react-components'

import { ADD_WEBSITE_MODAL } from 'hooks/useReturnToAction'


export default function NavigateToCheckoutPreviewButton(props) {
  const {
    className,
    color,
    dataTracking,
    handleClick: handleClickProp,
    message,
  } = props

  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    handleClickProp()

    navigate(URLBuilder.checkoutPreviewURL(null, {
      licenseDelta: 1,
      returnTo: ADD_WEBSITE_MODAL,
    }))
  }, [handleClickProp, navigate])

  return (
    <Button
      className={ className }
      color={ color }
      data-tracking={ dataTracking }
      handleClick={ handleClick }
    >
      { message }
    </Button>
  )
}

NavigateToCheckoutPreviewButton.propTypes = {
  className: Button.propTypes.className,
  color: Button.propTypes.color,
  dataTracking: PropTypes.string,
  handleClick: Button.propTypes.handleClick,
  message: PropTypes.shape({
    defaultMessage: PropTypes.string,
    id: PropTypes.string,
  }),
}
