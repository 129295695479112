import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'

import Styles from './styles.scss'


/*
 * Decorates its children with visual indicators that the URL supplied
 * in the `url` prop is identical to `location.pathname`. If the
 * url and the pathname do not match, it serves to maintain indenting
 * consistency across all similarly-decorated links in the sidebar
 * (i.e., it always adds a couple of pixels of border on the left so
 * that things always line up).
 *
 * @returns {Node}
 */
export default function SidebarLink({ children, className, url }) {
  const { pathname } = useLocation()

  const isActive = ( url === pathname )
  const rootClassName = classnames(Styles.root, className, {
    [Styles.active]: isActive,
  })

  return (
    <div className={ rootClassName }>
      { children }
    </div>
  )
}

SidebarLink.propTypes = {
  children: PropTypes.node.isRequired,

  className: PropTypes.string,
  url: PropTypes.string,
}
