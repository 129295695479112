import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PRICING_PAGE_PARAMS,
  useUpgrade,
} from '@termly_web/common'

import Paths from 'constants/paths'

import useShowNotificationBanner from '../../hooks/useShowNotificationBanner'

import Locale from './locale'

const CONFIG = {
  overConsentQuota: {
    alertMessage: Locale.notificationConsentCount,
    buttonMessage: Locale.learnMore,
    queryParam: PRICING_PAGE_PARAMS.UPGRADE_REACH_LIMITATION,
  },
  overPolicyLimit: {
    alertMessage: Locale.notificationPolicyLimit,
    buttonMessage: Locale.learnMore,
    queryParam: PRICING_PAGE_PARAMS.UPGRADE_FREEMIUM_WARNING,
  },
  pastDue: {
    alertMessage: Locale.subscriptionPastDue,
    buttonMessage: Locale.updateNow,
    path: Paths.BILLING,
  },
}


export default function useNotificationBannerConfig() {
  const navigate = useNavigate()
  const { upgrade } = useUpgrade()

  const {
    isError,
    isLoading,
    isOverConsentQuota,
    isSubscriptionPastDue,
  } = useShowNotificationBanner()

  const config = getConfig({
    isOverConsentQuota,
    isSubscriptionPastDue,
  })

  const handleRedirect = useCallback(() => {
    if ( config.path ) {
      navigate(config.path)
      return
    }

    upgrade({
      queryParam: config.queryParam,
    })
  }, [config, navigate, upgrade])

  return {
    alertMessage: config.alertMessage,
    buttonMessage: config.buttonMessage,
    errorMessage: ( isError ) ? Locale.errorMessage : void 0,
    handleRedirect,
    isError,
    isLoading,
  }
}

function getConfig({ isOverConsentQuota, isSubscriptionPastDue }) {
  if ( isSubscriptionPastDue ) {
    return CONFIG.pastDue
  }

  const key = ( isOverConsentQuota ) ? 'overConsentQuota' : 'overPolicyLimit'

  return CONFIG[key]
}
