export default function makeTrackerAPI({ observers }) {
  // N.B.: The memoization keeps us from hammering the observers
  // with duplicate requests. It's easier to do it here than out
  // where the user tracker is actually invoked.
  return {
    signIn({ hasWebsite, user }) {
      observers.forEach((api) => api.signIn({
        hasWebsite,
        user,
      }))
    },

    signOut() {
      observers.forEach((api) => api.signOut())
    },

    trackEvent(event) {
      observers.forEach((api) => api.trackEvent(event))
    },

    updateUser(attrs) {
      observers.forEach((api) => api.updateUser(attrs))
    },
  }
}
