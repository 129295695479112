import createActionCreator from 'utils/redux'

export const UPDATE_IS_COPIED = `${ __filename }/UPDATE_IS_COPIED`
export const UPDATE_PIN_NUMBER = `${ __filename }/UPDATE_PIN_NUMBER`

export const updateIsCopied = createActionCreator(UPDATE_IS_COPIED)
export const updatePinNumber = createActionCreator(UPDATE_PIN_NUMBER)

export const initState = {
  isCopied: false,
  pinNumber: '',
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_IS_COPIED:
      return {
        ...state,
        isCopied: payload,
      }
    case UPDATE_PIN_NUMBER:
      return {
        ...state,
        pinNumber: payload,
      }
    default:
      return state
  }
}
