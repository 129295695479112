// NOT CURRENTLY USED
export const UPGRADE_SETTINGS = 'settings'
export const UPGRADE_MORE_THEME = 'more_theme'
export const UPGRADE_MORE_THEME_COOKIE = 'more_theme_cookie'
export const UPGRADE_REMOVE_LOGO = 'remove_logo'
export const UPGRADE_EXPORT_CONSENT = 'export_consent'
export const UPGRADE_AUTOMATIC_SCANS = 'automatic_scans'
export const UPGRADE_SET_SUBDOMAIN = 'set_subdomain'
export const UPGRADE_GET_API_KEY = 'get_api_key'

// IN USE
export const UPGRADE_FREEMIUM_WARNING = 'freemiumWarning'
export const UPGRADE_REACH_LIMITATION = 'reachLimitation'
export const UPGRADE_EDIT = 'edit'
export const ADD_WEBSITE = 'add_website'
export const SECOND_POLICY = 'second_policy'
