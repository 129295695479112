import { useAuth } from 'pkg/authentication'

import useGetQuery from 'hooks/useGetQuery'

const GET_URI = 'v1/user/provisions'
const QUERY_NAME = 'userPolicy'

/**
 * @typedef {Object} UserPolicyQuery
 * @property {Object[]} provisions
 */

/**
 * @param {Object} options - see "Options" at https://react-query.tanstack.com/reference/useQuery
 *
 * @returns {UserPolicyQuery} also includes the response detailed at https://react-query.tanstack.com/reference/useQuery
 */
export default function useUserPolicyQuery(options) {
  const { user } = useAuth()

  const {
    responseData,
    ...rest
  } = useGetQuery({
    endpoint: GET_URI,
    queryKey: [QUERY_NAME, user.id],
  }, {
    enabled: Boolean(user.id),
    ...options,
  })

  // In other policies/provisions we may care about a 'count', or some other
  // non-boolean value, but all we care about here is the existence of `key`.
  // Examples of `key` being: 'canViewBilling', 'canCreateWebsite', etc.
  const userPolicy = new Set(responseData?.provisions?.map(({ key }) => key))

  return {
    ...rest,
    userPolicy,
  }
}
