import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from '@termly/react-components'

import { useUpgrade } from '@termly_web/common'

import Locale from './locale'
import Styles from './styles.scss'

import StarIcon from './img/star-icon.svg'


export default function UpgradeButton() {
  const {
    isUpgradable,
    upgrade,
  } = useUpgrade()

  if ( !isUpgradable ) {
    return null
  }

  return (
    <Button
      className={ Styles.root }
      handleClick={ upgrade }
      type="text"
    >
      <img
        alt=""
        src={ StarIcon }
      />
      <FormattedMessage
        { ...Locale.buttonText }
      />
    </Button>
  )
}
