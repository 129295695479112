import { createSelector } from 'reselect'

export const selector = (state) => state.responses.putMigratedDocument

export const getMigratedWebsiteId = createSelector(
  selector,
  ({ website_uuid }) => website_uuid,
)

export const getMigratedPolicyType = createSelector(
  selector,
  ( { template_type } ) => template_type
)
