// Probably not the best place for this constant, but it suits our purposes
// for right now.
const MAX_ARRAY_LENGTH = 5


/**
 * @typedef {Object} WebsiteSelectWebsite
 * @property {String} uuid
 */

/**
 * @typedef {Object} WebsiteSelectValue
 * @property {String} value - the UUID of the website
 * @property {WebsiteSelectWebsite} website - the full website record
 */

/**
 * If `addValueToOptions` is true, adds `value.website` to the head of a
 * copy of the `websites` array, and truncates the resulting array (if needed)
 * to a maximum length of 5.
 *
 * @param {Object} args
 * @param {Boolean} args.addValueToOptions
 * @param {WebsiteSelectValue} args.value
 * @param {WebsiteSelectWebsite[]} args.websites
 *
 * @returns {WebsiteSelectWebsite[]}
 */
export default function buildWebsites({ addValueToOptions, value, websites }) {
  if ( !addValueToOptions || !value ) {
    return websites
  }

  const alreadyHasValue = websites.some(({ uuid }) => {
    return ( value.value === uuid )
  })

  const resultArray = ( alreadyHasValue ) ? websites : [
    value.website,
    ...websites,
  ]

  // We truncate in all cases because we're paranoid. This function
  // has no control over the number of websites supplied to it, so
  // just in case the caller got a little over-zealous, we'll make
  // sure that we're consistent in our return value.
  return truncateArray(resultArray, MAX_ARRAY_LENGTH)
}

function truncateArray(array, maxLength) {
  if ( array.length <= maxLength ) {
    return array
  }

  return array.slice(0, maxLength)
}
