export default {
  name: 'themeEditor',
  fields: {
    body: {
      style: {
        background: 'body.style.background',
      },
    },
    title: {
      style: {
        fontFamily: 'title.style.font-family',
        fontSize: 'title.style.font-size',
        color: 'title.style.color',
      },
    },
    subtitle: {
      style: {
        fontFamily: 'subtitle.style.font-family',
        fontSize: 'subtitle.style.font-size',
        color: 'subtitle.style.color',
      },
    },
    heading_1: {
      style: {
        fontFamily: 'heading_1.style.font-family',
        fontSize: 'heading_1.style.font-size',
        color: 'heading_1.style.color',
      },
    },
    heading_2: {
      style: {
        fontFamily: 'heading_2.style.font-family',
        fontSize: 'heading_2.style.font-size',
        color: 'heading_2.style.color',
      },
    },
    body_text: {
      style: {
        fontFamily: 'body_text.style.font-family',
        fontSize: 'body_text.style.font-size',
        color: 'body_text.style.color',
      },
    },
    link: {
      style: {
        fontFamily: 'link.style.font-family',
        fontSize: 'link.style.font-size',
        color: 'link.style.color',
      },
    },
  },
}
