import { defineMessage } from 'react-intl'


export const create = defineMessage({
  id: 'theme-saver.create',
  defaultMessage: 'Save Theme',
})

export const update = defineMessage({
  id: 'theme-saver.update',
  defaultMessage: 'Update Theme',
})

export const themeName = defineMessage({
  id: 'theme-saver.theme-name',
  defaultMessage: 'Theme Name',
})

export const cancel = defineMessage({
  id: 'theme-saver.cancel',
  defaultMessage: 'Cancel',
})

export const save = defineMessage({
  id: 'theme-saver.save',
  defaultMessage: 'SAVE',
})

export const enterThemeName = defineMessage({
  id: 'theme-saver.enter-theme-name',
  defaultMessage: 'Enter Theme Name',
})

export const duplicateThemeName = defineMessage({
  id: 'theme-saver.duplicate-theme-name',
  defaultMessage: 'Already in use',
})
