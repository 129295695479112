import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'
import { ToastBar } from 'react-hot-toast'

import './styles.scss'


export default function Toast({ toast }) {
  const style = useMemo(() => {
    if ( !toast.visible ) {
      return
    }

    return {
      animation: 'custom-toast-entry 0.5s ease',
    }
  }, [toast.visible])

  return (
    <ToastBar
      style={ style }
      toast={ toast }
    >
      {
        // One day, we might use the icon...but not today. Leaving
        // in place as documentation.
        ({ icon: _icon, message }) => (
          <>
            { message }
          </>
        )
      }
    </ToastBar>
  )
}

Toast.propTypes = {
  toast: PropTypes.shape({
    visible: PropTypes.bool,
  })
}

Toast.defaultProps = {
  toast: {},
}
