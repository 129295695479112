import {
  createContext,
  useContext,
} from 'react'


/**
 * Creates a custom context with an optional default context value,
 * and returns the new context and the corresponding 'use' function.
 *
 * @example
 *    const [MyContext, useMyContext] = makeContext('MyContext')
 *
 * @param {String} name - the name of the context to be created
 * @param {Object} [defaultContextValue]
 *
 * @returns {[Context, Function]}
 */
export function makeContext(name, defaultContextValue) {
  if ( !name ) {
    throw new Error('no name provided')
  }

  const customContext = createContext(defaultContextValue)
  customContext.displayName = name

  // eslint-disable-next-line func-style
  const useCustomContext = () => {
    const context = useContext(customContext)

    // eslint-disable-next-line eqeqeq
    if ( context == null ) {
      throw new Error(`No ${ name } context could be found`)
    }

    return context
  }

  return [customContext, useCustomContext]
}
