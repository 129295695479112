import { useQuery } from '@tanstack/react-query'

import { useAuth } from 'pkg/authentication'

import Client from 'utils/client'

import getCacheKey from './getCacheKey'

const GET_URI = 'v1/plans'

/**
 * @typedef {Object} PlansQuery
 * @property {Object} plans
 * @property {Object[]} plans.active_plans
 * @property {Object} plans.current_plan
 * @property {Object} plans.plans
 */

/**
 * @param {Object} options - see "Options" at https://react-query.tanstack.com/reference/useQuery
 *
 * @returns {PlansQuery} also includes the response detailed at https://react-query.tanstack.com/reference/useQuery
 */
export default function usePlansQuery(options) {
  const { user } = useAuth()

  const {
    data = {},
    ...rest
  } = useQuery(getCacheKey(user.id),
               () => Client.get(GET_URI),
               {
                 ...options,
                 enabled: Boolean(user.id),
               })

  const responseData = data?.data || {}

  return {
    ...rest,
    activePlans: responseData.active_plans,
    currencySymbol: responseData.currency_symbol,
    currentUserPlan: responseData.current_plan,
  }
}
