import _ from 'lodash'
import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  startSubmit,
  stopSubmit,
  submit as formSubmit,
} from 'redux-form'

import history from 'store/history'

import paths from 'constants/paths'
import businessInfoFormIds from 'constants/formIds/businessInfo'

import { getCookiePolicyId } from 'selectors/responses/website'
import { getSyncErrors } from 'selectors/forms/businessInfo'
import { getBusinessInfoAsRequestBody } from 'selectors/forms/dashboardBusinessInfo'
import { getWebsiteIdFromParam } from 'utils/selectors'
import { setData as setWebsiteData } from 'reducers/responses/website'
import createActionCreator from 'utils/redux'
import api from 'utils/api'
import {
  website,
  publishCookiePolicy,
} from 'constants/api'

export const HANDLE_DID_MOUNT = `${ __filename }/HANDLE_DID_MOUNT`
export const SUBMIT           = `${ __filename }/SUBMIT`

export const handleDidMount = createActionCreator(HANDLE_DID_MOUNT)
export const submit         = createActionCreator(SUBMIT)

export function * sagaHandleDidMount() {
  try {
    const websiteId = yield select(getWebsiteIdFromParam)
    const result    = yield call(api.getData, website(websiteId))
    yield put(setWebsiteData(result))
  } catch (e) {
    console.error(e)
  }
}

export function * sagaSubmit() {
  try {
    yield put(startSubmit(businessInfoFormIds.name))
    yield put(formSubmit(businessInfoFormIds.name))
    const error = yield select(getSyncErrors)
    if (_.isEmpty(error)) {
      const payload        = yield select(getBusinessInfoAsRequestBody)
      const websiteId      = yield select(getWebsiteIdFromParam)
      const cookiePolicyId = yield select(getCookiePolicyId)
      yield call(api.putData, website(websiteId), payload)
      if (cookiePolicyId) {
        yield call(api.putData, publishCookiePolicy(cookiePolicyId))
      }
      yield call(history.push, paths.WEBSITE_DASHBOARD(websiteId))
    }
  } catch (e) {
    console.error(e)
  } finally {
    yield put(stopSubmit(businessInfoFormIds.name))
  }
}

export const watchers = {
  * handleDidMount() {
    yield takeLatest(HANDLE_DID_MOUNT, sagaHandleDidMount)
  },
  * submit() {
    yield takeLatest(SUBMIT, sagaSubmit)
  },
}
