import { useQuery } from '@tanstack/react-query'

const CACHE_KEY = ['goneFishin']
const QUERY_CONFIG = {
  retry: false,
}


export default function useGoneFishin(fetchHealth) {
  const {
    isLoading,
    error,
    data = {},
  } = useQuery(CACHE_KEY, fetchHealth, QUERY_CONFIG)

  return {
    error,
    isLoading: Boolean(isLoading),
    isGoneFishin: Boolean(data.maintenance),
  }
}
