import { createAction } from '@reduxjs/toolkit'

/**
 * Inspired by [redux-namespaced-actions](https://github.com/skleeschulte/redux-namespaced-actions#readme) which seems to have been abandoned.
 *
 * @param {String} namespace
 *
 * @returns {Function} see Inner Function
 */
export default function createNamespacedAction(namespace) {
  if ( !namespace ) {
    throw new Error('No namespace supplied to createNamespacedAction()')
  }

  /**
   * Inner function
   *
   * @param {String} action
   *
   * @returns {String} of the form "namespace/action"
   */
  return (action) => {
    if ( !action ) {
      throw new Error('No action was provided to namespaced action creator')
    }

    return createAction([namespace, action].join('/'))
  }
}
