import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import ReactQueryStateHandler from 'components/ReactQueryStateHandler'

import UserPolicyContext from './UserPolicyContext'

import usePrunedUserPolicyQuery from './hooks/usePrunedUserPolicyQuery'


export default function UserPolicyProvider({ children }) {
  const {
    isError,
    isLoading,
    refetch,
    userPolicy,
  } = usePrunedUserPolicyQuery()

  const value = useMemo(() => ({
    refetch,
    userPolicy,
  }), [refetch, userPolicy])

  if ( isError || isLoading ) {
    return (
      <ReactQueryStateHandler
        isError={ isError }
        isLoading={ isLoading }
      />
    )
  }

  return (
    <UserPolicyContext.Provider
      value={ value }
    >
      { children }
    </UserPolicyContext.Provider>
  )
}

UserPolicyProvider.propTypes = {
  children: PropTypes.node,
}
