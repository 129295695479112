import React from 'react'
import PropTypes from 'prop-types'

import { CurrentWebsiteIDContext } from './currentWebsiteIDContext'


export default function CurrentWebsiteIDProvider({ children, websiteID }) {
  return (
    <CurrentWebsiteIDContext.Provider value={ websiteID }>
      { children }
    </CurrentWebsiteIDContext.Provider>
  )
}

CurrentWebsiteIDProvider.propTypes = {
  children: PropTypes.element,
  websiteID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
}
