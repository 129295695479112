import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from './styles.scss'
import Logo from './img/logo.svg'

const ALT = 'Termly'
const DEFAULT_HREF = '/'
const LOGO_CLASS_NAME = `${ Styles.logo } t-logoLink`


export default function TermlyLogo({ className, href }) {
  const logoClassName = classnames(LOGO_CLASS_NAME, className)

  const img = (
    <img
      alt={ ALT }
      className={ logoClassName }
      src={ Logo }
    />
  )

  if ( !href ) {
    return img
  }

  return (
    <a href={ href }>
      { img }
    </a>
  )
}

TermlyLogo.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
}

TermlyLogo.defaultProps = {
  href: DEFAULT_HREF,
}
