import useBillingInfoQuery from 'hooks/useBillingInfoQuery'


export default function useAdditionalWebsiteDetailsQuery() {
  const billingInfoQuery = useBillingInfoQuery()

  const { billingInfo } = billingInfoQuery

  // We subtract 1 here so we don't include the initial license
  const additionalLicenseCount = billingInfo?.website_allowed - 1

  // TODO: We really should use filter() + reduce() to build the actual futureAdditionalLicenseCount,
  // but it's not clear at the moment what it would mean to have multiple `{ type: 'website' }`,
  // and whether the `future_quantity` numbers would be additive.
  const websiteFeature = billingInfo?.features?.find(({ type }) => type === 'website')

  const numOfCancelledLicenses = getNumOfCancelledLicenses({
    additionalLicenseCount,
    websiteFeature,
  })

  return {
    additionalLicenseCount,
    numOfCancelledLicenses,

    currentPeriodEnd: billingInfo?.current_period_end,
    // TODO: update `isCancelled` => `isCancellationScheduled`
    //
    // `scheduled_for_cancellation_on` is the date the user clicked
    // 'cancel my plan.' For the most part, we don't care what the date is,
    // we only care whether there is a value there or not.
    //
    // It should really be `isCancellationScheduled: Boolean(billingInfo?.scheduled_for_cancellation_on)`
    isCancelled: billingInfo?.scheduled_for_cancellation_on,
    subscriptionStatus: billingInfo?.subscription_status,

    error: billingInfoQuery?.error,
    isError: billingInfoQuery?.isError,
    isLoading: billingInfoQuery?.isLoading,
    refetch: billingInfoQuery?.refetch,
  }
}

function getNumOfCancelledLicenses({ additionalLicenseCount, websiteFeature }) {
  // `websiteFeature.futureQuantity` is the # of additional (emphasis on 'additional', not total) licenses
  // that a user's account will have when the next billing cycle begins. If the user currently has 4 licenses
  // and removes 2 through <SubscriptionManager />, they would have a total of 2 licenses and this would be === 1.
  //
  // Unfortunately, we can't just do `!websiteFeature?.future_quantity` here because
  // that is true for both `null/undefined` and when this is === 0, and we need
  // different behavior for those two scenarios.
  //
  // eslint-disable-next-line eqeqeq
  if ( websiteFeature?.future_quantity == null ) {
    return 0
  }

  // If `websiteFeature.futureQuantity` === 0, that means the user has removed all of their additional
  // licenses and only their main license remains. In order to get rid of their main license, the user would
  // need to cancel their plan entirely.
  if ( websiteFeature.future_quantity === 0 ) {
    return additionalLicenseCount
  }

  return ( additionalLicenseCount - websiteFeature.future_quantity )
}
