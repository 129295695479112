import { useQuery } from '@tanstack/react-query'

import Client from 'utils/client'

import getCacheKey from './getCacheKey'

const GET_URI = 'v1/billing_info'

/**
 * @typedef  {Object}  BillingInfoQuery
 * @property {Object}  billingInfo
 * @property {Object}  billingInfo.credit_card
 * @property {String}  billingInfo.current_period_end
 * @property {String}  billingInfo.current_period_start
 * @property {Array}   billingInfo.features
 * @property {Number}  billingInfo.language_allowed
 * @property {String}  billingInfo.last4
 * @property {Boolean} billingInfo.legacy_plan
 * @property {Number}  billingInfo.page_view_start_date
 * @property {String}  billingInfo.plan_description
 * @property {Number}  billingInfo.pricing
 * @property {Object}  billingInfo.renew_failed_info
 * @property {Number}  billingInfo.renewal_amount
 * @property {String}  billingInfo.scheduled_for_cancellation_on
 * @property {String}  billingInfo.subscription_status
 * @property {Number}  billingInfo.total_license_count
 * @property {Number}  billingInfo.used_license_count
 * @property {String}  billingInfo.uuid
 * @property {Number}  billingInfo.website_allowed
 */

// We use a static value here so that we don't cause an unnecessary,
// and potentially harmful, rerender while we're waiting for results
// to come back from the server.
const DEFAULT_BILLING_INFO = {}


/**
 * @param {Object} options - see "Options" at https://react-query.tanstack.com/reference/useQuery
 *
 * @returns {BillingInfoQuery} also includes the response detailed at https://react-query.tanstack.com/reference/useQuery
 */
export default function useBillingInfoQuery(options) {
  const {
    data = {},
    ...rest
  } = useQuery(getCacheKey(),
               () => Client.get(GET_URI),
               {
                 // We don't want this because the API has a
                 // Cache-Control header that forbids caching, so
                 // every time we click away and then back on the
                 // window, we'd refetch the record, which causes a
                 // brand new object to be returned, which in turns
                 // causes potentially-harmful re-rendering of parts
                 // of the application.
                 refetchOnWindowFocus: false,
                 ...options,
               })

  const billingInfo = data?.data || DEFAULT_BILLING_INFO

  return {
    ...rest,
    billingInfo,
  }
}
