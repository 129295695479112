import React from 'react'
import PropTypes from 'prop-types'

import TopLevelLink from '../TopLevelLink'
import SidebarLink from '../SidebarLink'

import Locale from './locale'

import DASHBOARD_ICON from './img/dashboard-icon.svg'

/**
 * This component renders a link that _looks_ like a TopLevelLink,
 * but rather than controlling the expansion/collapse of a section,
 * it is actually a link to the Website Dashboard page.
 *
 * @returns {Node}
 */
export default function WebsiteDashboardLink({ handleClick, url }) {
  // The SidebarLink uses `url` to determine whether the
  // TopLevelLink should be decorated or not--it does not actually
  // handle the click (that's the job of TopLevelLink).
  return (
    <SidebarLink
      url={ url }
    >
      <TopLevelLink
        handleClick={ handleClick }
        hasCarat={ false }
        icon={ DASHBOARD_ICON }
        id="dashboard"
        name={ Locale.dashboard }
      />
    </SidebarLink>
  )
}

WebsiteDashboardLink.propTypes = {
  handleClick: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
}
