import { defineMessages } from 'react-intl'


export default defineMessages({
  doh: {
    id: 'lazy-load-failed.doh',
    defaultMessage: 'Termly encountered an error.',
  },

  otraVez: {
    defaultMessage: 'Please try again, or <link>contact us</link> if the issue persists.',
    id: 'lazy-load-failed.otra-vez',
  },
})
