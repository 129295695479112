import { PolicyTypes } from '@termly_web/common'

import Paths from 'constants/paths'

export function makePoliciesLinks({ currentWebsiteID }) {
  const makeLink = createPolicyLinkMaker(currentWebsiteID)

  return PolicyTypes.getAllTypesByPriority().map(makeLink)
}

function createPolicyLinkMaker(currentWebsiteID) {
  return (policyType) => ({
    id: PolicyTypes.getID(policyType),
    name: PolicyTypes.getNameMessage(policyType),
    url: Paths.POLICY_URL({
      id: currentWebsiteID,
      policyType,
    }),
  })
}
