import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import classnames from 'classnames'

import Styles from './styles.scss'

// This component is intended to be a _very_ thin wrapper around
// ReactSelect so that we can have a consistent look and feel
// across our UIs.
//
// Please do not add anything to this file unless it's a
// feature or style that needs to be present across all applications.
// If it's only used in one client, consider creating an application-
// specific wrapper instead.
//
export default function Select({ ReactSelectComponent = ReactSelect, className, ...props }) {
  const classNames = useMemo(() => ({
    dropdownIndicator({ selectProps }) {
      return classnames(Styles.dropdownIndicator, {
        [Styles.isOpen]: selectProps.menuIsOpen,
      })
    },
    option({ isDisabled, isFocused, isSelected }) {
      return classnames(Styles.option, {
        [Styles.isFocused]: isFocused,
        [Styles.isSelected]: isSelected,
        [Styles.isDisabled]: isDisabled,
      })
    },
    // It's not clear to me, at the moment, how I should handle
    // the case where `props.classNames` has an `option` member
    // since I don't have a need for it, currently. For the
    // meantime, then, I'll just treat a provided `option` as an
    // override of the built-in.
    ...props.classNames,
  }), [props.classNames])

  const rootClassName = classnames(Styles.root, className)

  return (
    <ReactSelectComponent
      { ...props }
      className={ rootClassName }
      classNames={ classNames }
    />
  )
}

Select.propTypes = {
  ReactSelectComponent: PropTypes.elementType,
  className: PropTypes.string,
  classNames: PropTypes.objectOf(PropTypes.func),
}
