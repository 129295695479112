import { put, takeLatest } from 'redux-saga/effects'
import { initialize } from 'redux-form'

import createActionCreator from 'utils/redux'
import embedPreferenceCenterButton from 'constants/formIds/embedPreferenceCenterButton'

export const HANDLE_DID_MOUNT = `${ __filename }/HANDLE_DID_MOUNT`

export const handleDidMount = createActionCreator(HANDLE_DID_MOUNT)

export function * sagaHandleDidMount() {
  // WIP
  // check if this website has cookie policy.
  // if no then redirect the user to "scan your website."
  yield put(initialize(embedPreferenceCenterButton.name, {
    [embedPreferenceCenterButton.fields.allowPreferenceInEU]: 'Yes',
  }))
}

export const watchers = {
  * handleDidMount() {
    yield takeLatest(HANDLE_DID_MOUNT, sagaHandleDidMount)
  },
}
