import { createSelector } from 'reselect'
import URI from 'urijs'

import { extractWebsiteId } from 'utils/urlParser'


export const getWebsiteNotificationTabIndex = (state) => state.websiteNotification.tabIndex

export function getBilling(state) {
  return state.billing
}

export function getDashboard(state) {
  return state.dashboard
}

export function getWebsiteNotification(state) {
  return state.websiteNotification
}

export function getDetectSnippetResult(state) {
  return state.websiteNotification.snippetDetection.success
}

// TODO: kill
export function getQueryParams(_state) {
  return new URI(window.location.search).query(true)
}

export function getUi(state) {
  return state.ui
}

export function getPageview(state) {
  return state.pageview
}

export function getCookieReport(state) {
  return state.cookieReport
}

export function getCurrencySymbol(state) {
  return state.plan.currency_symbol
}

export function getCurrentPlan(state) {
  return state.plan.current_plan
}

function getRouting(state) {
  return state.routing
}

// reselect starts here
const parseInteger = (num) => {
  const parsed = parseInt(num, 10)
  return isNaN(parsed) ? null : parsed
}

// routing
export const routingSelector = (state) => state.routing.locationBeforeTransitions

export const getPreviousLocation = createSelector(
  getRouting,
  (routing) => routing && routing.previousLocation
)

export const getOriginalTargetLocation = createSelector(
  getRouting,
  (routing) => routing && routing.originalTargetLocation
)

// TODO: This should probably be killed
export const getPathname = () => window.location.pathname

export const getWebsiteIdFromParam = createSelector(
  getPathname,
  (pathname) => extractWebsiteId(pathname)
)

export const getCookieReportIdFromParam = createSelector(
  getPathname,
  (pathname) => {
    // TODO: if we make the regex group more permissive - (.+?) vs (\d+) -
    //  should we remove the call to parseInteger and just let it flow as a
    //  string?
    const id = (
      /\/cookie-report\/(\d+)\/edit-cookie/.exec(pathname) ||
      /\/cookie-report\/(\d+)\/add-cookie/.exec(pathname) ||
      []
    )[1]
    return parseInteger(id)
  }
)

export const getCookieIdFromParam = createSelector(
  getPathname,
  (pathname) => {
    // TODO: if we make the regex group more permissive - ([^/]+) vs (\d+) -
    //  should we remove the call to parseInteger and just let it flow as a
    //  string?
    const id = (
      /\/edit-cookie\/(\d+)/.exec(pathname) ||
      []
    )[1]
    return parseInteger(id)
  }
)

export const getReceiptIdFromParam = createSelector(
  getPathname,
  (pathname) => {
    const id = (
      /\/receipts\/(\w*)/.exec(pathname) ||
      []
    )[1]
    return id
  }
)

export const getClassifiedCookies = createSelector(
  getCookieReport,
  (report) => {
    return report.classifiedCookies
  },
)

export const getBillingFeatures = createSelector(
  getBilling,
  (billing) => billing && billing.features
)

export const getHasCreditCardOnFile = createSelector(
  getBilling,
  (billing) => Boolean(billing.credit_card.last4)
)

const getWebsiteAllowed = createSelector(
  getBilling,
  (billing) => billing && billing.website_allowed
)

export const getCurrentPeriodEnd = createSelector(
  getBilling,
  (billing) => billing && billing.current_period_end
)

export const getCancelSchedule = createSelector(
  getBilling,
  (billing) => billing && billing.scheduled_for_cancellation_on
)

const getRenewFailedInfo = createSelector(
  getBilling,
  (billing) => billing && billing.renew_failed_info
)

export const getWebsiteAmount = createSelector(
  getRenewFailedInfo,
  (renewFailedInfo) => renewFailedInfo.website_amount
)

const getRenewFailedPlan = createSelector(
  getRenewFailedInfo,
  (renewFailedInfo) => renewFailedInfo && renewFailedInfo.plan
)

export const getRenewFailedPlanId = createSelector(
  getRenewFailedPlan,
  (renewFailedPlan) => renewFailedPlan && renewFailedPlan.id
)

export const isCanceled =  createSelector(
  getCancelSchedule,
  (cancelSchdule) => Boolean(cancelSchdule)
)

export const getHasPendingDowngrades = createSelector(
  getBillingFeatures,
  // eslint-disable-next-line eqeqeq
  (features) => features.some((feature) => feature.future_quantity != null)
)

export const showDiscardDialog = getHasPendingDowngrades

export const showDiscardDialogXXX = createSelector(
  getBillingFeatures,
  (billingFeatures) => billingFeatures && billingFeatures.filter((feature) => feature.future_quantity !== null).length > 0
)

// NOTE: Is there ever an instance where a `default_quantity` isn't 1? This seems silly.
export const getDefaultQuantityWebsite = createSelector(
  getBillingFeatures,
  (billingFeatures) =>
    billingFeatures
    && billingFeatures.filter(({ type }) => type.includes('website'))[0]
    && billingFeatures.filter(({ type }) => type.includes('website'))[0].default_quantity
)

// The future quantity is the quota that will be enforced at the
// end of the current billing cycle...it gives the user some time
// to clean things up should they remove licenses.
export const getFutureQuantityWebsite = createSelector(
  getBillingFeatures,
  (billingFeatures) =>
    billingFeatures
    && billingFeatures.filter(({ type }) => type.includes('website'))[0]
    && billingFeatures.filter(({ type }) => type.includes('website'))[0].future_quantity
)

// NOTE: This is poorly/ambiguously named.
// 'AvailableLicense' here is snonymous with # of additionalLicenses.
// It has nothing to do with the # of licenses a user has in use / a website assigned to.
export const getAvailableLicense = createSelector(
  getWebsiteAllowed,
  getDefaultQuantityWebsite,
  (websiteAllowed, defaultQuantity) => websiteAllowed && defaultQuantity && websiteAllowed - defaultQuantity
)

export const getNumOfCanceledLicense = createSelector(
  getAvailableLicense,
  getFutureQuantityWebsite,
  (availableLicense, futureQuantity) => availableLicense && (futureQuantity ? availableLicense - futureQuantity : availableLicense)
)

export const getWebsiteQuery = createSelector(
  getQueryParams,
  ({ website }) => website,
)

export const getRegionQuery = createSelector(
  getQueryParams,
  ({ region }) => region,
)

export const getWPCookieConsentManagerUrl = createSelector(
  getRegionQuery,
  (region) =>
    region === 'fr' ? 'https://termly.io/fr/produits/gestionnaire-de-cookies/' :
      region === 'uk' ? 'https://termly.io/en/products/cookie-consent-manager/' :
        'https://termly.io/products/cookie-consent-manager/'
)
