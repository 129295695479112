import { createSelector } from 'reselect'
import {
  isSubmitting,
  getFormValues as getReduxFormValues,
  isInvalid,
} from 'redux-form'

import { PolicyTypes } from '@termly_web/common'

import formId from 'constants/formIds/policyMigrator'
import Paths from 'constants/paths'

import {
  getWebsiteIds,
  getWebsiteNames,
  getMigrated,
} from 'selectors/responses/websites'
import {
  getMigratedPolicyType,
  getMigratedWebsiteId,
} from 'selectors/responses/putMigratedDocument'

export const selector = (state) => state.forms.policyMigrator

export const getFormValues = getReduxFormValues(formId.name)
export const getFormInvalidStatus = isInvalid(formId.name)
export const getIsSubmitting = isSubmitting(formId.name)

export const getMigratedDocument = createSelector(
  getMigrated,
  (migratedPolicies) => migratedPolicies[0]
)

export const getWebsiteId = createSelector(
  getFormValues,
  (formValues = {}) => {
    return Number(formValues[formId.fields.websiteId])
  }
)

export const getRedirectPathByPolicyType = createSelector(
  getMigratedPolicyType,
  getMigratedWebsiteId,
  (policyType, websiteId) => {
    if ( !PolicyTypes.hasPolicyMigratorFunction(policyType) ) {
      return
    }

    return Paths.POLICY_URL({
      id: websiteId,
      policyType,
    })
  }
)

export const getDocumentName = createSelector(
  getMigrated,
  (migratedPolicies) => {
    return migratedPolicies[0].name
  }
)

export const getShouldOverrideDocument = createSelector(
  selector,
  ({ shouldOverrideDocument }) => shouldOverrideDocument,
)

export const getFormData = createSelector(
  getWebsiteId,
  getShouldOverrideDocument,
  (websiteId, shouldOverrideDocument) => ({
    document: {
      website_id: websiteId,
    },
    override: shouldOverrideDocument,
  }),
)

export const getWebsiteSelectorOptions = createSelector(
  getWebsiteIds,
  getWebsiteNames,
  (websiteIds, websiteNames) => ({
    options: websiteNames,
    ids: websiteIds,
  }),
)
