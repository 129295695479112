export default function configureSentryScope(store) {
  return (scope) => {
    scope.addEventProcessor((event, _hint) => ({
      ...event,
      contexts: {
        ...event.contexts,
        'Redux Store': store.getState(),
      },
    }))
  }
}
