import React from 'react'

import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { Button } from '@termly/react-components'

import ReactQueryStateHandler from 'components/ReactQueryStateHandler'

import alertIcon from 'assets/images/alert-triangle-white.svg'

import Styles from './styles.scss'

import useNotificationBannerConfig from './hooks/useNotificationBannerConfig'


export default function NotificationBanner() {
  const {
    alertMessage,
    buttonMessage,
    errorMessage,
    handleRedirect,
    isError,
    isLoading,
  } = useNotificationBannerConfig()

  if ( isError || isLoading ) {
    return (
      <ReactQueryStateHandler
        errorMessage={ errorMessage }
        isError={ isError }
        isLoading={ isLoading }
      />
    )
  }

  const rootClassName = classnames(Styles.root, 't-notificationBanner')
  const buttonClassName = classnames(Styles.button, 't-notificationBannerButton')

  return (
    <div className={ rootClassName }>
      <img
        alt="alert"
        className={ Styles.alertImage }
        src={ alertIcon }
      />
      <p className={ Styles.alertMessage }>
        <FormattedMessage
          { ...alertMessage }
        />
      </p>

      <Button
        className={ buttonClassName }
        handleClick={ handleRedirect }
      >
        <FormattedMessage
          { ...buttonMessage }
        />
      </Button>
    </div>
  )
}
