export default {
  stripe: {
    // development and staging using the same test key. we do this because in
    // config/index.js, it defaults to production. While it would make more sense
    // to default to staging, for backward compatibility we're leaving it as
    // production so that we don't have a fire drill when new charges can't
    // be made after a deploy
    development: 'pk_test_zRLHGqyhgLg3yYRiUqs4wkv3',
    production: 'pk_live_bcSQstw0O6aGASy5bh5P6WHi',
    staging: 'pk_test_zRLHGqyhgLg3yYRiUqs4wkv3',
  },
  google: {
    development: '800148887292-9ia8stnusevceio3ti9jnkagvsbcal0v.apps.googleusercontent.com',
    staging: '800148887292-e2nr3qi6p78uuc13088epqvjug0ln8uj.apps.googleusercontent.com',
    production: '741093138401-q7cdqeqf4p1kg8v8g3f15sj8p137fui1.apps.googleusercontent.com',
  },
  googleapis: {
    map: 'AIzaSyDn15mVKjPABXJW_XpGrarYZr8JE2Pq4lo',
  },
  recaptcha: {
    // development requires 'localhost'
    development: '6Lcx9igaAAAAAI-N6UZ-E2T4FZ7WjqfCn77w-eMY',
    develop: '6Lf5WWIaAAAAAG5K3u-A7axw-HlnqafCQvr18_MU',
    staging: '6Leb8ygaAAAAAPHS695y7ZA5pGGbhXDvu8SZaeBd',
    production: '6Lde7igaAAAAALcTYWGBKCWU4QDVuL591szZZMIZ',
  },
  papId: 'pap_x2s6df8d',
}
