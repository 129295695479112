import createActionCreator from 'utils/redux'
import createCookieManager from 'components/CookieReport/cookieManager'

const API_FAILURE = 'API_FAILURE'

export const COOKIE_REPORT_INIT = 'COOKIE_REPORT_INIT'
export const GET_COOKIE_REPORT_SUCCESS = 'GET_COOKIE_REPORT_SUCCESS'
export const CREATE_COOKIE = 'CREATE_COOKIE'
export const CREATE_COOKIE_SUCCESS = 'CREATE_COOKIE_SUCCESS'
export const CREATE_ANOTHER_COOKIE = 'CREATE_ANOTHER_COOKIE'
export const UPDATE_COOKIE = 'UPDATE_COOKIE'
export const UPDATE_COOKIE_SUCCESS = 'UPDATE_COOKIE_SUCCESS'
export const DELETE_COOKIE = 'DELETE_COOKIE'
export const DELETE_COOKIE_SUCCESS = 'DELETE_COOKIE_SUCCESS'
export const UPDATE_COOKIE_CATEGORY = 'UPDATE_COOKIE_CATEGORY'
export const UPDATE_COOKIE_CATEGORY_SUCCESS = 'UPDATE_COOKIE_CATEGORY_SUCCESS'
export const EXPORT_CONSENT_LOGS = 'EXPORT_CONSENT_LOGS'

export const cookieReportInit = createActionCreator(COOKIE_REPORT_INIT)
export const getCookieReportSuccess = createActionCreator(GET_COOKIE_REPORT_SUCCESS)
export const createCookie = createActionCreator(CREATE_COOKIE)
export const createCookieSuccess = createActionCreator(CREATE_COOKIE_SUCCESS)
export const createAnotherCookie = createActionCreator(CREATE_ANOTHER_COOKIE)
export const updateCookie = createActionCreator(UPDATE_COOKIE)
export const updateCookieSuccess = createActionCreator(UPDATE_COOKIE_SUCCESS)
export const deleteCookie = createActionCreator(DELETE_COOKIE)
export const deleteCookieSuccess = createActionCreator(DELETE_COOKIE_SUCCESS)
export const updateCookieCategory = createActionCreator(UPDATE_COOKIE_CATEGORY)
export const updateCookieCategorySuccess = createActionCreator(UPDATE_COOKIE_CATEGORY_SUCCESS)
export const exportConsentLogs = createActionCreator(EXPORT_CONSENT_LOGS)

export const initialState = {
  id: null,
  websiteId: null,
  status: null,
  pages: null,
  cookies: [],
  classifiedCookies: {
    unclassified: [],
    essential: [],
    performance: [],
    analytics: [],
    advertising: [],
    social_networking: [],
  },
  updated_at: null,
  createAnother: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ANOTHER_COOKIE: {
      return {
        ...state,
        createAnother: true,
      }
    }
    case API_FAILURE:
    case CREATE_COOKIE_SUCCESS: {
      return {
        ...state,
        createAnother: false,
      }
    }
    case GET_COOKIE_REPORT_SUCCESS: {
      const cookieManager = createCookieManager(action.payload.cookies)
      return {
        ...state,
        ...action.payload,
        classifiedCookies: cookieManager.getClassifiedCookies(),
      }
    }
    default: return state
  }
}
