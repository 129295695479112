import createActionCreator from 'utils/redux'

export const UPDATE_TAB = `${ __filename }/UPDATE_TAB`
export const UPDATE_TEXT = `${ __filename }/UPDATE_TEXT`
export const UPDATE_LOADING = `${ __filename }/UPDATE_LOADING`
export const UPDATE_APPLY_LOADING = `${ __filename }/UPDATE_APPLY_LOADING`

export const updateTab = createActionCreator(UPDATE_TAB)
export const updateText = createActionCreator(UPDATE_TEXT)
export const updateLoading = createActionCreator(UPDATE_LOADING)
export const updateApplyLoading = createActionCreator(UPDATE_APPLY_LOADING)

export const initState = {
  tab: 'text',
  text: 'title',
  loading: false,
  applyLoading: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_TAB:
      return {
        ...state,
        tab: payload,
      }
    case UPDATE_TEXT:
      return {
        ...state,
        text: payload,
      }
    case UPDATE_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case UPDATE_APPLY_LOADING:
      return {
        ...state,
        applyLoading: payload,
      }
    default:
      return state
  }
}
