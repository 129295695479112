import { URLBuilder } from '@termly_web/common'

import Paths from 'constants/paths'

import Locale from './locale'

import CONSENT_MANAGEMENT_ICON from './img/consent-mgmt-icon.svg'


export default function makeCookiesAccordion({ currentWebsiteID }) {
  return {
    icon: CONSENT_MANAGEMENT_ICON,
    id: 'consentManagement',
    name: Locale.consentManagement,
    sections: [
      {
        name: Locale.dataSources,
        links: [
          {
            id: 'cookies',
            name: Locale.cookies,
            url: Paths.COOKIE_REPORT(currentWebsiteID),
          },
          {
            id: 'vendors',
            name: Locale.vendors,
            url: URLBuilder.websiteVendorsPageURL({
              id: currentWebsiteID,
            }),
          },
          {
            id: 'scanner',
            name: Locale.scanner,
            url: Paths.SCAN_SETTINGS(currentWebsiteID),
          },
        ],
      },
      {
        name: Locale.consentManagementPlatform,
        links: [
          {
            id: 'consentBanner',
            name: Locale.consentBanner,
            url: Paths.BANNER_SETTINGS(currentWebsiteID),
          },
          {
            id: 'consentLogs',
            name: Locale.consentLogs,
            url: Paths.CONSENT_LOGS(currentWebsiteID),
          },
          {
            id: 'googleConsentMode',
            name: Locale.googleConsentMode,
            url: URLBuilder.websiteCMPOnboardingURL({
              id: currentWebsiteID,
            }),
          },
        ],
      },
      {
        name: Locale.dsarManagerSubsectionTitle,
        links: [
          {
            id: 'dsarForm',
            name: Locale.dsarForm,
            url: Paths.DSAR_FORM(currentWebsiteID),
          },
          {
            id: 'ccpaSettings',
            name: Locale.ccpaSettings,
            url: Paths.CCPA_SETTINGS(currentWebsiteID),
          },
        ],
      },
    ],
  }
}
