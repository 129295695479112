import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from './styles.scss'

const CONFIG = {
  error: Styles.error,
  light: Styles.light,
  success: Styles.success,
}


export default function MessageBox({ children, className, theme = 'error' }) {
  if ( !children ) {
    return null
  }

  const rootClassName = classnames(CONFIG[theme], className)

  return (
    <div className={ rootClassName }>
      { children }
    </div>
  )
}

MessageBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(CONFIG)),
}
