import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from '@termly/react-components'

import Locale from './locale'
import Styles from './styles.scss'


export default function RefreshPrompt() {
  return (
    <>
      <span className={ Styles.text }>
        <FormattedMessage
          { ...Locale.refreshMessage }
        />
      </span>
      <Button
        className={ Styles.button }
        handleClick={ handleClick }
        type="text"
      >
        <FormattedMessage
          { ...Locale.reload }
        />
      </Button>
    </>
  )
}

function handleClick() {
  window.location.reload()
}
