import useIsGroupAllowed from './useIsGroupAllowed'


export default function useFilterLinksByPolicy(linkGroups) {
  const isGroupAllowedByPolicy = useIsGroupAllowed()

  const reduceAllowedLinks = makeReduceAllowedLinks(isGroupAllowedByPolicy)

  return linkGroups
    .filter(isGroupAllowedByPolicy)
    .reduce(reduceAllowedLinks, [])
    .filter(hasLinks)
}

function makeReduceAllowedLinks(filter) {
  return (collector, item) => {
    const links = item.links.filter(filter)

    return collector.concat({
      ...item,
      links,
    })
  }
}

function hasLinks({ links }) {
  return links.length
}
