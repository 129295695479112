import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import mapClassNameToArray from 'utils/mapClassName'

import style from './auto-formatted-message.scss'

export default class AutoFormattedMessage extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    tag: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.shape({}),
    values: PropTypes.objectOf(
      PropTypes.node,
    ),
    children: PropTypes.node,
    title: PropTypes.string,
  }

  static defaultProps = {
    show:                 true,
    tag:                  'p',
    className:            '',
    text:                 {},
    values:               {},
    children:             null,
  }

  render() {
    const {
      show,
      tag: Tag,
      text,
      className,
      values,
      children,
      title = '',
      ...dataAttribute
    } = this.props

    const wrapStyle = style[className] ?
      cx(
        style.default,
        ...mapClassNameToArray(className, style),
      ) : className
    const tagAttribute = { className: wrapStyle, ...dataAttribute }
    if (title) tagAttribute.title = title

    if ( !show ) {
      return null
    }

    return (
      <Tag { ...tagAttribute }>
        {
          renderChildren({
            children,
            text,
            values,
          })
        }
      </Tag>
    )
  }
}

function renderChildren({ children, text, values }) {
  if ( children ) {
    return children
  }

  return (
    <FormattedMessage
      { ...text }
      values={ values }
    />
  )
}
