import createNamespacedAction from 'utils/createNamespacedAction'

import name from './name'

const createAction = createNamespacedAction(name)

export const signIn = createAction('SIGN_IN')
export const signInSuccess = createAction('SIGN_IN_SUCCESS')
export const signInError = createAction('SIGN_IN_ERROR')

export const signUpSuccess = createAction('SIGN_UP_SUCCESS')
export const signInSocial = createAction('SIGN_IN_SOCIAL')
export const signInCreateAccount = createAction('SIGN_IN_CREATE_ACCOUNT')
export const signInMigrateAccount = createAction('SIGN_IN_MIGRATE_ACCOUNT')
