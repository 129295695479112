import { Client } from '@termly_web/common'

// N.B.: We have to specify a `baseURL` for each Client invocaton
// because Client will otherwise prepend '/api' to each request. This
// is great for most API requests, but as we're trying to retrofit
// Client into the dashboard at all points, this assumption makes
// things difficult. Some calls are actually made from sagas for
// things like the locale.json file (e.g. /dashboard/en-us.json), so
// the automatically-prepended `/api` is harmful in those cases. So,
// rather than hunt down and change hundreds of discrete URLs, we'll
// tackle this the easy way...by just trusting that the URLs we've
// been given are precisely what should be called.
//
const CLIENT_CONFIG = {
  baseURL: '/',
}


// TODO: Ideally, we'd return more than just the response.data, but
// the goal here is maximal backwards compatibility. The right thing
// to do is probably to use the Client methods directly and eliminate
// this abstraction entirely.
//
export default {
  getData(url) {
    return Client.get(url, CLIENT_CONFIG)
      .then(handleSuccess)
      .catch(handleError)
  },

  postData(url, payload) {
    return Client.post(url, payload, CLIENT_CONFIG)
      .then(handleSuccess)
      .catch(handleError)
  },

  putData(url, payload) {
    return Client.put(url, payload, CLIENT_CONFIG)
      .then(handleSuccess)
      .catch(handleError)
  },

  deleteData(url, payload) {
    return Client.delete(url, payload, CLIENT_CONFIG)
      .then(handleSuccess)
      .catch(handleError)
  },
}

function handleSuccess({ data } = {}) {
  return data
}

// This handler is to mimic behavior removed in 70be9a436c5e8 which
// completely broke error handling.
function handleError({ response }) {
  const error = new Error(response.statusText)

  // Ugh. I hate this, but again, this whole file is about maximal
  // backwards compatibility with the old junk I removed. There are
  // only 14 files that use this 'json.json()' construct, so it
  // wouldn't be *too* difficult to make this more sane, but that's
  // out of scope for the bug I'm working on now [TER-10666].
  error.json = {
    json() {
      return response.data
    },
  }

  throw error
}
