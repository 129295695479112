import React, {
  useCallback,
} from 'react'

import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import {
  useLocation,
  useNavigate,
} from 'react-router-dom'

import { Button } from '@termly/react-components'

import { URLBuilder } from '@termly_web/common'

import arrowIcon from 'assets/images/arrow-back.svg'

import Locale from './locale'
import Styles from './styles.scss'


export default function SeeAllWebsites({ filter, show, totalWebsites }) {
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = useCallback(() => {
    navigate(URLBuilder.websitesURL(null, {
      filter,
    }))
  }, [filter, navigate])

  if ( !show ) {
    return null
  }

  if ( location.pathname === URLBuilder.websitesURL() ) {
    return null
  }

  return (
    <div className={ Styles.root }>
      <Button
        className={ Styles.button }
        data-testid="see-all-button"
        handleClick={ handleClick }
        type="text"
      >
        <FormattedMessage
          { ...Locale.showWebsites }
          values={ { totalWebsites } }
        />
        <img
          alt=">"
          className={ Styles.icon }
          src={ arrowIcon }
        />
      </Button>
    </div>
  )
}

SeeAllWebsites.propTypes = {
  filter: PropTypes.string,
  show: PropTypes.bool,
  totalWebsites: PropTypes.number,
}
