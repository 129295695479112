import { call, select, put, takeLatest } from 'redux-saga/effects'

import { touch } from 'redux-form'

import { queryClient } from '@termly_web/common'

import { CREATE_NEW_THEME_ID } from 'constants/documentThemes'

import {
  createTheme,
  updateExistingTheme,
  applyDocumentTheme,
} from 'constants/api'

import formId from 'constants/formIds/themeSaver'

import api from 'utils/api'
import createActionCreator from 'utils/redux'
import { getWebsiteIdFromParam } from 'utils/selectors'

import { setLoading, setVisible } from 'reducers/elements/modals/themeSaver'
import { getWebsite } from 'reducers/dashboard'

import { getPreviewTheme } from 'selectors/forms/themeEditor'
import {
  selectFormValue as selectThemeSaverFormValue,
  selectIsInvalid as selectThemeSaverIsInvalid,
} from 'selectors/elements/modals/themeSaver'
import { selectFormValue as selectThemeSelectorFormValue } from 'selectors/forms/themeSelector'
import { getDocument } from 'selectors/responses/website'

import { getCacheKey as getWebsiteCacheKey } from 'hooks/useWebsite'

export const SAVE_THEME = `${ __filename }/SAVE_THEME`

export const saveTheme = createActionCreator(SAVE_THEME)

export function* sagaSaveTheme() {
  try {
    yield put(setLoading(true))
    const websiteId = yield select(getWebsiteIdFromParam)
    const document = yield select(getDocument)
    const theme = yield select(getPreviewTheme)
    const themeSaverFormValue = yield select(selectThemeSaverFormValue)
    const themeSaverIsInvalid = yield select(selectThemeSaverIsInvalid)
    if (themeSaverIsInvalid) {
      yield put(touch(formId.name, formId.fields.themeName))
      return
    }
    const themeSelectorFormValue = yield select(selectThemeSelectorFormValue)
    const payload = {
      name: themeSaverFormValue.themeName,
      elements_css: theme,
    }
    const savedTheme =
      themeSelectorFormValue.themeId === CREATE_NEW_THEME_ID
        ? yield call(api.postData, createTheme(websiteId), payload)
        : yield call(
          api.putData,
          updateExistingTheme(websiteId, themeSelectorFormValue.themeId),
          payload
        )
    yield call(api.putData, applyDocumentTheme(document.id), {
      theme_id: savedTheme.id,
    })

    yield call([queryClient, queryClient.invalidateQueries], getWebsiteCacheKey(websiteId))
    yield put(getWebsite(websiteId))

    yield put(setVisible(false))
  } catch (e) {
    console.error(e)
  } finally {
    yield put(setLoading(false))
  }
}

export const watchers = {
  *saveTheme() {
    yield takeLatest(SAVE_THEME, sagaSaveTheme)
  },
}
