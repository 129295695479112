import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import PropTypes from 'prop-types'

import Modal from 'components/elements/Modal'
import ModalContent from 'components/elements/ModalContent'

import { ModalContext } from './modalContext'


/**
 * Do not use this component near the root of the application (e.g. in the router).
 * This provider needs to be unmounted between pages so that the underlying
 * modal component can clean up after itself when a link to another page is followed,
 * otherwise, it's easy to get into a situation where the "overflow: hidden" on the
 * whole page will be orphaned, and the page will be unscrollable.
 *
 * @returns {Node}
 */
export default function ModalProvider({ children }) {
  const [isOpen, setOpen] = useState(false)
  const [content, setContent] = useState(null)
  const [isDismissible, setDismissible] = useState(true)

  const close = useCallback(() => setOpen(false), [setOpen])
  const open = useCallback(() => setOpen(true), [setOpen])

  const value = useMemo(() => ({
    close,
    open,
    setDismissible,
    setModalContent: setContent,
  }), [close, open, setContent, setDismissible])

  return (
    <ModalContext.Provider value={ value }>
      { children }

      {
        renderModal({
          close,
          content,
          isDismissible,
          isOpen,
        })
      }
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node,
}

// eslint-disable-next-line react/prop-types
function renderModal({ close, content, isDismissible, isOpen }) {
  // If we don't unmount the modal when it is closed, its internal
  // cleanup won't get triggered, which will leave the page
  // unscrollable. It will also leave the modal in the DOM and cause
  // problems if a page to which we're navigating does not have the store
  // support for the displayed modal.
  if ( !isOpen ) {
    return null
  }

  const dismissModal = ( isDismissible ) ? close : void 0

  return (
    <Modal
      show
      handleClickOutside={ dismissModal }
    >
      <ModalContent
        onClickCloseButton={ dismissModal }
      >
        { content }
      </ModalContent>
    </Modal>
  )
}
