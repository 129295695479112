import { defineMessages } from 'react-intl'


export default defineMessages({
  errorMessage: {
    defaultMessage: 'Something went wrong when loading the website information',
    id: 'website-select.error',
  },

  noMatches: {
    defaultMessage: 'No matches found',
    id: 'website-select.no-matches-found',
  },

  search: {
    defaultMessage: 'Search',
    id: 'website-select.search',
  },
})
