import React, {
  useCallback,
} from 'react'

import PropTypes from 'prop-types'

import { Button } from '@termly/react-components'

import useAddWebsiteModal from 'hooks/useAddWebsiteModal'


export default function AddWebsiteModalButton(props) {
  const {
    className,
    color,
    dataTracking,
    handleClick: handleClickProp,
    handleSuccess,
    message,
  } = props

  const addWebsiteModal = useAddWebsiteModal({
    handleSuccess,
  })

  const handleClick = useCallback(() => {
    handleClickProp()

    addWebsiteModal.open()
  }, [addWebsiteModal, handleClickProp])

  return (
    <Button
      className={ className }
      color={ color }
      data-tracking={ dataTracking }
      handleClick={ handleClick }
      type="solid"
    >
      { message }
    </Button>
  )
}

AddWebsiteModalButton.propTypes = {
  handleSuccess: PropTypes.func.isRequired,

  className: Button.propTypes.className,
  color: Button.propTypes.color,
  dataTracking: PropTypes.string,
  handleClick: PropTypes.func,
  message: PropTypes.shape({
    defaultMessage: PropTypes.string,
    id: PropTypes.string,
  }),
}
