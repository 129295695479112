import React, {
  useEffect,
  useState,
} from 'react'

import PropTypes from 'prop-types'
import Flagsmith from 'flagsmith'

import FeatureFlagsContext from './FeatureFlagsContext'
import initFlagsmith from './lib/initFlagsmith'


// There's actually a FlagsmithProvider, but when we import
// flagsmith/react, everything blows up...it won't even build because
// the resolver can't resolve 'react'. No matter what I do, I can't
// get it to work, despite the fact that it works just fine in an
// off-the-shelf NextJS or CRA app. I got sick of fighting with it, so I
// just wrote this simple wrapper.
//
export default function FeatureFlagsProvider({ children, enableLogs, environmentID }) {
  const [value, setValue] = useState({
    flagsmith: Flagsmith,
  })

  useEffect(() => {
    initFlagsmith({
      enableLogs,
      environmentID,
      // This is here just to force our children to re-render when the
      // feature flags are finally loaded. Without this, we can run
      // into a situation where a feature whose flag is "on" won't get
      // activated because the initial value was "off" and no update
      // is ever made. The Flagsmith object reference is static, so we
      // have to wrap it in another object to trigger the update.
      onChange() {
        setValue({
          flagsmith: Flagsmith,
        })
      },
    })
  }, [enableLogs, environmentID, setValue])

  return (
    <FeatureFlagsContext.Provider
      value={ value }
    >
      { children }
    </FeatureFlagsContext.Provider>
  )
}

FeatureFlagsProvider.propTypes = {
  environmentID: PropTypes.string.isRequired,

  children: PropTypes.node,
  enableLogs: PropTypes.bool,
}
