import React from 'react'

import { SmartLink } from '@termly_web/common'

import Styles from './styles.scss'

import PopOutIcon from './img/pop-out.svg'


/**
 * @name ReactIntlFormatters
 * @see https://formatjs.io/docs/react-intl/components#rich-text-formatting
 */
export default {
  b: (chunks) => (
    <b className={ Styles.bold }>
      { chunks }
    </b>
  ),

  code: (chunks) => (
    <code>
      { chunks }
    </code>
  ),

  p: (chunks) => (
    <p>
      { chunks }
    </p>
  ),
}

export function makeSmartLinkFormatter(url) {
  // eslint-disable-next-line react/display-name
  return (chunks) => (
    <SmartLink
      className={ Styles.link }
      url={ url }
    >
      { chunks }
    </SmartLink>
  )
}

// TODO: replace all instances of makeExternalLinkFormatter with makeSmartLinkFormatter
export function makeExternalLinkFormatter(url) {
  // eslint-disable-next-line react/display-name
  return (chunks) => (
    <a
      className={ Styles.link }
      href={ url }
      rel="noopener"
      target="_blank"
    >
      { chunks }
      <img
        alt=""
        className={ Styles.popOutIcon }
        src={ PopOutIcon }
      />
    </a>
  )
}
