import createActionCreator from 'utils/redux'


export const CREATE_NEW_SITE = 'CREATE_NEW_SITE'
export const GET_WEBSITE = 'GET_WEBSITE'
export const SET_WEBSITE_INFO = 'SET_WEBSITE_INFO'
export const UPDATE_WEBSITE = 'UPDATE_WEBSITE'
export const SET_INIT = 'SET_INIT'
export const SET_TEMPLATE = 'SET_TEMPLATE'
export const MIGRATE_DOC = 'MIGRATE_DOC'
export const DELETE_DOC_BY_ID = 'DELETE_DOC_BY_ID'
export const DELETE_DOC_BY_ID_SUCCESS = 'DELETE_DOC_BY_ID_SUCCESS'
export const DETECT_DOC = 'DETECT_DOC'
export const DETECT_DOC_SUCCESS = 'DETECT_DOC_SUCCESS'
export const GET_WEBSITE_INFO_SUCCESS = 'GET_WEBSITE_INFO_SUCCESS'
export const DELETE_WEBSITE_SUCCESS = 'DELETE_WEBSITE_SUCCESS'

export const getWebsite = createActionCreator(GET_WEBSITE)
export const setWebsiteInfo = createActionCreator(SET_WEBSITE_INFO)
export const updateWebsite = createActionCreator(UPDATE_WEBSITE)
export const setInit = createActionCreator(SET_INIT)
export const migrateDoc = createActionCreator(MIGRATE_DOC)
export const deleteDocById = createActionCreator(DELETE_DOC_BY_ID)
export const deleteDocByIdSuccess = createActionCreator(DELETE_DOC_BY_ID_SUCCESS)

export const initialState = {
  init: {},
  templates: [],
  migrated: [],
  iframeDetection: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATE:
    case SET_WEBSITE_INFO: return { ...state, ...action.payload }

    case SET_INIT: return {
      ...state,
      init: action.payload,
    }
    case DELETE_DOC_BY_ID_SUCCESS: {
      return {
        ...state,
        migrated: initialState.migrated,
      }
    }
    case DETECT_DOC_SUCCESS: {
      return {
        ...state,
        iframeDetection: { ...action.payload, url: action.payload.url || '' },
      }
    }
    default: return state
  }
}
