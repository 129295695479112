// eslint-disable-next-line import/no-named-as-default
import Axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const DEFAULT_REFETCH_INTERVAL_MS = 90 * 1_000
const QUERY_CACHE_KEY = ['build-version']

// We're cheating here a little bit...both the builder and dashboard
// reference the same file, so we don't need to customize the queries
// for each app.
const URL = '/dashboard/build.json'


export default function useBuildVersionQuery(options) {
  const {
    data,
    ...query
  } = useQuery(QUERY_CACHE_KEY,
               () => Axios.get(URL),
               {
                 refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
                 ...options,
               })

  return {
    buildVersion: data?.data?.commitHash?.trim(),
    ...query
  }
}
