import React, {
  useCallback,
} from 'react'

import PropTypes from 'prop-types'

import { Button } from '@termly/react-components'

import useAdditionalWebsiteDetailsQuery from 'hooks/useAdditionalWebsiteDetailsQuery'
import useDiscardChangesModal from 'hooks/useDiscardChangesModal'


export default function DiscardChangesModalButton(props) {
  const {
    className,
    color,
    dataTracking,
    message,
    planName,
  } = props

  const additionalWebsiteDetails = useAdditionalWebsiteDetailsQuery()

  const discardChangesModal = useDiscardChangesModal({
    additionalWebsiteDetails,
    handleSuccess: additionalWebsiteDetails.refetch,
    planName,
  })

  const handleClick = useCallback(() => {
    discardChangesModal.open()
  }, [discardChangesModal])

  return (
    <Button
      className={ className }
      color={ color }
      data-tracking={ dataTracking }
      handleClick={ handleClick }
      type="solid"
    >
      { message }
    </Button>
  )
}

DiscardChangesModalButton.propTypes = {
  className: Button.propTypes.className,
  color: Button.propTypes.color,
  dataTracking: PropTypes.string,
  message: PropTypes.shape({
    defaultMessage: PropTypes.string,
    id: PropTypes.string,
  }),
  planName: PropTypes.string,
}
