import React, {
  useMemo,
} from 'react'

import { Hat } from '@termly_web/common'

import Paths from 'constants/paths'

import { useUserPolicy } from 'components/UserPolicyProvider'
import UpgradeButton from 'components/UpgradeButton'

import useBillingInfoQuery from 'hooks/useBillingInfoQuery'

import AvatarDropdown from './AvatarDropdown'
import HeaderNewWebsiteButton from './HeaderNewWebsiteButton'
import HeaderWebsiteSelect from './HeaderWebsiteSelect'

const ALLOW_AVATAR_DROPDOWN = 'allow_avatar_dropdown'
const ALLOW_WEBSITE_SELECTOR = 'allow_website_selector'
const CAN_CREATE_WEBSITE = 'can_create_website'

const RIGHT_CONTENT = (
  <AvatarDropdown />
)


export default function Header() {
  const { userPolicy } = useUserPolicy()

  const {
    billingInfo,
    isLoading,
  } = useBillingInfoQuery()

  const canCreateWebsite = userPolicy.has(CAN_CREATE_WEBSITE)
  const showAvatarDropdown = userPolicy.has(ALLOW_AVATAR_DROPDOWN)
  const showWebsiteSelector = userPolicy.has(ALLOW_WEBSITE_SELECTOR)

  const middleContent = useMemo(() => {
    if ( isLoading ) {
      return null
    }

    const hasWebsites = Boolean(billingInfo.used_license_count)

    return renderMiddleContent({
      canCreateWebsite,
      hasWebsites,
      showWebsiteSelector,
    })
  }, [billingInfo, canCreateWebsite, isLoading, showWebsiteSelector])

  const rightContent = ( showAvatarDropdown ) ? RIGHT_CONTENT : null

  return (
    <Hat
      leftContent={ <UpgradeButton /> }
      logoHref={ Paths.DASHBOARD }
      middleContent={ middleContent }
      rightContent={ rightContent }
    />
  )
}

function renderMiddleContent({ canCreateWebsite, hasWebsites, showWebsiteSelector }) {
  if ( !canCreateWebsite && !showWebsiteSelector ) {
    return null
  }

  if ( hasWebsites ) {
    return (
      <HeaderWebsiteSelect />
    )
  }

  if ( !canCreateWebsite ) {
    return null
  }

  return (
    <HeaderNewWebsiteButton />
  )
}
