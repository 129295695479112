import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Styles from './styles.scss'


export default function SectionTitle({ title }) {
  if ( !title ) {
    return null
  }

  return (
    <div className={ Styles.root }>
      <FormattedMessage
        { ...title }
      />
    </div>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
}
