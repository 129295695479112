import React from 'react'
import PropTypes from 'prop-types'

import { ZendeskProvider } from 'react-use-zendesk'

import ZendeskAdaptor from './ZendeskAdaptor'

const API_KEYS = {
  production: '7a71a0eb-6676-4de1-acb1-b7c226d1d3d2',
  sandbox: '1d47eb9c-bdb7-43cd-bbb5-369fa95887ec',
}

// eslint-disable-next-line no-process-env
const ZENDESK_API_KEY = ( process.env.NODE_ENV === 'production' )
  ? API_KEYS.production
  : API_KEYS.sandbox


export default function ZendeskAPIProvider({ children }) {
  return (
    <ZendeskProvider
      apiKey={ ZENDESK_API_KEY }
    >
      <ZendeskAdaptor>
        { children }
      </ZendeskAdaptor>
    </ZendeskProvider>
  )
}

ZendeskAPIProvider.propTypes = {
  children: PropTypes.node,
}
