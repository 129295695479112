import { defineMessages } from 'react-intl'


export default defineMessages({
  refreshMessage: {
    defaultMessage: 'A new version of Termly is available',
    id: 'build-version-checker.refresh-message',
  },

  reload: {
    defaultMessage: 'Reload',
    id: 'build-version-checker.reload-button',
  },
})
