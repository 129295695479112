const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas, The',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burma',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  'Cote d\'Ivoire',
  'Croatia',
  'Cuba',
  'Curacao',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia, The',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territories',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Republic of Cyprus',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export const countriesWithFlagAndDialCode = [
  { country: 'United States', flag: 'US', dialCode: '+1'},
  { country: 'United Kingdom', flag: 'GB', dialCode: '+44'},
  { country: 'Canada', flag: 'CA', dialCode: '+1'},
  { country: 'Afghanistan', flag: 'AF', dialCode: '+93'},
  { country: 'Albania', flag: 'AL', dialCode: '+355'},
  { country: 'Algeria', flag: 'DZ', dialCode: '+213'},
  { country: 'Andorra', flag: 'AD', dialCode: '+376'},
  { country: 'Angola', flag: 'AO', dialCode: '+244'},
  { country: 'Antigua and Barbuda', flag: 'AG', dialCode: '+1'},
  { country: 'Argentina', flag: 'AR', dialCode: '+54'},
  { country: 'Armenia', flag: 'AM', dialCode: '+374'},
  { country: 'Aruba', flag: 'AW', dialCode: '+297'},
  { country: 'Australia', flag: 'AU', dialCode: '+61'},
  { country: 'Austria', flag: 'AT', dialCode: '+43'},
  { country: 'Azerbaijan', flag: 'AZ', dialCode: '+994'},
  { country: 'Bahamas, The', flag: 'BS', dialCode: '+1'},
  { country: 'Bahrain', flag: 'BH', dialCode: '+973'},
  { country: 'Bangladesh', flag: 'BD', dialCode: '+880'},
  { country: 'Barbados', flag: 'BB', dialCode: '+1'},
  { country: 'Belarus', flag: 'BY', dialCode: '+375'},
  { country: 'Belgium', flag: 'BE', dialCode: '+32'},
  { country: 'Belize', flag: 'BZ', dialCode: '+501'},
  { country: 'Benin', flag: 'BJ', dialCode: '+229'},
  { country: 'Bermuda', flag: 'BM', dialCode: '+1'},
  { country: 'Bhutan', flag: 'BT', dialCode: '+975'},
  { country: 'Bolivia', flag: 'BO', dialCode: '+591'},
  { country: 'Bosnia and Herzegovina', flag: 'BA', dialCode: '+387'},
  { country: 'Botswana', flag: 'BW', dialCode: '+267'},
  { country: 'Brazil', flag: 'BR', dialCode: '+55'},
  { country: 'Brunei', flag: 'BN', dialCode: '+673'},
  { country: 'Bulgaria', flag: 'BG', dialCode: '+359'},
  { country: 'Burkina Faso', flag: 'BF', dialCode: '+226'},
  { country: 'Burma', flag: 'MM', dialCode: '+95'},
  { country: 'Burundi', flag: 'BI', dialCode: '+257'},
  { country: 'Cabo Verde', flag: 'CV', dialCode: '+238'},
  { country: 'Cambodia', flag: 'KH', dialCode: '+855'},
  { country: 'Cameroon', flag: 'CM', dialCode: '+237'},
  { country: 'Central African Republic', flag: 'CF', dialCode: '+236'},
  { country: 'Chad', flag: 'TD', dialCode: '+235'},
  { country: 'Chile', flag: 'CL', dialCode: '+56'},
  { country: 'China', flag: 'CN', dialCode: '+86'},
  { country: 'Colombia', flag: 'CO', dialCode: '+57'},
  { country: 'Comoros', flag: 'KM', dialCode: '+269'},
  { country: 'Congo, Democratic Republic of the', flag: 'CD', dialCode: '+243'},
  { country: 'Congo, Republic of the', flag: 'CG', dialCode: '+242'},
  { country: 'Costa Rica', flag: 'CR', dialCode: '+506'},
  { country: 'Cote d\'Ivoire', flag: 'CI', dialCode: '+225'},
  { country: 'Croatia', flag: 'HR', dialCode: '+385'},
  { country: 'Cuba', flag: 'CU', dialCode: '+53'},
  { country: 'Curacao', flag: 'CW', dialCode: '+599'},
  { country: 'Czech Republic', flag: 'CZ', dialCode: '+420'},
  { country: 'Denmark', flag: 'DK', dialCode: '+45'},
  { country: 'Djibouti', flag: 'DJ', dialCode: '+253'},
  { country: 'Dominica', flag: 'DM', dialCode: '+1'},
  { country: 'Dominican Republic', flag: 'DO', dialCode: '+1'},
  { country: 'Ecuador', flag: 'EC', dialCode: '+593'},
  { country: 'Egypt', flag: 'EG', dialCode: '+20'},
  { country: 'El Salvador', flag: 'SV', dialCode: '+503'},
  { country: 'Equatorial Guinea', flag: 'GQ', dialCode: '+240'},
  { country: 'Eritrea', flag: 'ER', dialCode: '+291'},
  { country: 'Estonia', flag: 'EE', dialCode: '+372'},
  { country: 'Ethiopia', flag: 'ET', dialCode: '+251'},
  { country: 'Fiji', flag: 'FJ', dialCode: '+679'},
  { country: 'Finland', flag: 'FI', dialCode: '+358'},
  { country: 'France', flag: 'FR', dialCode: '+33'},
  { country: 'Gabon', flag: 'GA', dialCode: '+241'},
  { country: 'Gambia, The', flag: 'GM', dialCode: '+220'},
  { country: 'Georgia', flag: 'GE', dialCode: '+995'},
  { country: 'Germany', flag: 'DE', dialCode: '+49'},
  { country: 'Ghana', flag: 'GH', dialCode: '+233'},
  { country: 'Greece', flag: 'GR', dialCode: '+30'},
  { country: 'Grenada', flag: 'GD', dialCode: '+1'},
  { country: 'Guatemala', flag: 'GT', dialCode: '+502'},
  { country: 'Guinea', flag: 'GN', dialCode: '+224'},
  { country: 'Guinea-Bissau', flag: 'GW', dialCode: '+245'},
  { country: 'Guyana', flag: 'GY', dialCode: '+592'},
  { country: 'Haiti', flag: 'HT', dialCode: '+509'},
  { country: 'Holy See', flag: 'VA', dialCode: '+379'},
  { country: 'Honduras', flag: 'HN', dialCode: '+504'},
  { country: 'Hong Kong', flag: 'HK', dialCode: '+852'},
  { country: 'Hungary', flag: 'HU', dialCode: '+36'},
  { country: 'Iceland', flag: 'IS', dialCode: '+354'},
  { country: 'India', flag: 'IN', dialCode: '+91'},
  { country: 'Indonesia', flag: 'ID', dialCode: '+62'},
  { country: 'Iran', flag: 'IR', dialCode: '+98'},
  { country: 'Iraq', flag: 'IQ', dialCode: '+964'},
  { country: 'Ireland', flag: 'IE', dialCode: '+353'},
  { country: 'Israel', flag: 'IL', dialCode: '+972'},
  { country: 'Italy', flag: 'IT', dialCode: '+39'},
  { country: 'Jamaica', flag: 'JM', dialCode: '+1'},
  { country: 'Japan', flag: 'JP', dialCode: '+81'},
  { country: 'Jordan', flag: 'JO', dialCode: '+962'},
  { country: 'Kazakhstan', flag: 'KZ', dialCode: '+7'},
  { country: 'Kenya', flag: 'KE', dialCode: '+254'},
  { country: 'Kiribati', flag: 'KI', dialCode: '+686'},
  { country: 'Kosovo', flag: 'XK', dialCode: '+383'},
  { country: 'Kuwait', flag: 'KW', dialCode: '+965'},
  { country: 'Kyrgyzstan', flag: 'KG', dialCode: '+996'},
  { country: 'Laos', flag: 'LA', dialCode: '+856'},
  { country: 'Latvia', flag: 'LV', dialCode: '+371'},
  { country: 'Lebanon', flag: 'LB', dialCode: '+961'},
  { country: 'Lesotho', flag: 'LS', dialCode: '+266'},
  { country: 'Liberia', flag: 'LR', dialCode: '+231'},
  { country: 'Libya', flag: 'LY', dialCode: '+218'},
  { country: 'Liechtenstein', flag: 'LI', dialCode: '+423'},
  { country: 'Lithuania', flag: 'LT', dialCode: '+370'},
  { country: 'Luxembourg', flag: 'LU', dialCode: '+352'},
  { country: 'Macau', flag: 'MO', dialCode: '+853'},
  { country: 'Macedonia', flag: 'MK', dialCode: '+389'},
  { country: 'Madagascar', flag: 'MG', dialCode: '+261'},
  { country: 'Malawi', flag: 'MW', dialCode: '+265'},
  { country: 'Malaysia', flag: 'MY', dialCode: '+60'},
  { country: 'Maldives', flag: 'MV', dialCode: '+960'},
  { country: 'Mali', flag: 'ML', dialCode: '+223'},
  { country: 'Malta', flag: 'MT', dialCode: '+356'},
  { country: 'Marshall Islands', flag: 'MH', dialCode: '+692'},
  { country: 'Mauritania', flag: 'MR', dialCode: '+222'},
  { country: 'Mauritius', flag: 'MU', dialCode: '+230'},
  { country: 'Mexico', flag: 'MX', dialCode: '+52'},
  { country: 'Micronesia', flag: 'FM', dialCode: '+691'},
  { country: 'Moldova', flag: 'MD', dialCode: '+373'},
  { country: 'Monaco', flag: 'MC', dialCode: '+377'},
  { country: 'Mongolia', flag: 'MN', dialCode: '+976'},
  { country: 'Montenegro', flag: 'ME', dialCode: '+382'},
  { country: 'Morocco', flag: 'MA', dialCode: '+212'},
  { country: 'Mozambique', flag: 'MZ', dialCode: '+258'},
  { country: 'Namibia', flag: 'NA', dialCode: '+264'},
  { country: 'Nauru', flag: 'NR', dialCode: '+674'},
  { country: 'Nepal', flag: 'NP', dialCode: '+977'},
  { country: 'Netherlands', flag: 'NL', dialCode: '+31'},
  { country: 'New Zealand', flag: 'NZ', dialCode: '+64'},
  { country: 'Nicaragua', flag: 'NI', dialCode: '+505'},
  { country: 'Niger', flag: 'NE', dialCode: '+227'},
  { country: 'Nigeria', flag: 'NG', dialCode: '+234'},
  { country: 'North Korea', flag: 'KP', dialCode: '+850'},
  { country: 'Norway', flag: 'NO', dialCode: '+47'},
  { country: 'Oman', flag: 'OM', dialCode: '+968'},
  { country: 'Pakistan', flag: 'PK', dialCode: '+92'},
  { country: 'Palau', flag: 'PW', dialCode: '+680'},
  { country: 'Palestinian Territories', flag: 'PS', dialCode: '+970'},
  { country: 'Panama', flag: 'PA', dialCode: '+507'},
  { country: 'Papua New Guinea', flag: 'PG', dialCode: '+675'},
  { country: 'Paraguay', flag: 'PY', dialCode: '+595'},
  { country: 'Peru', flag: 'PE', dialCode: '+51'},
  { country: 'Philippines', flag: 'PH', dialCode: '+63'},
  { country: 'Poland', flag: 'PL', dialCode: '+48'},
  { country: 'Portugal', flag: 'PT', dialCode: '+351'},
  { country: 'Qatar', flag: 'QA', dialCode: '+974'},
  { country: 'Republic of Cyprus', flag: 'CY', dialCode: '+357'},
  { country: 'Romania', flag: 'RO', dialCode: '+40'},
  { country: 'Russia', flag: 'RU', dialCode: '+7'},
  { country: 'Rwanda', flag: 'RW', dialCode: '+250'},
  { country: 'Saint Kitts and Nevis', flag: 'KN', dialCode: '+1'},
  { country: 'Saint Lucia', flag: 'LC', dialCode: '+1'},
  { country: 'Saint Vincent and the Grenadines', flag: 'VC', dialCode: '+1'},
  { country: 'Samoa', flag: 'WS', dialCode: '+685'},
  { country: 'San Marino', flag: 'SM', dialCode: '+378'},
  { country: 'Sao Tome and Principe', flag: 'ST', dialCode: '+239'},
  { country: 'Saudi Arabia', flag: 'SA', dialCode: '+966'},
  { country: 'Senegal', flag: 'SN', dialCode: '+221'},
  { country: 'Serbia', flag: 'RS', dialCode: '+381'},
  { country: 'Seychelles', flag: 'SC', dialCode: '+248'},
  { country: 'Sierra Leone', flag: 'SL', dialCode: '+232'},
  { country: 'Singapore', flag: 'SG', dialCode: '+65'},
  { country: 'Sint Maarten', flag: 'SX', dialCode: '+1'},
  { country: 'Slovakia', flag: 'SK', dialCode: '+421'},
  { country: 'Slovenia', flag: 'SI', dialCode: '+386'},
  { country: 'Solomon Islands', flag: 'SB', dialCode: '+677'},
  { country: 'Somalia', flag: 'SO', dialCode: '+252'},
  { country: 'South Africa', flag: 'ZA', dialCode: '+27'},
  { country: 'South Korea', flag: 'KR', dialCode: '+82'},
  { country: 'South Sudan', flag: 'SS', dialCode: '+211'},
  { country: 'Spain', flag: 'ES', dialCode: '+34'},
  { country: 'Sri Lanka', flag: 'LK', dialCode: '+94'},
  { country: 'Sudan', flag: 'SD', dialCode: '+249'},
  { country: 'Suriname', flag: 'SR', dialCode: '+597'},
  { country: 'Swaziland', flag: 'SZ', dialCode: '+268'},
  { country: 'Sweden', flag: 'SE', dialCode: '+46'},
  { country: 'Switzerland', flag: 'CH', dialCode: '+41'},
  { country: 'Syria', flag: 'SY', dialCode: '+963'},
  { country: 'Taiwan', flag: 'TW', dialCode: '+886'},
  { country: 'Tajikistan', flag: 'TJ', dialCode: '+992'},
  { country: 'Tanzania', flag: 'TZ', dialCode: '+255'},
  { country: 'Thailand', flag: 'TH', dialCode: '+66'},
  { country: 'Timor-Leste', flag: 'TL', dialCode: '+670'},
  { country: 'Togo', flag: 'TG', dialCode: '+228'},
  { country: 'Tonga', flag: 'TO', dialCode: '+676'},
  { country: 'Trinidad and Tobago', flag: 'TT', dialCode: '+1'},
  { country: 'Tunisia', flag: 'TN', dialCode: '+216'},
  { country: 'Turkey', flag: 'TR', dialCode: '+90'},
  { country: 'Turkmenistan', flag: 'TM', dialCode: '+993'},
  { country: 'Tuvalu', flag: 'TV', dialCode: '+688'},
  { country: 'Uganda', flag: 'UG', dialCode: '+256'},
  { country: 'Ukraine', flag: 'UA', dialCode: '+380'},
  { country: 'United Arab Emirates', flag: 'AE', dialCode: '+971'},
  { country: 'Uruguay', flag: 'UY', dialCode: '+598'},
  { country: 'Uzbekistan', flag: 'UZ', dialCode: '+998'},
  { country: 'Vanuatu', flag: 'VU', dialCode: '+678'},
  { country: 'Venezuela', flag: 'VE', dialCode: '+58'},
  { country: 'Vietnam', flag: 'VN', dialCode: '+84'},
  { country: 'Yemen', flag: 'YE', dialCode: '+967'},
  { country: 'Zambia', flag: 'ZM', dialCode: '+260'},
  { country: 'Zimbabwe', flag: 'ZW', dialCode: '+263'},
]

export const fullCountries = [
  'Afghanistan',
  'Åland',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire, Sint Eustatius, and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos [Keeling] Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  'Democratic Republic of Timor-Leste',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Federated States of Micronesia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Hashemite Kingdom of Jordan',
  'Heard Island and McDonald Islands',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jersey',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Luxembourg',
  'Macao',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Korea',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of Korea',
  'Republic of Lithuania',
  'Republic of Moldova',
  'Republic of the Congo',
  'Réunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Barthélemy',
  'Saint Helena',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'São Tomé and Príncipe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'St Kitts and Nevis',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'U.S. Minor Outlying Islands',
  'U.S. Virgin Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export const statesMap = {
  'United States': [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    'American Samoa',
    'Guam',
    'Northern Mariana Islands',
    'Puerto Rico',
    'Virgin Islands',
    'U.S. Armed Forces - Americas',
    'U.S. Armed Forces - Europe',
    'U.S. Armed Forces - Pacific',
  ].sort(),
  'Canada': [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ].sort(),
  'Australia': [
    'Ashmore and Cartier Islands',
    'Australian Antarctic Territory',
    'Australian Capital Territory',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Coral Sea Islands',
    'Heard Island and McDonald Islands',
    'Jervis Bay Territory',
    'New South Wales',
    'Norfolk Island',
    'Northern Territory',
    'Queensland',
    'South Australia',
    'Tasmania',
    'Victoria',
    'Western Australia',
  ].sort(),
}

export const countriesArray     = countriesWithFlagAndDialCode.map(({ country }) => country)
export const countriesCodeArray = countriesWithFlagAndDialCode.map(({ flag }) => flag)

export const countryFlagToFullName = (targetFlag) => {
  const { country } = countriesWithFlagAndDialCode.find(({ flag }) => flag === targetFlag) || {}
  return country
}

export const countryFullNametoFlag = (fullName) => {
  const { flag } = countriesWithFlagAndDialCode.find(({ country }) => country === fullName) || {}
  return flag
}

export default countries
