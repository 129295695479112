import React, {
  useEffect,
  useRef,
} from 'react'

import PropTypes from 'prop-types'

import ContentConstraint from 'components/ContentConstraint'

import DashboardSidebar from './DashboardSidebar'
import Header from './Header'
import NotificationBanner from './NotificationBanner'

import Styles from './styles.scss'

import useShowNotificationBanner from './hooks/useShowNotificationBanner'


export default function DashboardLayout({ children, showSidebar = true }) {
  const headerRef = useRef(null)

  const { showBanner } = useShowNotificationBanner()

  useEffect(() => {
    const { height } = headerRef.current.getBoundingClientRect()

    // There's a detailed comment in
    // client/builder/src/pages/BuilderPage/BuilderPage.jsx that
    // explains what's going on here. This might be a sign that I need
    // to find a way to DRY this up, but I am not feeling sufficiently
    // motivated to figure out a good way to do this since `builder`
    // uses a slightly different approach. If I have to do it again,
    // though, I'm not going to be able to sleep at night if I
    // copy-and-paste again. :-\
    document.body.style.setProperty('--header-height', `${ height }px`)
  }, [headerRef])

  return (
    <div className={ Styles.root }>
      <div
        className={ Styles.hatAndBanners }
        ref={ headerRef }
      >
        {
          renderNotificationBanner({
            showBanner,
          })
        }
        <div className={ Styles.header }>
          <ContentConstraint>
            <Header />
          </ContentConstraint>
        </div>
      </div>
      <ContentConstraint className={ Styles.main }>
        <div className={ Styles.mainContent }>
          {
            renderSidebar({
              showSidebar,
            })
          }
          <div className={ Styles.body }>
            { children }
          </div>
        </div>
      </ContentConstraint>
    </div>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
  showSidebar: PropTypes.bool,
}

function renderNotificationBanner({ showBanner }) {
  if ( !showBanner ) {
    return null
  }

  return (
    <div className={ Styles.banner }>
      <NotificationBanner />
    </div>
  )
}

function renderSidebar({ showSidebar }) {
  if ( !showSidebar ) {
    return null
  }

  return (
    <div className={ Styles.sidebar }>
      <DashboardSidebar />
    </div>
  )
}
