import React from 'react'
import PropTypes from 'prop-types'

import { IntlProvider } from '@termly_web/common'

import onError from './lib/onError'

const DEFAULT_LOCALE = 'en'
const URL_TEMPLATE = '/dashboard/i18n/:locale.json'

// Order doesn't matter
const SUPPORTED_LOCALES = [
  'de',
  'en',
]


export default function DashboardIntlProvider({ children, preferredLocales }) {
  return (
    <IntlProvider
      defaultLocale={ DEFAULT_LOCALE }
      localeFileURLTemplate={ URL_TEMPLATE }
      onError={ onError }
      preferredLocales={ preferredLocales }
      supportedLocales={ SUPPORTED_LOCALES }
    >
      { children }
    </IntlProvider>
  )
}

DashboardIntlProvider.propTypes = {
  children: PropTypes.node,
  preferredLocales: IntlProvider.propTypes.preferredLocales,
}
