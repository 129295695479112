export default {
  name:   'businessInfo',
  fields: {
    companyName: 'companyName',
    email:       'email',
    phone: {
      country: 'phone.country',
      number:  'phone.number',
    },
    fax: {
      country: 'fax.country',
      number:  'fax.number',
    },
    address:            'address',
    state:              'state',
    city:               'city',
    country:            'country',
    zip:                'zip',
    policyConfirmation: 'policyConfirmation',
  },
}
