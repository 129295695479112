import { call, put, all, take } from 'redux-saga/effects'

import { GET_PAGEVIEW, getPageviewSuccess, setViewSelect } from 'reducers/pageview'
import { toggleLoading } from 'reducers/ui'
import API from 'utils/api'

import {
  pageview,
} from 'constants/api'

export function* getPageview({ payload }, hasLoading) {
  try {
    if (hasLoading) {
      yield put(toggleLoading({ element: 'pageview', isLoading: true }))
    }
    const { page_view_list } = yield call(API.getData, pageview(payload.substring(0, payload.length - 3)), null, 'json')
    yield all([
      put(getPageviewSuccess({ timestamp: payload, pageviewList: page_view_list })),
      put(setViewSelect(payload)),
    ])
    if (hasLoading) {
      yield put(toggleLoading('pageview'))
    }
  } catch (e) {
    const error = yield e.json.json()
    console.log(error)
  }
}

// TODO: What are we even doing with all of this?
export function* watchGetPageview() {
  while ( true ) {
    const action = yield take(GET_PAGEVIEW)
    yield call(getPageview, action, true)
  }
}
