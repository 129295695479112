import createActionCreator from 'utils/redux'

export const SET_VISIBLE = `${ __filename }/SET_VISIBLE`
export const SET_LOADING = `${ __filename }/SET_LOADING`

export const setVisible = createActionCreator(SET_VISIBLE)
export const setLoading = createActionCreator(SET_LOADING)

export const initState = {
  visible: false,
  loading: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_VISIBLE: return {
      ...state,
      visible: payload,
    }
    case SET_LOADING: return {
      ...state,
      loading: payload,
    }
    default: return state
  }
}
