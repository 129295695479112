import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import {
  websiteInfo,
  billingInfo,
} from 'constants/api'

import Api from 'utils/api'

import { setVisible as setPolicyMigratorVisible } from 'reducers/elements/modals/policyMigrator'
import { setData as setBillingData } from 'reducers/responses/billing'

import * as DashboardSagas from 'sagas/dashboard'

import {
  setData as setWebsitesData,
  setLoading as setWebsitesLoading,
} from 'reducers/responses/websites'

import {
  setData as setWebsiteData,
  setLoading as setWebsiteLoading,
} from 'reducers/responses/website'

import { getMigrated } from 'selectors/responses/websites'

import * as WebsitePagesLayoutActions from './actions'


export default function* websitePagesLayoutSagas() {
  yield takeLatest(WebsitePagesLayoutActions.initialize,
                   handleInitialize)
}

function* handleInitialize({ payload }) {
  const { websiteID } = payload

  // TODO: I'm not really happy with all of the stuff we're doing in
  // this saga. If we were doing Redux sagas properly, all we would be
  // doing in this saga is dispatching a bunch of actions that would
  // cause other, single-purpose sagas to fire to fetch their
  // respective data sets. But at this stage of the game, I'm just
  // trying to inch my way to sanity rather than re-architect the
  // whole world. What I would *really* like to do is eliminate most
  // of the Redux+sagas in the entire application and use react-query
  // instead. But again, that's way out of scope for now.
  try {
    yield put(setWebsitesLoading(true))
    const websitesResult = yield call(Api.getData, websiteInfo())
    yield put(setWebsitesData(websitesResult))
    yield put(setWebsitesLoading(false))

    if ( websiteID ) {
      yield put(setWebsiteLoading(true))
      const website = yield call(DashboardSagas.getWebsite, websiteID)
      if ( website ) {
        yield put(setWebsiteData(website))
      }
      yield put(setWebsiteLoading(false))
    }

    const billingResult = yield call(Api.getData, billingInfo())
    yield put(setBillingData(billingResult))

    const migratedPolicy = yield select(getMigrated)
    if ( migratedPolicy.length > 0 ) {
      yield put(setPolicyMigratorVisible(true))
    }

    return websitesResult
  } catch (e) {
    yield put(setWebsiteLoading(false))
    yield put(setWebsitesLoading(false))
    console.error(e)
  }
}


export const _private = {
  handleInitialize,
}
