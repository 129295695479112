export const encodePhoneNumber = (countryAbbr, number) => number === '' ? '' : `(${ countryAbbr })${ number }`

export const decodePhoneNumber = (encodedNumber) => {
  const [
    placeholder1, // eslint-disable-line
    placeholder2, // eslint-disable-line
    countryAbbr = '',
    number      = '',
  ] = /(\((\w*)\))?(.*)/g.exec(encodedNumber) || []
  return {
    countryAbbr,
    number: number === 'undefined' || number === 'null' ? '' : number,
  }
}
