import React, {
  useContext,
} from 'react'

import { ReduxFormContext } from 'redux-form'

import getDisplayName from './getDisplayName'


/**
 * @deprecated This HOC is nothing more than a shim to provide
 * backwards compatibility for the small number of components that are
 * expecting to receive the redux-form context via a `_reduxForm`
 * prop.
 */
export default function withFormContext(WrappedComponent) {
  const Wrapper = (props) => {
    const _reduxForm = useContext(ReduxFormContext)

    if ( !_reduxForm ) {
      const name = getDisplayName(WrappedComponent)
      throw new Error(`${ name } must be inside a component decorated with reduxForm()`)
    }

    return (
      <WrappedComponent
        { ...props }
        _reduxForm={ _reduxForm }
      />
    )
  }

  Wrapper.propTypes = WrappedComponent.propTypes

  Wrapper.displayName = `withFormContext(${ getDisplayName(WrappedComponent) })`

  return Wrapper
}
