import { createSelector } from 'reselect'

import { getFormValues, isInvalid } from 'redux-form'

import {
  CREATE_NEW_THEME_ID,
  DEFAULT_THEME_ID,
} from 'constants/documentThemes'

import { selectFormValue as selectThemeSelectorFormValue } from 'selectors/forms/themeSelector'
import { store } from 'store'
import { setVisible as setThemeSaverVisible } from 'reducers/elements/modals/themeSaver'
import Input from 'components/Input'
import formId from 'constants/formIds/themeSaver'
import {
  enterThemeName,
  duplicateThemeName,
} from 'components/forms/ThemeSaver/locale'
import { required } from 'utils/form-validation'
import { getDocument } from 'selectors/responses/website'
import { saveTheme } from 'sagas/forms/themeSaver'

export const selector = (state) => state.elements.modals.themeSaver
export const selectFormValue = getFormValues(formId.name)
export const selectIsInvalid = isInvalid(formId.name)

export const getVisible = createSelector(
  selector,
  ({ visible }) => visible
)

export const getMode = createSelector(
  selectThemeSelectorFormValue,
  (themeSelectorFormValue) =>
    themeSelectorFormValue &&
    themeSelectorFormValue.themeId !== CREATE_NEW_THEME_ID
      ? 'update'
      : 'create'
)

export const getOnCancel = () => () =>
  store.dispatch(setThemeSaverVisible(false))

export const getOnSave = () => () => store.dispatch(saveTheme())

export const getLoading = createSelector(
  selector,
  ({ loading }) => loading
)

export const getUniqueThemeName = createSelector(
  getDocument,
  getMode,
  (document, mode) => (value) =>
    mode === 'update'
      ? undefined
      : document.themes.customize_themes.find(
        (theme) => theme.data.name === value
      )
        ? duplicateThemeName
        : undefined
)

export const getNameFieldRequired = createSelector(
  getUniqueThemeName,
  (uniqueThemeName) => (value) =>
    required(enterThemeName)(value) || uniqueThemeName(value)
)

export const getField = createSelector(
  getNameFieldRequired,
  (nameFieldRequired) => ({
    name: formId.fields.themeName,
    component: Input,
    placeholder: enterThemeName,
    validate: nameFieldRequired,
  })
)

export const getOnClickCloseButton = () => () =>
  store.dispatch(setThemeSaverVisible(false))

export const getInitialValues = createSelector(
  getDocument,
  selectThemeSelectorFormValue,
  (document, themeSelectorFormValue) => ({
    themeName:
      document &&
      themeSelectorFormValue &&
      themeSelectorFormValue.themeId !== CREATE_NEW_THEME_ID &&
      themeSelectorFormValue.themeId !== DEFAULT_THEME_ID
        ? document.themes.customize_themes.find(
          (theme) =>
            parseInt(theme.id, 10) ===
              parseInt(themeSelectorFormValue.themeId, 10)
        )?.data?.name
        : '',
  })
)
