import Flagsmith from 'flagsmith'

//
// Our Flagsmith architecture is documented at https://termly.slite.com/app/docs/7O3is47bcUuMGB
//

const OPTIONS = {
  // The flagsmith staging environment is for testing flagsmith deployments.
  // The production endpoint has an environmentID for each of production and
  // staging.
  api: 'https://flagsmith-api.production.termlyapi.io/api/v1/',
  cacheFlags: true,
  enableLogs: false,
  environmentID: void 0,
}


export default function initFlagsmith(options) {
  return Flagsmith.init({
    ...OPTIONS,
    ...options,
  })
}
