import createActionCreator from 'utils/redux'

export const UPDATE_LOADING = `${ __filename }/UPDATE_LOADING`

export const updateLoading = createActionCreator(UPDATE_LOADING)

export const initState = {
  loading: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_LOADING:
      return {
        ...state,
        loading: payload,
      }
    default:
      return state
  }
}
