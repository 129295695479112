import {
  all,
  call,
  delay,
  put,
} from 'redux-saga/effects'

import errorArrayGenerator from 'utils/errorArrayGenerator'

import {
  apiFailure,
  toggleLoading,
} from 'reducers/ui'


export function* apiCallBasic(action, method, url, onSuccess, options = {}) {
  const { onFailure, type, noMsg } = { onFailure: apiFailure, type: 'json', noMsg: false, ...options }
  try {
    const payload = yield call(method, url(), (action && action.payload), type)
    yield put(onSuccess(payload))
  } catch (e) {
    if (noMsg) {
      yield put(onFailure('Internal server error'))
    } else {
      const error = yield e.json.json()
      yield put(onFailure(errorArrayGenerator(error)))
    }
  }
}

export default function* apiCall(action, method, url, onSuccess, options) {
  yield all([put(toggleLoading({ element: 'form', isLoading: true })), delay(500)])
  yield* apiCallBasic(...arguments)
  yield put(toggleLoading('form'))
}
