import React, {
  useEffect,
  useRef,
} from 'react'

import { toast } from '../Toaster'

import RefreshPrompt from './RefreshPrompt'

import useBuildVersionQuery from './hooks/useBuildVersionQuery'

const TOAST_CONFIG = {
  duration: Infinity,
  id: 'build-version-checker-refresh-prompt'
}


// The reason this is a component and not simply a hook is that we
// need the react-intl context to be able to render <RefreshPrompt />
//
export default function BuildVersionChecker() {
  const previousVersion = useRef()

  const { buildVersion } = useBuildVersionQuery()

  useEffect(() => {
    if ( !previousVersion.current ) {
      previousVersion.current = buildVersion
      return
    }

    if ( buildVersion === previousVersion.current ) {
      return
    }

    toast(<RefreshPrompt />, TOAST_CONFIG)
  }, [buildVersion])

  return null
}
