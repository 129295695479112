import { useAuth } from 'pkg/authentication'

import usePlansQuery from 'hooks/usePlansQuery'

import {
  BASIC,
  ON_DEMAND,
  PRO_PLUS,
  STARTER,
  TIER,
} from 'constants/variables'


export default function useCurrentUserPlanQuery(options) {
  const {
    activePlans,
    currencySymbol,
    currentUserPlan,
    ...rest
  } = usePlansQuery(options)

  const {
    user = {},
  } = useAuth()

  const checkPlanKey = (planKey) => {
    return ( currentUserPlan?.plan_key === planKey )
  }

  return {
    ...rest,

    activePlans,
    canPublish: user.can_publish,
    currentUserPlan,
    isGuestUser: user.guest,
    isOverConsentQuota: user.is_over_consent_quota,
    isTrialUser: user.is_trial_user,
    currencySymbol,
    currency: currentUserPlan?.currency,
    isAnnual: currentUserPlan?.is_annual,
    isBasic: checkPlanKey(BASIC),
    isLegacyPlan: ( currentUserPlan?.type === TIER ),
    isOnDemand: ( currentUserPlan?.type === ON_DEMAND ),
    isPaidUser: ( !checkPlanKey(BASIC) && !user.is_trial_user ),
    isProPlus: checkPlanKey(PRO_PLUS),
    isStarter: checkPlanKey(STARTER),
    planName: currentUserPlan?.name,
    planPrice: currentUserPlan?.amount_in_cents,
  }
}
