import React from 'react'

import { CLICK_NEW_WEBSITE_HEADER } from 'constants/dataTrackingHeap'

import useNavigateToBusinessInfo from 'hooks/useNavigateToBusinessInfo'

import AddWebsiteButton from 'components/AddWebsiteButton'


export default function HeaderNewWebsiteButton() {
  const handleSuccess = useNavigateToBusinessInfo()

  return (
    <AddWebsiteButton
      className="t-newWebsiteButton"
      dataTracking={ CLICK_NEW_WEBSITE_HEADER }
      handleSuccess={ handleSuccess }
    />
  )
}
