import createActionCreator from 'utils/redux'

export const UPDATE_IS_COPIED = `${ __filename }/UPDATE_IS_COPIED`

export const updateIsCopied = createActionCreator(UPDATE_IS_COPIED)

export const initState = {
  isCopied: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_IS_COPIED:
      return {
        ...state,
        isCopied: payload,
      }
    default:
      return state
  }
}
