import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  components as ReactSelectComponents,
} from 'react-select'

import Styles from './styles.scss'


export default function WebsiteMenu(props) {
  const {
    MenuFooter,
  } = props.selectProps.components

  const className = classnames(Styles.root, props.className)

  return (
    <ReactSelectComponents.Menu
      { ...props }
      className={ className }
    >
      { props.children }
      {
        renderFooter(MenuFooter, props)
      }
    </ReactSelectComponents.Menu>
  )
}

WebsiteMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  selectProps: PropTypes.shape({
    components: PropTypes.objectOf(PropTypes.elementType).isRequired,
  }).isRequired,
}

function renderFooter(Component, props) {
  if ( !Component ) {
    return null
  }

  return (
    <Component
      { ...props }
    />
  )
}
