import { defineMessages } from 'react-intl'


export default defineMessages({
  planInfo: {
    id: 'avatar-flyout.planInfo',
    defaultMessage: 'Plan Info',
  },

  creditCardInfo: {
    id: 'avatar-flyout.creditCardInfo',
    defaultMessage: 'Credit Card Info',
  },

  billingHistory: {
    id: 'avatar-flyout.billingHistory',
    defaultMessage: 'Billing History',
  },

  account: {
    id: 'avatar-flyout.account',
    defaultMessage: 'Account',
  },

  subscription: {
    id: 'avatar-flyout.subscription',
    defaultMessage: 'Subscription',
  },

  users: {
    id: 'avatar-flyout.users',
    defaultMessage: 'Users',
  },

  websites: {
    defaultMessage: 'Websites',
    id: 'avatar-flyout.websites',
  },
})
